import {getColor, rgbaColor} from './utils';

export const chartJsDefaultTooltip = () => ({
  backgroundColor: rgbaColor(getColor('gray-100'), 0.8),
  borderColor: getColor('gray-200'),
  borderWidth: 1,
  titleColor: getColor('gray-1100'),
  callbacks: {
    labelTextColor() {
      return getColor('gray-1100');
    }
  }
});
