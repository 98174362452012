import fetchData from "./fetchData";
import {settings} from "../config";
import wrapPromise from "./wrapPromise";

export const createIndex = (text) => {
    let lines = text.split(/\r?\n/);
    return new Set(lines);
}

export const teamImageData = fetchData(settings.imgServer + "index/teams.txt", response => new Promise((resolve) => {
    response.text().then(text => resolve(createIndex(text)))
}));

export const playerImageData = wrapPromise(new Promise((resolve) => {
    resolve(new Set());
}));