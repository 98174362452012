import React, {useEffect, useState} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import classNames from 'classnames';
import Logo from 'components/common/Logo';
import {topNavbarBreakpoint} from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import SearchBox from "./SearchBox";

const NavbarTop = () => {
    const [showDropShadow, setShowDropShadow] = useState(false);

    const setDropShadow = () => {
        const el = document.documentElement;
        if (el.scrollTop > 0) {
            setShowDropShadow(true);
        } else {
            setShowDropShadow(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', setDropShadow);
        return () => window.removeEventListener('scroll', setDropShadow);
    }, []);

    return (
        <Navbar
            className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
                'navbar-glass-shadow': showDropShadow
            })}
        >
            <NavbarTopElements/>
        </Navbar>
    );
};

const NavbarTopElements = () => {
    const [showSmallSearch, setShowSmallSearch] = useState(false);
    return (
        <>
            {showSmallSearch ? <SearchBox/> : <Logo at="navbar-top" width={30} id="topLogo"/>}
            <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`} as="ul">
                <Nav.Item as="li">
                    <SearchBox/>
                </Nav.Item>
            </Nav>
            <TopNavRightSideNavItem toggleShowSmallSearch={() => setShowSmallSearch(!showSmallSearch)}/>
        </>
    );
};

export default NavbarTop;
