import React, {useContext, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import is from 'is_js';
import {PlayerProfile, TeamProfile} from 'components/custom/profile';
import MatchReport from 'components/custom/matchreport';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import {toast, ToastContainer} from 'react-toastify';
import {CloseButton} from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import AppContext from 'context/Context';
import Home from "../components/custom/home";
import CompetitionProfile from "../components/custom/competition";
import {PlayerComparison} from "../components/custom/playercomparison";
import {TeamComparison} from "../components/custom/teamcomparison";
import {PlayerRatings, TeamRatings} from "../components/custom/ranks";
import {CookiePolicy} from "../components/custom/cookiepolicy";
import {Loading} from "../components/custom/spinner";

const Layout = () => {
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    const {
        config: {navbarPosition}
    } = useContext(AppContext);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
        if (is.safari()) {
            HTMLClassList.add('safari');
        }
    }, [HTMLClassList]);

    useEffect(() => {
        if (navbarPosition === 'double-top') {
            HTMLClassList.add('double-top-nav-layout');
        }
        return () => HTMLClassList.remove('double-top-nav-layout');
    }, [navbarPosition]);

    return (
        <>
            <Routes>

                <Route element={<MainLayout/>}>
                    {/*Routes must be added to firebase.json and/or .rsp.json for production*/}
                    <Route path="/" element={<Home/>}/>
                    <Route path="team/:id" element={<TeamProfile/>}/>
                    <Route path="player/:id" element={<PlayerProfile/>}/>
                    <Route path="playercomparison/:ids" element={<PlayerComparison/>}/>
                    <Route path="teamcomparison/:ids" element={<TeamComparison/>}/>
                    <Route path="competition/:id/:season" element={<CompetitionProfile/>}/>
                    <Route path="competition/:id" element={<CompetitionProfile/>}/>
                    <Route path="match/:id" element={<MatchReport/>}/>
                    <Route path="playerratings" element={<PlayerRatings/>}/>
                    <Route path="teamratings" element={<TeamRatings/>}/>
                    <Route path="cookiepolicy" element={<CookiePolicy/>}/>
                    <Route path="loading" element={<Loading/>}/>
                </Route>

                <Route element={<ErrorLayout/>}>
                    <Route path="500.html" element={<Error500/>}/>
                    <Route path="*" element={<Error404/>}/>
                </Route>

            </Routes>
            <ToastContainer
                closeButton={CloseButton}
                icon={false}
                position={toast.POSITION.BOTTOM_LEFT}
            />
        </>
    );
};

export default Layout;
