import {Table} from "react-bootstrap";
import {TeamLinkShortener, TeamLogoLink} from "./team";
import React, {useEffect, useState} from "react";
import {settings} from "../../config";
import {Loading} from "./spinner";
import _ from "lodash";
import {TEAM} from "./alias";

export const LeagueTableRound = ({roundId}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [table, setTable] = useState(0);

    useEffect(() => {
        fetch(settings.apiServer + "leaguetables/" + roundId + ".json")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setTable(result);
                },
                () => {
                    setIsLoaded(true);
                }
            )
    }, []);

    if (isLoaded) {
        return <LeagueTable table={table}/>
    } else {
        return <Loading/>
    }
}

export const LeagueTable = ({table}) => {
    let rows = _.map(table.z, (row, i) => {
        return <tr key={i}>
            <td>{row.z}</td>
            <td><TeamLogoLink {...row[TEAM]} size={32}/><TeamLinkShortener display="inline-block" {...row[TEAM]}/></td>
            <td>{row.m}</td>
            <td>{row.w}</td>
            <td>{row.d}</td>
            <td>{row.l}</td>
            <td>{row.g}</td>
            <td>{row.c}</td>
            <td>{row.g - row.c}</td>
            <td>{row.p}</td>
        </tr>
    });
    return <Table variant="light" striped bordered hover size="sm">
        <thead>
        <tr>
            <th width={50}>#</th>
            <th>Team</th>
            <th width={40}>P</th>
            <th width={40}>W</th>
            <th width={40}>D</th>
            <th width={40}>L</th>
            <th width={40}>F</th>
            <th width={40}>A</th>
            <th width={40}>GD</th>
            <th width={40}>Pts</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
    </Table>
}