import React, {useContext, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import AppContext from 'context/Context';
import NavbarTop from "../components/navbar/top/NavbarTop";
import {Container} from "react-bootstrap";
import Footer from "../components/footer/Footer";
import {CookieBanner} from "../components/custom/cookiebanner";

const MainLayout = () => {
    const {hash, pathname} = useLocation();

    const {
        config: {isFluid}
    } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({block: 'start', behavior: 'smooth'});
                }
            }
        }, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container fluid={isFluid}>
            <NavbarTop/>
            <Outlet/>
            <Footer/>
            <CookieBanner/>
        </Container>
    );
};

export default MainLayout;
