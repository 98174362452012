import React, {createContext, useContext, useState} from 'react';

export const MediaViewerContext = createContext(null);

const MediaViewerProvider = ({children}) => {
    const [activeMedia, setActiveMedia] = useState(null);
    return (
        <MediaViewerContext.Provider value={{activeMedia, setActiveMedia}}>
            {children}
        </MediaViewerContext.Provider>
    );
};

export const useMediaViewer = () => useContext(MediaViewerContext);

export default MediaViewerProvider;