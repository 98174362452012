import React, {Suspense, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Badge, Button, Card, Dropdown, Form, ListGroup, Modal} from "react-bootstrap";
import {Flag} from "./flag";
import {hotScale} from "./colorprogress";
import _ from "lodash";
import {FootballEvent, Gender, GenderToggle, NationalToggle} from "./icons";
import {settings} from "../../config";
import {useImage} from 'react-image'
import {sizeString} from "./img";
import {breakpoints, isValidNumber, stopDefault, stopPropagation} from "../../helpers/utils";
import {Loading} from "./spinner";
import {
    CLUB,
    COMPETITION,
    COUNTRY,
    GENDER,
    ID,
    NAME,
    NATIONAL,
    RANK,
    RATING,
    SEASON_SECONDARY,
    STATS,
    WEIGHT,
    ZONE
} from "./alias";
import {FixedSizeList as List} from "react-window";
import {
    faCity,
    faCog,
    faGlobeAmericas,
    faGraduationCap,
    faMedal,
    faPercent,
    faPersonRunning,
    faPlusMinus,
    faSearch,
    faShield,
    faSortAmountUpAlt,
    faTable,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {CompetitionBadge, formatCompetitionSeasonName} from "./competition";
import classNames from "classnames";
import {calculateStatValue, DefaultDropdown, displayName, PlayerList} from "./player";
import {competitionSeasonUrl, teamProfileUrl} from "../../helpers/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RatingBadge, RatingBadge100} from "./matchreport";
import {useWindowContext} from "../../hooks/useWindowContext";
import {filteredSearch} from "../../helpers/searchIndex";
import {teamImageData} from "../../helpers/imageIndex";

export const fieldDisplay = ['r', 'rc', 'rt', 'att', 'def', 'oc', 'g', 'go', 'g90', 'go90', 'winrate', 'avpoints', 'goaldiff'];

export const fields = {
    rt: {
        name: 'Rating',
        min: 0,
        max: 100,
        axis: 'rating',
        faIcon: {icon: faMedal, color: '#ff9900'}
    },
    att: {
        name: 'Attack',
        min: 0,
        max: 100,
        axis: 'rating',
        faIcon: {icon: faPersonRunning, color: '#ff9900'}
    },
    def: {
        name: 'Defend',
        min: 0,
        max: 100,
        axis: 'rating',
        faIcon: {icon: faShield, color: '#ff9900'}
    },
    r: {
        name: 'Rank',
        reverse: true,
        lowerIsBetter: true,
        min: 1,
        max: 1000,
        axis: 'rank',
        faIcon: {icon: faSortAmountUpAlt, color: '#ff9900'},
        axisMin: 1,
        barexclude: true,
    },
    rc: {
        name: 'Rank Country',
        reverse: true,
        lowerIsBetter: true,
        min: 1,
        max: 1000,
        axis: 'rank',
        iconfn: (x) => <Flag id={x.c} size={"1x"}/>,
        barexclude: true,
    },
    oc: {
        name: 'Played',
        min: 0,
        max: 500,
        sum: true,
        tooltip: 'Total number of matches played',
        faIcon: {icon: faGraduationCap, color: '#22ff00'},
        axis: 'apps',
    },
    g: {
        name: 'Goals',
        min: 0,
        max: 250,
        sum: true,
        tooltip: 'Goals scored by team',
        axis: 'goals',
        icon: <FootballEvent id={0} fixedWidth/>
    },
    g90: {
        name: 'Goals / 90',
        min: 0,
        max: 2.5,
        sum: true,
        tooltip: 'Goals scored by team per 90 mins',
        axis: 'goals',
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.g / x.oc,
        denomfn: x => x.oc
    },
    go: {
        name: 'Goals Conceded',
        min: 0,
        max: 1000,
        sum: true,
        lowerIsBetter: true,
        axis: 'goals',
        tooltip: 'Number of goals conceded by team',
        icon: <FootballEvent id={1} fixedWidth/>
    },
    go90: {
        name: 'Goals Conceded / 90',
        min: 0,
        max: 2.5,
        sum: true,
        lowerIsBetter: true,
        tooltip: 'Number of goals conceded by team mins',
        axis: 'goals',
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.go / x.oc,
        denomfn: x => x.oc
    },
    goaldiff: {
        name: 'Av Goal Diff',
        min: -2,
        max: 2,
        sum: true,
        fn: x => (x.g - x.go) / (x.w + x.d + x.l),
        denomfn: x => x.w + x.d + x.l,
        axis: 'diff',
        tooltip: 'Team goals scored minus goals conceded',
        faIcon: {icon: faPlusMinus}
    },
    winrate: {
        name: 'Win Rate',
        min: 0,
        max: 100,
        sum: true,
        fn: (x) => 100 * x.w / (x.w + x.d + x.l),
        denomfn: x => x.w + x.d + x.l,
        axis: 'rating',
        tooltip: '% of matches won',
        faIcon: {icon: faPercent}
    },
    avpoints: {
        name: 'Av Points',
        min: 0,
        max: 3,
        sum: true,
        fn: (x) => (x.w * 3 + x.d) / (x.w + x.d + x.l),
        denomfn: x => x.w + x.d + x.l,
        axis: 'points',
        tooltip: 'Av league points (W=3, D=1, L=0)',
        faIcon: {icon: faTable}
    }
}

export function createTeamChildBars(range) {
    return _.get(range[COMPETITION], ID) != null ?
        <Link to={"/competition/" + range[COMPETITION][ID]}>
            <CompetitionBadge {...range[COMPETITION]} height={32}/>
        </Link> : <></>;
}

export function fetchTeamDetails(id) {
    return fetch(settings.apiServer + "team/" + id + ".json")
        .then(res => res.json())
}

export function calculatePeak(stats, field) {
    let values = _.filter(_.map(stats, (p, date) => ({
        date: date,
        value: calculateStatValue(p, field, fields)
    })), p => isValidNumber(p.value));
    return fields[field].reverse ? _.minBy(values, x => x.value) : _.maxBy(values, x => x.value);
}

export const TeamRatingTable = ({
                                    teams,
                                    title,
                                    display,
                                    nationalState,
                                    genderState,
                                    countryState,
                                    statsKey = STATS,
                                    seasonView = false
                                }) => {
    const [gender, setGender] = genderState || useState(0);
    const [national, setNational] = nationalState || useState(0);
    const [country, setCountry] = countryState || useState(null);
    const [sort, setSort] = useState(false);
    const [field, setField] = useState(RATING);

    let items = _.filter(teams, t => t[GENDER] === gender
        && t[NATIONAL] === national
        && (country == null || t[COUNTRY] === country)
    );

    if (sort) {
        items = _.orderBy(items, i => calculateStatValue(i[statsKey], field, fields), fields[field].lowerIsBetter ? "asc" : "desc");
    }
    const RenderedItem = ({index, style}) => {
        let t = items[index];
        return <ListGroup.Item action as={Link}
                               to={seasonView ? competitionSeasonUrl(t[SEASON_SECONDARY]) : teamProfileUrl(t[ID])}
                               key={t[ID]} style={style}>
            {seasonView ? <CompetitionBadge {...t[SEASON_SECONDARY][COMPETITION]}/> :
                <div className="d-inline-block" style={{marginTop: -4, marginLeft: -8}}>
                    <TeamLogo {...t} size={32}/>
                </div>}
            {seasonView ? <></> : <span className="ms-1">{t[STATS][RANK]}.</span>}
            <span className="ms-1">{seasonView ? formatCompetitionSeasonName(t[SEASON_SECONDARY]) : t[NAME]}</span>
            <span className="float-end">
                {seasonView ? <></> :
                    <span className="mx-1 cursor-pointer" onClick={(e) => {
                        stopDefault(e);
                        setCountry(country === t[COUNTRY] ? null : t[COUNTRY]);
                    }}>
                    <Flag id={t[COUNTRY]}/>
                </span>}
                {seasonView ?
                    <RatingBadge rt={calculateStatValue(t[statsKey], field, fields)} title="Team Average Rating"
                                 size={3} maxDp={1}/> :
                    <RatingBadge100 rt={calculateStatValue(t[statsKey], field, fields)} title="Team Rating"/>}
            </span>
        </ListGroup.Item>
    };

    let itemSize = 41;
    let height = display * itemSize;
    let content;
    if (teams == null) {
        content = <Loading/>
    } else {
        content = <List
            height={height}
            itemCount={items.length}
            itemSize={itemSize}>
            {RenderedItem}
        </List>
    }
    let list = _.map(fields, (value, key) => {
        return <Dropdown.Item key={key} onClick={() => setField(key)}
                              active={key === field}>
            {value.name}
        </Dropdown.Item>
    })
    return <Card className={"mb-3"}>
        <Card.Header className="p-2 bg-light text-dark">
            <span className="float-end">
                {seasonView ? <></> : <>
                    <NationalToggle selected={national} onChange={setNational} id="team"/>
                    <span className="ms-1"/>
                    <GenderToggle selected={gender} onChange={setGender} id="team"/>
                </>}
                <span className="ms-1"/>
                    <DefaultDropdown id="team-settings-dropdown" content={<FontAwesomeIcon icon={faCog}/>}>
                    <Dropdown.Item onClick={() => setSort(!sort)} active={sort}>
                        <FontAwesomeIcon icon={faSortAmountUpAlt} fixedWidth/>
                        <span className="text-dark ms-2">Sort</span>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                        {seasonView ? <></> : <>
                            <Dropdown.Header>Gender</Dropdown.Header>
                            <Dropdown.Item onClick={() => setGender(0)} active={gender === 0}>
                                <Gender gender={0} fixedWidth/>
                                <span className="text-dark ms-2">Male</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setGender(1)} active={gender === 1}>
                                <Gender gender={1} fixedWidth/>
                                <span className="text-dark ms-2">Female</span>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Header>International</Dropdown.Header>
                            <Dropdown.Item onClick={() => setNational(0)} active={national === 0}>
                                <FontAwesomeIcon icon={faCity} fixedWidth color={"#148f00"}/>
                                <span className="text-dark ms-2">Club</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setNational(1)} active={national === 1}>
                                <FontAwesomeIcon icon={faGlobeAmericas} fixedWidth className={"text-info"}/>
                                <span className="text-dark ms-2">National</span>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                        </>}
                        <Dropdown.Header>Statistic</Dropdown.Header>
                        {list}
                </DefaultDropdown>
            </span>
            <Card.Title>{title}</Card.Title>
        </Card.Header>
        <ListGroup className="list-group-flush" style={{height}}>
            {content}
        </ListGroup>
    </Card>
}

export const TopPlayersForTeam = ({team, season, referenceDate, type}) => {
    const [result, setResult] = useState(null);
    const {clientWidth} = useWindowContext();
    let teamId = team[NATIONAL] ? team[COUNTRY] : team[ID];

    useEffect(() => {
        let url;
        let seasonRequest = season != null;
        if (seasonRequest) {
            url = "seasons/" + season + ".json";
        } else if (team[NATIONAL]) {
            url = "teamplayers/" + teamId + ".json";
        } else {
            url = "teamsquad/" + teamId + ".json";
        }
        fetch(settings.apiServer + url)
            .then(res => res.json())
            .then(
                (result) => {
                    if (seasonRequest) {
                        setResult(_.filter(result.p, p => _.get(p[ZONE][CLUB], ID) === team[ID]))
                    } else {
                        setResult(result);
                    }
                },
                () => {
                    setResult(null);
                }
            )
    }, [team, season]);

    return <PlayerList
        players={result == null ? null : _.filter((season == null && !team[NATIONAL]) ? result[type] : result, p => p[GENDER] === team[GENDER])}
        referenceDate={referenceDate}
        defaultSort={null}
        size={clientWidth < breakpoints.xl ? 2 : 5}
        title={<Link to={"/playerratings?team=" + teamId} className={"btn btn-primary btn-sm"}>
            <FontAwesomeIcon icon={faUsers}/> Squad
        </Link>}
    />;
}

export const shortName = (name, maxLength = 3) => {
    if (name == null) {
        return '';
    }
    let shortName = '';
    const nameParts = name.split(/[\s,-]+/);
    for (let i = 0; i < Math.min(nameParts.length, maxLength); i++) {
        shortName += nameParts[i].substring(0, 1);
    }
    if (shortName.length < maxLength) {
        shortName += nameParts[nameParts.length - 1].substring(1, maxLength - shortName.length + 1);
    }
    return shortName.toUpperCase();
}

export const TeamSelectorOverlay = ({gender, national, id}) => {
    const [show, setShow] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    let height = 600;
    return <>
        <Button variant="primary" size={"sm"} className="py-0" onClick={handleShow}>
            <FontAwesomeIcon icon={faSearch}/> Compare
        </Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="py-2" closeButton>
                <Form.Control
                    id="team-search"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    className="rounded-pill search-input"
                    value={searchInputValue}
                    onChange={({target}) => setSearchInputValue(target.value)}
                />
            </Modal.Header>
            <Card className={"mb-2"}>
                <ListGroup className="list-group-flush" style={{height}}>
                    {show ?
                        <TeamSelectorList searchInputValue={searchInputValue} id={id} height={height} gender={gender}
                                          national={national}/> : <></>}
                </ListGroup>
            </Card>
        </Modal>
    </>
}

export const TeamSelectorList = ({searchInputValue, id, gender, national, height}) => {
    const [filteredIndex, setFilteredIndex] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        filteredSearch(o => o.type === 'team' && o[GENDER] === gender && o[NATIONAL] === national)
            .then(index => setFilteredIndex(index))
    }, []);

    useEffect(() => {
        if (filteredIndex != null) {
            setSearchResults(_.map(filteredIndex.search(searchInputValue, 100), item => item.item));
        }
    }, [filteredIndex, searchInputValue])

    const RenderedItem = ({index, style}) => {
        let team = searchResults[index];
        let targetUrl = "/teamcomparison/" + id + "-" + team[ID];
        return <ListGroup.Item as={Link} to={targetUrl} key={team[ID]} style={style} action>
            <div style={{position: 'absolute', left: 8, top: 3}}>
                <TeamLogo {...team} size={32}/>
            </div>
            <span className="ms-4">
                {displayName(team[NAME], 25)}
            </span>
            <span className="float-end">
            <Badge className="em4 px-0 ms-1 my-auto text-center" bg="custom" title="Team Rating"
                   style={{backgroundColor: hotScale(_.get(team, WEIGHT) / 100)}}>{_.round(_.get(team, WEIGHT), 2)}</Badge>
            </span>
        </ListGroup.Item>
    };

    let loaded = filteredIndex != null;

    if (loaded) {
        return <List
            height={height}
            itemCount={searchResults.length}
            itemSize={41}>
            {RenderedItem}
        </List>
    } else {
        return <Loading/>
    }
}

const SIZES = [20, 32, 80, 160];

export function getTeamImageUrl(size, id) {
    const teams = teamImageData.read();
    if (teams.has(id)) {
        return settings.imgServer + 'teams/' + sizeString(size, SIZES) + id + '.png';
    } else {
        return null;
    }
}

const TeamLogoRender = (props) => {
    let id = props[ID];
    let name = props[NAME];
    let srcList = _.filter([getTeamImageUrl(props.size, id), settings.imgServer + 'errors/noteam.svg'], p => p != null);
    const {src} = useImage({srcList})
    return <img alt={name + " Logo"} title={name} src={src} width={props.size} height={props.size} loading="lazy"/>
};

const TeamPlaceholder = (props) => {
    return <div className="fs--1 text-center em" style={{
        height: props.size,
        width: props.size,
    }}>{shortName(props[NAME], 3)}</div>;
}

export const TeamLogo = (props) => <Suspense fallback={TeamPlaceholder(props)}><TeamLogoRender {...props}/></Suspense>

export const TeamLogoLink = (props) => <Link to={teamProfileUrl(props[ID])} onClick={stopPropagation}>
    {props.placeholder ? <TeamPlaceholder {...props}/> : <TeamLogo {...props}/>}
</Link>

export const TeamLink = (props) => <Link to={teamProfileUrl(props[ID])} className={props.className}
                                         onClick={stopPropagation}>
    {props.short ? shortName(props[NAME]) : props[NAME]}
</Link>

export const TeamLinkShortener = ({display = "block", breakpoint = "sm", ...props}) => <>
    <div className={classNames("d-" + breakpoint + "-none", "d-" + display)}>
        <TeamLink {...props} short/>
    </div>
    <div className={classNames("d-none", "d-" + breakpoint + "-" + display)}>
        <TeamLink {...props}/>
    </div>
</>


