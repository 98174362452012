import { useEffect } from 'react';

const useToggleStylesheet = (isDark, configDispatch) => {
  useEffect(() => {
    document.documentElement.classList[isDark ? 'add' : 'remove']('dark');
    configDispatch({
      type: 'REFRESH'
    });
  }, [isDark]);
};

export default useToggleStylesheet;
