import React, {useEffect, useState, Suspense} from 'react';
import {Link, useParams} from "react-router-dom";
import {Accordion, Badge, Card, Col, Dropdown, ListGroup, Row} from "react-bootstrap";
import {Flag} from "./flag";
import _ from "lodash";
import {Gender} from "./icons";
import {settings} from "../../config";
import dayjs from "dayjs";
import {MatchList, processFixtureList} from "./matches";
import {Loading} from "./spinner";
import {hotScale} from "./colorprogress";
import Error500 from "../errors/Error500";
import {MatchHighlights} from "./highlights";
import {LeagueTable} from "./leaguetable";
import {Map} from "./map";
import {getTeamImageUrl} from "./team";
import {PlayerList} from "./player";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {COMPETITION, COUNTRY, END, GENDER, ID, NAME, SEASON, START} from "./alias";

export const formatSeasonName = (season) => {
    if (season == null) {
        return '';
    } else if (season[NAME] != null) {
        return season[NAME];
    } else {
        let startYear = dayjs(season[START]).year();
        let endYear = dayjs(season[END]).year();
        if (startYear === endYear) {
            return startYear;
        } else if (startYear + 1 === endYear) {
            return startYear + "/" + dayjs(season[END]).format("YY");
        } else {
            return startYear + " - " + endYear;
        }
    }
}

export const formatCompetitionSeasonName = (season) => {
    return season[COMPETITION][NAME] + " " + formatSeasonName(season);
}

const MapBody = ({teams}) => {
    const [mapVisible, setMapVisible] = useState(false);
    return <Accordion.Body className="p-0" onEntered={() => setMapVisible(true)} onExit={() => setMapVisible(false)}>
        {mapVisible ? <Map icons={teams}/> : <div/>}
    </Accordion.Body>;
}

function createMatchBoxes(groupedMatches, sort = 'desc') {
    return _.map(_.orderBy(_.keys(groupedMatches), x => x, sort), (date) => {
        return <Card className={"mb-3"} key={date}>
            <Card.Header className="p-2 bg-light text-dark">
                <Card.Title>{dayjs(date).format('DD MMMM YYYY')}</Card.Title>
            </Card.Header>
            <ListGroup className="list-group-flush">
                <MatchList matches={groupedMatches[date]} expanded={true}/>
            </ListGroup>
        </Card>
    });
}

function CompetitionProfile() {
    const {id, season} = useParams()
    const [error, setError] = useState(null);
    const [competition, setCompetition] = useState(null);
    const [seasonData, setSeasonData] = useState(null);

    useEffect(() => {
        fetch(settings.apiServer + "competition/" + id + ".json")
            .then(res => res.json())
            .then(
                (result) => {
                    setCompetition(result);
                    document.title = result[COMPETITION][NAME] + ' - Football Ratings';
                },
                (error) => {
                    setError(error);
                }
            )
    }, [])

    let matchSeason;
    if (season != null) {
        matchSeason = season;
    } else if (competition != null) {
        matchSeason = _.get(_.maxBy(_.get(competition, SEASON, []), START), ID);
    } else {
        matchSeason = null;
    }

    useEffect(() => {
        if (matchSeason != null) {
            Promise.all([fetch(settings.apiServer + "seasonfixtures/" + matchSeason + ".json").then(res => res.json()),
                fetch(settings.apiServer + "seasons/" + matchSeason + ".json").then(res => res.json())])
                .then(
                    (result) => {
                        let [fixtures, details] = result;
                        setSeasonData({matches: processFixtureList(fixtures), details});
                    },
                    (error) => {
                        setError(error);
                    }
                )
        }
    }, [matchSeason])

    if (error) {
        return <Error500/>;
    } else if (seasonData == null) {
        return <Loading/>;
    } else {
        return <Suspense fallback={<Loading/>}>
            <CompetitionSeasonRender id={id} competition={competition} {...seasonData}/>
        </Suspense>
    }
}

const CompetitionSeasonRender = ({id, competition, matches, details}) => {
    let seasons = _.get(competition, SEASON, []);
    let seasonItems = _.map(_.orderBy(seasons, START, 'desc'), s => {
        let targetUrl = "/competition/" + id + "/" + s[ID];
        return <Dropdown.Item key={s[ID]} as={Link} to={targetUrl}>
                <span className="me-2 text-primary">
                    {formatSeasonName(s)}
                    </span>
            <small className="float-end mt-1">
                {dayjs(s[START]).format('DD MMM YYYY')} - {dayjs(s[END]).format('DD MMM YYYY')}
            </small>
        </Dropdown.Item>
    })
    let fixtureMatches = _.filter(matches, m => m.ss === 0);
    let resultMatches = _.filter(matches, m => m.ss !== 0);
    let fixtureBoxes = createMatchBoxes(_.groupBy(fixtureMatches, m => dayjs(m[START]).format('YYYY-MM-DD')), 'asc')
    let resultBoxes = createMatchBoxes(_.groupBy(resultMatches, m => dayjs(m[START]).format('YYYY-MM-DD')))
    let teams = _.map(_.get(details, 't', []), t => ({...t, url: getTeamImageUrl(32, t[ID])}));
    teams = _.filter(teams, t => t.url != null);
    let leagueTables = _.get(details, 'l', []);
    let tables = _.map(leagueTables, (l, i) =>
        <Col key={i} sm={12} md={leagueTables.length > 1 ? 6 : 12}>
            <LeagueTable table={l}/>
        </Col>);
    let players = _.get(details, 'p', []);

    let accordionItems = [];
    if (players.length > 0) {
        accordionItems.push(<Accordion.Item key="players" eventKey="players">
            <Accordion.Header>Top players <Badge pill bg="secondary"
                                                 className="ms-3">{players.length}</Badge></Accordion.Header>
            <Accordion.Body className="p-0">
                <Row>
                    <Col md={12} lg={6}>
                        <PlayerList players={players}
                                    title={<Link to={"/playerratings?season=" + details[SEASON][ID] + "&only=true"}
                                                 className={"btn btn-primary btn-sm"}>
                                        <FontAwesomeIcon
                                            icon={faChartSimple}/> {competition[COUNTRY][NAME]} {formatSeasonName(details[SEASON])}
                                    </Link>} statsKey="z" size={3} ratingScale={10}
                                    referenceDate={details[SEASON][END]}/>
                    </Col>
                    <Col md={12} lg={6}>
                        <PlayerList players={players} title={<Link to={"/playerratings?season=" + details[SEASON][ID]}
                                                                   className={"btn btn-primary btn-sm"}>
                            <FontAwesomeIcon icon={faChartSimple}/> Career to {formatSeasonName(details[SEASON])}
                        </Link>} size={3} referenceDate={details[SEASON][END]}/>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>);
    }
    if (tables.length > 0) {
        accordionItems.push(<Accordion.Item key="tables" eventKey="tables">
            <Accordion.Header>League tables <Badge pill bg="secondary"
                                                   className="ms-3">{tables.length}</Badge></Accordion.Header>
            <Accordion.Body>
                <Row>
                    {tables}
                </Row>
            </Accordion.Body>
        </Accordion.Item>);
    }
    if (teams.length > 0) {
        accordionItems.push(<Accordion.Item key="map" eventKey="map">
            <Accordion.Header>
                Team Map <Badge pill bg="secondary" className="ms-3">{teams.length}</Badge>
            </Accordion.Header>
            <MapBody teams={teams}/>
        </Accordion.Item>);
    }

    if (resultBoxes.length > 0) {
        accordionItems.push(<Accordion.Item key="results" eventKey="results">
            <Accordion.Header>Results <Badge pill bg="secondary" className="ms-3">{resultMatches.length}</Badge>
            </Accordion.Header>
            <Accordion.Body className="p-0">
                {resultBoxes}
            </Accordion.Body>
        </Accordion.Item>)
    }

    if (fixtureBoxes.length > 0) {
        accordionItems.push(<Accordion.Item key="fixtures" eventKey="fixtures">
            <Accordion.Header>Upcoming Fixtures <Badge pill bg="secondary"
                                                       className="ms-3">{fixtureMatches.length}</Badge></Accordion.Header>
            <Accordion.Body className="p-0">
                {fixtureBoxes}
            </Accordion.Body>
        </Accordion.Item>)
    }

    let accordion = <Accordion defaultActiveKey={["players"]} alwaysOpen>
        {accordionItems}
    </Accordion>
    return (
        <Row>
            <Col md={9}>
                <Row>
                    <Col>
                        <Dropdown>
                            <Dropdown.Toggle id="season-dropdown-toggle" variant="primary" className={"float-start"}>
                                Seasons
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {seasonItems}
                            </Dropdown.Menu>
                        </Dropdown>
                        <h2 className="text-center">
                            <Flag id={competition[COMPETITION][COUNTRY]} size="2x"/>
                            <span className="mx-2">
                                {competition[COMPETITION][NAME]} {formatSeasonName(details[SEASON])}
                            </span>
                            <Gender gender={competition[COMPETITION][GENDER]} hiddenValue={0}/>
                        </h2>
                    </Col>
                </Row>
                <Row>
                    {accordion}
                </Row>
            </Col>
            <Col md={3}>
                <MatchHighlights matches={matches}/>
            </Col>
        </Row>
    );
}

export default CompetitionProfile;

export const CompetitionBadge = ({n, rt, cd, height, c}) => {
    return <Badge className="em5" bg="custom"
                  title={n}
                  style={{
                      backgroundColor: hotScale(rt / 100),
                      height: height,
                  }}>
        {c != null ? <Flag id={c} size="sm"/> : <></>}
        <span style={{marginLeft: 2}}>
            {cd.substring(0, 4)}
        </span>
    </Badge>;
}