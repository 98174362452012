import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Card, Dropdown, ListGroup} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {settings} from "../../config";
import Error500 from "../errors/Error500";
import {useMediaViewer} from "../../hooks/useMediaViewer";
import {FixedSizeList as List} from "react-window";
import _ from "lodash";
import {MatchTimeline} from "./matchtimeline";
import {TeamLinkShortener, TeamLogoLink} from "./team";
import {Img} from "react-image";
import {LazyLoadComponent, trackWindowScroll} from "react-lazy-load-image-component";
import {VerticalProgressBar} from "./colorprogress";
import dayjs from "dayjs";
import {RatingPill} from "./matchreport";
import {getCompetition, processFixtureList, VerticalScorePartial} from "./matches";
import {
    AWAY,
    EVENT,
    GENDER,
    HOME,
    ID,
    MATCH,
    MEDIA,
    PERIOD,
    PLAYER_END_PERIOD,
    PLAYER_END_SECONDS,
    PLAYER_START_PERIOD,
    PLAYER_START_SECONDS,
    RATING,
    SCORE,
    SECONDS,
    START
} from "./alias";
import {Gender, VideoToggle} from "./icons";
import {stopDefault, stopPropagation} from "../../helpers/utils";
import {useWindowContext} from "../../hooks/useWindowContext";
import {
    faCog,
    faDumbbell,
    faFilm,
    faFutbol,
    faListNumeric,
    faMedal,
    faSortAmountUpAlt,
    faSortNumericUpAlt,
    faTemperatureHigh,
    faTimeline
} from "@fortawesome/free-solid-svg-icons";
import {DefaultDropdown} from "./player";
import {CompetitionBadge} from "./competition";
import {Loading} from "./spinner";

export const YoutubeThumb = ({
                                 thumbHeight,
                                 thumbWidth,
                                 fallback,
                                 video,
                                 onClick = stopPropagation,
                                 opensInNewWindow
                             }) => {
    let hq = thumbWidth > 300;
    return <Img height={thumbHeight}
                width={thumbWidth}
                alt="Youtube Highlights"
                loader={fallback}
                unloader={fallback}
                loading="lazy"
                src={"https://i.ytimg.com/vi/" + video[ID] + "/" + (hq ? "maxres" : "mq") + "default.jpg"}
                container={(children) => {
                    let youtubeIcon = <FontAwesomeIcon icon={faYoutube}
                                                       style={{
                                                           left: (thumbWidth - 27 * (hq ? 2 : 1)) / 2,
                                                           top: (thumbHeight - 24 * (hq ? 2 : 1)) / 2
                                                       }}
                                                       className="position-absolute text-youtube"
                                                       size={hq ? "3x" : "lg"}/>;
                    if (opensInNewWindow) {
                        return <a href={"https://www.youtube.com/watch?v=" + video[ID]} target="_blank"
                                  rel="noreferrer noopener" onClick={stopPropagation}>
                            {youtubeIcon}
                            {children}</a>
                    } else {
                        return <span onClick={onClick}>{youtubeIcon}{children}</span>
                    }
                }}
    />;
}

const YoutubeVideo = ({match, thumbHeight, thumbWidth, video, name, showTimeline = false}) => {
    let fallback;
    if (match[SCORE] != null) {
        fallback = <div className="flex-vertical-align px-2" style={{height: thumbHeight, width: thumbWidth}}>
            <MatchTimeline events={match[SCORE][EVENT]}
                           player={name}
                           startEvent={match[PLAYER_START_PERIOD] != null ? ({
                               [PERIOD]: match[PLAYER_START_PERIOD],
                               [SECONDS]: match[PLAYER_START_SECONDS]
                           }) : null}
                           endEvent={match[PLAYER_END_PERIOD] != null ? ({
                               [PERIOD]: match[PLAYER_END_PERIOD],
                               [SECONDS]: match[PLAYER_END_SECONDS]
                           }) : null}
            />
        </div>;
    } else {
        fallback = <></>
    }
    if (video != null && !showTimeline) {
        let {setActiveMedia} = useMediaViewer();
        let onClick = e => {
            setActiveMedia(video);
            stopDefault(e);
        };
        return <YoutubeThumb thumbHeight={thumbHeight} thumbWidth={thumbWidth} fallback={fallback} video={video}
                             onClick={onClick}/>;
    } else {
        return fallback;
    }
}

const MatchHighlightVertical = ({match, thumbHeight, highlight, showTimeline, placeholder}) => {
    let video = (match[MEDIA] != null && match[MEDIA].length > 0) ? match[MEDIA][0] : null;
    let {team, player, name} = highlight;
    let thumbWidth = _.round(thumbHeight * 16 / 9);
    let competition = getCompetition(match);
    return <div className={"d-flex flex-row w-100 position-relative"} style={{height: thumbHeight + 2}}>
        <div className="flex-shrink-0 me-1">
            <VerticalProgressBar min={0} max={10} now={_.get(match[SCORE], RATING, 5)}/>
        </div>
        <div className="flex-grow-1">
            <div className="h-50 flex-vertical-align w-100">
                <VerticalScorePartial match={match} home={true} showAggregateInBrackets={true}/>
                <div className="position-relative z-index-1">
                    <TeamLogoLink {...match[HOME]} size={32} placeholder={placeholder}/>
                    {(team === match[HOME][ID]) ? <RatingPill rt={_.get(match[SCORE], 'rt', 5)} left/> : <></>}
                    {(player != null && match.hp === 1) ?
                        <RatingPill player rt={_.get(match, RATING, 5)} left/> : <></>}
                </div>
                <TeamLinkShortener {...match[HOME]} display={"inline"} breakpoint="xxl"
                                   className="mx-1 position-relative z-index-1"/>
                <Gender gender={match[HOME][GENDER]} hiddenValue={0}/>
            </div>
            <div className="h-50 flex-vertical-align w-100 ">
                <VerticalScorePartial match={match} home={false} showAggregateInBrackets={true}/>
                <div className="position-relative z-index-1">
                    <TeamLogoLink {...match[AWAY]} size={32} placeholder={placeholder}/>
                    {team === match[AWAY][ID] ? <RatingPill rt={10 - _.get(match[SCORE], RATING, 5)} left/> : <></>}
                    {(player != null && match.hp === 0) ?
                        <RatingPill player rt={_.get(match, 'rt', 5)} left/> : <></>}
                </div>
                <TeamLinkShortener {...match[AWAY]} display={"inline"} breakpoint="xxl"
                                   className="mx-1 position-relative z-index-1"/><Gender
                gender={match[AWAY][GENDER]} hiddenValue={0}/>
            </div>
        </div>
        {competition != null ?
            <span style={{right: thumbWidth, top: 0, marginTop: -8}} className="position-absolute z-index-1">
            <CompetitionBadge height={16} {...competition}/>
        </span> : <></>}
        <span style={{right: thumbWidth, top: '50%', marginTop: -8}}
              className="position-absolute z-index-1 pe-1 py-0 fs--2">
            {dayjs(match[START]).format('DD MMM YY')}
        </span>
        {placeholder ? <></> :
            <div style={{width: thumbWidth, height: thumbHeight, position: "absolute", right: 0, top: 0}}>
                <YoutubeVideo match={match} thumbHeight={thumbHeight} thumbWidth={thumbWidth} video={video}
                              showTimeline={showTimeline} name={name}/>
            </div>}
    </div>
}

const MatchHighlightHorizontal = ({match, thumbHeight, itemHeight, highlight, showTimeline}) => {
    let video = (match.m != null && match.m.length > 0) ? match.m[0] : null;
    let {teams, player, name} = highlight;
    let thumbWidth = _.round(thumbHeight * 16 / 9);
    let competition = getCompetition(match);
    return <div>
        <div style={{width: thumbWidth, height: thumbHeight, position: "relative"}}>
            <YoutubeVideo match={match} thumbHeight={thumbHeight} thumbWidth={thumbWidth} video={video}
                          showTimeline={showTimeline} name={name}/>
        </div>
        <div className={"d-flex flex-row w-100 pt-1 m-0"} style={{height: itemHeight - thumbHeight}}>
            <div className="me-1">
                <VerticalProgressBar min={0} max={10} now={_.get(match[SCORE], RATING, 5)}/>
            </div>
            <div className={"flex-grow-1 position-relative"}>
                        <span style={{right: 0, top: '50%', marginTop: -8}}
                              className="position-absolute z-index-1 pe-1 py-0 fs--2">
            {dayjs(match[START]).format('DD MMM YY')}
        </span>
                {competition != null ?
                    <span style={{right: thumbWidth, top: 0, marginTop: -8}} className="position-absolute z-index-1">
            <CompetitionBadge height={16} {...competition}/>
        </span> : <></>}
                <div className="h-50 flex-vertical-align w-100">
                    <VerticalScorePartial match={match} home={true} showAggregateInBrackets={true}/>
                    <div className="position-relative">
                        <TeamLogoLink {...match[HOME]} size={32}/>
                        {teams.includes(match[HOME][ID]) ?
                            <RatingPill rt={_.get(match[SCORE], RATING, 5)} left/> : <></>}
                        {(player != null && match.hp === 1) ?
                            <RatingPill player rt={_.get(match, 'rt', 5)} left/> : <></>}
                    </div>
                    <TeamLinkShortener {...match[HOME]} display={"inline"} breakpoint="xxl" className="mx-1"/><Gender
                    gender={match[HOME][GENDER]} hiddenValue={0}/>
                </div>
                <div className="h-50 flex-vertical-align w-100">
                    <VerticalScorePartial match={match} home={false} showAggregateInBrackets={true}/>
                    <div className="position-relative">
                        <TeamLogoLink {...match[AWAY]} size={32}/>
                        {teams.includes(match[AWAY][ID]) ?
                            <RatingPill rt={10 - _.get(match[SCORE], RATING, 5)} left/> : <></>}
                        {(player != null && match.hp === 0) ?
                            <RatingPill player rt={_.get(match, RATING, 5)} left/> : <></>}
                    </div>
                    <TeamLinkShortener {...match[AWAY]} display={"inline"} breakpoint="xxl" className="mx-1"/><Gender
                    gender={match[AWAY][GENDER]} hiddenValue={0}/>
                </div>
            </div>
        </div>
    </div>
}

export const hasMedia = (matchItem) => {
    return (matchItem[MEDIA] != null && matchItem[MEDIA].length > 0);
}

export const hasEvents = (matchItem) => {
    return (matchItem[SCORE] != null && matchItem[SCORE][EVENT] != null && matchItem[SCORE][EVENT].length > 0);
}

const Gallery = ({videos, thumbHeight, scrollPosition, highlight, showTimeline}) => {
    const navigate = useNavigate();
    let height = thumbHeight + 8;
    return _.map(videos, (mediaItem, i) => {
        let placeholder = <ListGroup.Item variant="flush"
                                          className={"d-flex flex-row px-0 py-1 list-group-item-action cursor-pointer"}>
            <MatchHighlightVertical match={mediaItem} thumbHeight={thumbHeight} highlight={highlight}
                                    showTimeline={showTimeline} placeholder/>
        </ListGroup.Item>
        return <LazyLoadComponent key={i} scrollPosition={scrollPosition} height={height} placeholder={placeholder}>
            <ListGroup.Item variant="flush" onClick={() => navigate("/match/" + mediaItem.i)}
                            className={"d-flex flex-row px-0 py-1 list-group-item-action cursor-pointer"}>
                <MatchHighlightVertical match={mediaItem} thumbHeight={thumbHeight} highlight={highlight}
                                        showTimeline={showTimeline}/>
            </ListGroup.Item>
        </LazyLoadComponent>
    });
}

const HorizontalGallery = ({videos, thumbHeight, highlight, showTimeline}) => {
    const ref = useRef(null);
    const {clientWidth} = useWindowContext();
    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, [clientWidth]);
    let thumbWidth = Math.round(thumbHeight * 16 / 9);

    let itemHeight = thumbHeight + 120;

    const Column = ({index, style}) => {
        let mediaItem = videos[index];
        return <div style={{...style}} onClick={() => navigate("/match/" + mediaItem[ID])} className={"cursor-pointer"}>
            <MatchHighlightHorizontal match={mediaItem} thumbHeight={thumbHeight} itemHeight={itemHeight}
                                      highlight={highlight} showTimeline={showTimeline}/>
        </div>
    };
    const navigate = useNavigate();
    return <div ref={ref}>
        <List height={itemHeight + 12}
              itemCount={videos.length}
              itemSize={thumbWidth}
              layout="horizontal"
              width={width}
              className="p-0 m-0 overflow-y-hidden">
            {Column}
        </List>
    </div>
}

const LazyGallery = trackWindowScroll(Gallery);

export const MatchHighlights = ({
                                    playerIds = [],
                                    teamIds = [],
                                    highlightName = null,
                                    filterVideos = true,
                                    horizontal = false,
                                    matches = [],
                                    maxDisplay = 20
                                }) => {
    const [error, setError] = useState(null);
    const [onlyVideos, setOnlyVideos] = useState(filterVideos);
    const [contextualVideos, setContextualVideos] = useState(null);
    const [display, setDisplay] = useState(maxDisplay);
    const [sort, setSort] = useState(null);
    const [showTopMatches, setShowTopMatches] = useState(true);

    useEffect(() => {
        let urls = [];
        if (playerIds.length > 0) {
            urls.push(..._.map(playerIds, p => "playerfixtures/" + p + ".json"));
        }
        if (teamIds.length > 0) {
            urls.push(..._.map(teamIds, t => "teamfixtures/" + t + ".json"));
        }
        if (urls.length === 0) {
            urls.push('fixtures/top.json');
        }
        Promise.all(urls.map(url => fetch(settings.apiServer + url).then(res => res.json())))
            .then(
                (results) => {
                    let index = 0;
                    let contextualMatches = [];
                    _.forEach(results, r => {
                        let matches = processFixtureList(r);
                        _.forEach(matches, (m) => {
                            m.priority = index++;
                            contextualMatches.push(m);
                        });
                    });
                    setContextualVideos(contextualMatches);
                },
                (error) => setError(error)
            );
    }, []);

    function addMatches(orderedMatches, matchesToAdd) {
        let processedMatches = new Set(_.map(orderedMatches, v => v[ID]));

        let followingVideos = _.filter(matchesToAdd, m => !processedMatches.has(m[ID]));
        let groups = _.groupBy(followingVideos, m => m[ID]);
        let uniqueMatches = [];
        _.each(groups, (ms) => {
            let m = ms[0];
            uniqueMatches.push({m: m, priority: m.priority - ms.length * 10000});
        })
        uniqueMatches = _.sortBy(uniqueMatches, 'priority');
        orderedMatches.push(..._.map(uniqueMatches, m => m[MATCH]));
    }

    if (error) {
        return <Error500/>;
    } else if (contextualVideos == null) {
        return <Loading/>;
    } else {
        let orderedMatches = [...matches];
        addMatches(orderedMatches, contextualVideos);

        if (sort === 'goals') {
            orderedMatches = _.orderBy(orderedMatches, m => _.get(m[SCORE], HOME) + _.get(m[SCORE], AWAY), 'desc');
        } else if (sort === 'rating') {
            if (teamIds.length > 0 || playerIds.length > 0) {
                orderedMatches = _.orderBy(orderedMatches, m => _.get(m, RATING, 0), 'desc');
            } else {
                orderedMatches = _.orderBy(orderedMatches, m => _.get(m[SCORE], RATING, 0), 'desc');
            }
        } else if (sort === 'strength') {
            orderedMatches = _.orderBy(orderedMatches, m => _.get(m[HOME], RATING, 0) + _.get(m[AWAY], RATING, 0), 'desc');
        }
        let matchesWithVideos = _.filter(orderedMatches, hasMedia);
        let hasVideos = matchesWithVideos.length > 0;
        let showVideos = hasVideos && onlyVideos;
        let filteredMatches = _.slice(showVideos ? matchesWithVideos : orderedMatches, 0, display);

        let content;
        if (horizontal) {
            content = <HorizontalGallery videos={filteredMatches} thumbHeight={180} showTimeline={!showVideos}
                                         highlight={{
                                             teams: teamIds,
                                             player: _.get(playerIds, 0),
                                             name: highlightName
                                         }}/>
        } else {
            content = <LazyGallery videos={filteredMatches} thumbHeight={100} showTimeline={!showVideos}
                                   highlight={{teams: teamIds, player: _.get(playerIds, 0), name: highlightName}}/>
        }
        return <Card className="mb-2">
            <Card.Header className="p-2 bg-light text-dark text-center">
                <span className="float-end">
                    <VideoToggle selected={showVideos ? 1 : 0} onChange={setOnlyVideos} id="highlights" disableVideo={!hasVideos}/>
                    <span className="ms-1"/>
                    <DefaultDropdown id="highlights-settings-dropdown" content={<FontAwesomeIcon icon={faCog}/>}>
                    <Dropdown.Header>Sort</Dropdown.Header>
                        <Dropdown.Item onClick={() => setSort(null)} active={sort === null}>
                            <FontAwesomeIcon icon={faSortAmountUpAlt} fixedWidth/>
                            <span className="ms-2">Default</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSort('goals')} active={sort === 'goals'}>
                            <FontAwesomeIcon icon={faFutbol} fixedWidth/>
                            <span className="ms-2">Goals</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSort('rating')} active={sort === 'rating'}>
                            <FontAwesomeIcon icon={faMedal} fixedWidth/>
                            <span className="ms-2">Rating</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSort('strength')} active={sort === 'strength'}>
                            <FontAwesomeIcon icon={faDumbbell} fixedWidth/>
                            <span className="ms-2">Team Strength</span>
                        </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Header>Display</Dropdown.Header>
                        <Dropdown.Item onClick={() => setDisplay(20)} active={display === 20}>
                            <FontAwesomeIcon icon={faSortNumericUpAlt} fixedWidth/>
                            <span className="ms-2">20 Recent</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDisplay(1000)} active={display === 1000}>
                            <FontAwesomeIcon icon={faListNumeric} fixedWidth/>
                            <span className="ms-2">All Recent</span>
                        </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Header>View Mode</Dropdown.Header>
                        <Dropdown.Item onClick={() => setOnlyVideos(false)} active={!showVideos}>
                            <FontAwesomeIcon icon={faTimeline} fixedWidth/>
                            <span className="ms-2">Timeline</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setOnlyVideos(true)} active={showVideos}>
                            <FontAwesomeIcon icon={faFilm} fixedWidth/>
                            <span className="ms-2">Videos</span>
                        </Dropdown.Item>
                    <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => setShowTopMatches(!showTopMatches)} active={showTopMatches}>
                            <FontAwesomeIcon icon={faTemperatureHigh}/>
                            <span className="ms-2">Other Highlights</span>
                        </Dropdown.Item>
                </DefaultDropdown>
                </span>
                <Card.Title>Highlights</Card.Title>
            </Card.Header>
            <ListGroup variant="flush">
                {content}
            </ListGroup>
        </Card>
    }
}