export const NAME = 'n';
export const ID = 'i';
export const WEIGHT = 'w';
export const TEAM = 't';
export const ACTIVE = 'a';
export const ZONE = 'z';
export const GENDER = 'g';
export const COUNTRY = 'c';
export const NATIONAL = 'o';
export const RATING = 'rt';
export const ATT = 'att';
export const DEF = 'def';
export const THREAT = 'thr';
export const CREATIVITY = 'cre';
export const INFLUENCE = 'inf';
export const SHOOTING = 'sht';
export const ASSISTING = 'ass';
export const PASSING = 'pss';
export const ADVANCING = 'adv';
export const TACKLING = 'tkl';
export const BLOCKING = 'blk';
export const AERIALS = 'aer';
export const POSSESSION = 'psn';
export const DUELS = 'dul';
export const AWAY = 'a';
export const HOME = 'h';
export const SCORE = 'sc';
export const DATE = 'dt';
export const START = 'st';
export const END = 'en';
export const EVENT = 'e';
export const MEDIA = 'm';
export const MATCH = 'm';
export const COMPETITION = 'c';
export const SEASON = 's';
export const ROUND = 'r';
export const RANK = 'r';
export const RANK_COUNTRY = 'rc';
export const POSITION = 'pos';
export const CLUB = 'cl';
export const STATS = 's';
export const SEASON_STATS = 'z';
export const STATUS = 'ss';
export const SEASON_SECONDARY = 'ss';
export const CODE = 'cd';
export const TYPE = 't';
export const PERIOD = 'p';
export const SECONDS = 's';
export const PLAYER = 'pl';
export const RELATED = 'rl';
export const VENUE = 'v';
export const YOUTH = 'y';
export const FRIENDLY = 'f';
export const PRIMARY = 'p';
export const PLAYERID = 'p';
export const PLAYER_START_PERIOD = "stp";
export const PLAYER_START_SECONDS = "sts";
export const PLAYER_END_PERIOD = "enp";
export const PLAYER_END_SECONDS = "ens";
export const BIO = "bio";
export const TEXT = "t";