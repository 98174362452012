import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Badge, Card, Col, ListGroup, Row} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {Flag} from "./flag";
import {TeamLink, TeamLogoLink} from "./team";
import {getPosition} from "data/lookup"
import {Position, ShirtNumber} from "./position";
import dayjs from "dayjs";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendar, faCopyright, faFutbol, faMapPin, faPlus, faUser} from "@fortawesome/free-solid-svg-icons";
import {Pitch, PitchIcon} from "./pitch";
import {FootballEvent, Gender} from "./icons";
import {displayName} from "./player";
import CardHeader from "react-bootstrap/CardHeader";
import FalconCloseButton from "../common/FalconCloseButton";
import {settings} from "../../config";
import {breakpoints, getColor} from "../../helpers/utils";
import {ColorScaleProgressBar, hotScale} from "./colorprogress";
import {isEventForPlayer, MatchTimeline} from "./matchtimeline";
import {useWindowContext} from "../../hooks/useWindowContext";
import {formatRank} from "./ranks";
import Error500 from "../errors/Error500";
import {TCIRadar} from "../chart/tciradar";
import {formatFixed} from "../../helpers/numberutils";
import {playerProfileUrl, teamProfileUrl} from "../../helpers/urls";
import {ScoreElement, VerticalScorePartial} from "./matches";
import {MatchHighlights} from "./highlights";
import {
    AWAY,
    COMPETITION,
    COUNTRY,
    EVENT,
    GENDER,
    HOME,
    ID,
    MATCH,
    NAME,
    PERIOD,
    PLAYER,
    RANK,
    RANK_COUNTRY,
    RATING,
    RELATED,
    ROUND,
    SCORE,
    SEASON,
    SECONDS,
    START,
    STATS,
    TYPE,
    VENUE
} from "./alias";
import {CompetitionBadge} from "./competition";
import {Loading} from "./spinner";

const _ = require('lodash');

const Lineup = ({team, players, events}) => {
    const [activePlayer, setActivePlayer] = useState(null);
    if (players == null || players.length === 0) {
        return <></>;
    }
    let sorted = _.sortBy(players, p => getPosition(p.pos).z, p => getPosition(p.pos).r, 'no')
    let subs = _.filter(sorted, p => p.sub);
    let starting = _.filter(sorted, p => !p.sub);
    let sortedEvents = _.sortBy(events, [e => e[PERIOD]], [e => e[SECONDS]]);
    return <div className="mt-3">
        <ListGroup>
            <ListGroup.Item variant="light">
                <TeamLogoLink {...team} size={32}/>
                <TeamLink {...team} className={"mx-1"}/>
            </ListGroup.Item>
            {_.map(starting, mapToListItem)}
            <ListGroup.Item variant="light">
                <TeamLogoLink {...team} size={32}/>
                <TeamLink {...team} className={"mx-1"}/> (Subs)
            </ListGroup.Item>
            {_.map(subs, mapToListItem)}
        </ListGroup>
    </div>;

    function mapToListItem(mp) {
        let playerEvents = _.filter(sortedEvents, e => isEventForPlayer(e, mp[PLAYER][ID]));
        let eventSpan = _.map(playerEvents, (e, i) => {
            let relatedId = _.get(e[RELATED], ID);
            let related = relatedId === mp[PLAYER][ID];
            return (
                <FootballEvent key={i} id={e[TYPE]} p={e[PERIOD]} player={_.get(e[PLAYER], NAME)} secs={SECONDS}
                               rel={_.get(e[RELATED], NAME)}
                               onClick={() => setActivePlayer(related ? _.get(e[PLAYER], ID, null) : relatedId)}
                               related={related}
                               fixedWidth
                               className="mx-1"/>);
        });
        return <ListGroup.Item key={mp[PLAYER][ID]} variant={mp[PLAYER][ID] === activePlayer ? 'light' : ''}>
            <Position id={mp.pos}/>
            <ShirtNumber number={mp.no} className="float-end"/>
            <span className="mx-1"><Link to={playerProfileUrl(mp[PLAYER][ID])}>{mp[PLAYER][NAME]}</Link></span>
            <div className="float-end"><Flag id={mp[PLAYER][COUNTRY]}/></div>
            <div className="d-inline-block float-end">{eventSpan}</div>
        </ListGroup.Item>
    }
}

export const RatingBadge = ({rt, maxScale = 10, dp = 1, size = 2, title = "Rating"}) => {
    return <Badge className={"px-0 my-auto text-center em" + size} bg="custom"
                  title={title}
                  style={{
                      backgroundColor: hotScale(rt / maxScale),
                      zIndex: 5,
                  }}>{_.round(rt, dp).toFixed(dp)}</Badge>;
}

export const RatingBadge100 = ({rt, title = "Rating", dp = 1}) =>
    <RatingBadge rt={rt} maxScale={100} dp={dp} size={2 + dp} title={title}/>

export const RatingPill = ({rt, player = false, left = false, top = false}) => {
    return <Badge pill bg="custom"
                  title="Rating"
                  style={{
                      backgroundColor: hotScale((rt) / 10),
                      zIndex: 2,
                      position: 'absolute',
                      [top ? 'top' : 'bottom']: -12,
                      [left ? 'left' : 'right']: -2 + (player ? -5 : 0)
                  }}>{player ? <FontAwesomeIcon icon={faUser} size="1x"/> : <></>}{formatFixed(rt, 1)}</Badge>;
}

const PitchReport = ({report}) => {

    const [activePlayer, setActivePlayer] = useState(null);

    const ref = useRef(null);

    const {clientWidth, clientHeight} = useWindowContext();
    const [width, setWidth] = useState(clientWidth);

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, [clientWidth]);

    if (report[HOME] == null && report[AWAY] == null) {
        return <div ref={ref}/>;
    }

    let events = _.get(report[MATCH][SCORE], EVENT, []);

    let vertical = width < breakpoints.md;
    let pitchWidth = vertical ? width : Math.min(_.round(clientHeight * 0.7 * 1070 / 700), width * 0.75);

    function closePlayer() {
        setActivePlayer(null);
    }

    function mapSubs(subs, vertical) {
        return _.map(subs, (p) => createPitchIcon(p, activePlayer === p.i, vertical));
    }

    let homePlayers = _.get(report, HOME, []);
    let awayPlayers = _.get(report, AWAY, []);

    let minValue = _.min(_.map(_.concat(homePlayers, awayPlayers), p => _.min([p.rt, p.def, p.cre, p.inf, p.thr, p.att])));
    let maxValue = _.max(_.map(_.concat(homePlayers, awayPlayers), p => _.max([p.rt, p.def, p.cre, p.inf, p.thr, p.att])));

    function createPitchIcon(p, active, vertical) {
        let w = 120;
        let h = 80;
        let icons = [];
        if (p[RATING] != null) {
            icons.push(<RatingBadge key={p.id} rt={p[RATING]}/>);
        }
        if (p[COUNTRY]) {
            icons.push(<FontAwesomeIcon key={p.id + "-c"} icon={faCopyright} style={{color: '#fff583'}}
                                        title="Captain"/>);
        }
        icons.push(..._.map(p[EVENT], (e) => <FootballEvent key={e[SECONDS]} id={e[TYPE]} p={e[PERIOD]}
                                                            player={_.get(e[PLAYER], NAME)} secs={e[SECONDS]}
                                                            rel={_.get(e[RELATED], NAME)}
                                                            related={_.get(e[RELATED], ID) === p[ID]}/>));


        let r = 36;
        let theta = Math.PI;
        let thetaStep = Math.PI / 5;
        let iconWrapper = _.map(icons, (icon, i) => {
            let x = r * Math.cos(theta + thetaStep * i);
            let y = r * Math.sin(theta + thetaStep * i);
            return <div key={i}
                        style={{left: (w - 16) / 2 + x, top: (h - 16) / 2 + y - 10, position: 'absolute', zIndex: 4}}>
                {icon}
            </div>
        });
        return <PitchIcon x={p.x} y={p.y} height={h} width={w} home={p[HOME]} key={p[ID]}
                          onClick={() => setActivePlayer(p[ID])}
                          vertical={vertical}>
            <div className="w-100" style={{height: h - 24}}>
                <TCIRadar data={[p.rt, p.def, p.cre, p.inf, p.thr, p.att]} minValue={minValue}
                          maxValue={maxValue} showLabels={false} color={getColor('secondary')}/>
            </div>
            <div>
                <Badge bg={active ? 'dark' : 'light'}
                       text={active ? 'light' : 'dark'}>{displayName(p[NAME], vertical ? 9 : 12)}</Badge>
            </div>
            <div>{iconWrapper}</div>
        </PitchIcon>
    }

    let startingPlayerIconDetails = _.concat(
        extractPlayerDetails(homePlayers, events, true, false),
        extractPlayerDetails(awayPlayers, events, false, false)
    );
    let pitchOverlays = _.map(startingPlayerIconDetails, (p) => createPitchIcon(p, activePlayer === p[ID], vertical));

    if (report.p) {
        pitchOverlays.push(<PitchIcon x={0.5} y={0.02} height={20} width={120} home={true} key={"preview"}
                                      vertical={vertical}>
            <Badge pill bg={"secondary"}>
                Predicted Lineup
            </Badge>
        </PitchIcon>)
    }

    let homeSubOverlays = mapSubs(extractPlayerDetails(homePlayers, events, true, true), vertical);
    let awaySubOverlays = mapSubs(extractPlayerDetails(awayPlayers, events, false, true), vertical);

    function createPlayerDetails(mp, home, vertical) {
        let left = home ? '52%' : '2%';

        let subOnEvent = _.filter(events, e => e[TYPE] === 4 && e[PLAYER][ID] === mp[PLAYER][ID]);
        let startEvent = mp.sub ? _.get(subOnEvent, 0) : {p: 1, s: 0};
        let endEvent = _.get(_.filter(events, e => e[TYPE] === 5 && e[PLAYER][ID] === mp[PLAYER][ID]), 0);
        return <Card variant="dark" key={"overlay-" + mp[PLAYER][ID]}
                     style={{
                         position: 'absolute',
                         width: vertical ? '100%' : '46%',
                         height: vertical ? '50%' : '96%',
                         left: vertical ? '0%' : left,
                         top: vertical ? (home ? '50%' : '0%') : '1%',
                         zIndex: 10,
                     }}>
            <CardHeader>
                <FalconCloseButton onClick={() => closePlayer()} className="float-end"/>
                <h2><Link to={playerProfileUrl(mp[PLAYER][ID])}>{mp[PLAYER][NAME]}</Link></h2>
            </CardHeader>
            <Row style={{height: 190}}>
                <Col xs={6} className="text-center">
                    <TeamLogoLink {...(mp[HOME] ? report[MATCH][HOME] : report[MATCH][AWAY])} size={160}/>
                </Col>
                <Col xs={6} className="h-100" style={{width: 190}}>
                    <TCIRadar data={[mp.rt, mp.def, mp.cre, mp.inf, mp.thr, mp.att]}/>
                </Col>
            </Row>
            <div className="d-flex d-flex-row">
                <div className="float-end mx-2"><Flag id={mp[PLAYER][COUNTRY]}/></div>
                <Position id={mp.pos}/>
                <ShirtNumber number={mp.no} className="float-end"/>
            </div>
            <Row>
                <Col className="mx-2">
                    <MatchTimeline events={events} player={activePlayer} startEvent={startEvent} endEvent={endEvent}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup className="bg-light">
                        <ColorScaleProgressBar name="xG"
                                               min={0}
                                               max={2}
                                               value={mp.xg}
                                               faIcon={{icon: faFutbol, color: "#ffffff", fixedWidth: true}}
                                               tooltip="Expected Goals"
                                               maxDp={2}/>
                        <ColorScaleProgressBar name="xGPS"
                                               min={0}
                                               max={2}
                                               value={mp.xgot}
                                               faIcon={{icon: faFutbol, color: "#ffcc00", fixedWidth: true}}
                                               tooltip="Expected Goals Post Shot"
                                               maxDp={2}/>
                        <ColorScaleProgressBar name="xA"
                                               min={0}
                                               max={2}
                                               value={mp.xa}
                                               icon={<FootballEvent id={0} related fixedWidth/>}
                                               tooltip="Expected Assists"
                                               maxDp={2}/>
                        <ColorScaleProgressBar name="xTeam Goals"
                                               min={0}
                                               max={2}
                                               value={mp.xtg}
                                               faIcon={{icon: faFutbol, color: "#1eff00", fixedWidth: true}}
                                               tooltip="Team Expected Goals while on Pitch"
                                               maxDp={2}/>
                        <ColorScaleProgressBar name="xGoals Conceded"
                                               min={0}
                                               max={2}
                                               value={mp.xog}
                                               faIcon={{icon: faFutbol, color: "#ff0000", fixedWidth: true}}
                                               tooltip="Opponent Expected Goals while on Pitch"
                                               maxDp={2}/>
                        <ColorScaleProgressBar name="Goals - xG"
                                               min={0}
                                               max={2}
                                               value={mp.xgsa}
                                               faIcon={{icon: faPlus, color: "#ffcc00", fixedWidth: true}}
                                               tooltip="Expected Goals Shots Added"
                                               maxDp={2}/>
                        {mp.pos === 0 ? <ColorScaleProgressBar name="Conceded - xG"
                                                               min={0}
                                                               max={2}
                                                               value={mp.xgst}
                                                               icon={<FootballEvent id={0} fixedWidth/>}
                                                               tooltip="Expected Goals Shots Stopped"
                                                               maxDp={2}/> : <></>}
                    </ListGroup>
                </Col>
            </Row>
        </Card>;
    }

    if (activePlayer !== null) {
        let mp;
        let home;
        let homePlayer = _.filter(homePlayers, p => p[PLAYER][ID] === activePlayer);
        let awayPlayer = _.filter(awayPlayers, p => p[PLAYER][ID] === activePlayer);
        if (homePlayer.length === 1) {
            mp = homePlayer[0];
            home = true;
        } else if (awayPlayer.length === 1) {
            mp = awayPlayer[0];
            home = false;
        } else {
            mp = null;
        }
        if (mp != null) {
            pitchOverlays.push(createPlayerDetails(mp, home, vertical));
        }
    }

    function extractPlayerDetails(players, events, home, sub) {
        if (players.length === 0) {
            return [];
        }
        let sortedEvents = _.sortBy(events, [e => e[PERIOD]], [e => e[SECONDS]]);
        let lineups = _.orderBy(_.filter(players, p => p.sub === (sub ? 1 : 0)), [p => p.y, p => getPosition(p.pos).r, 'no'], ["desc", "asc", "asc"]);
        let playersByZone;
        if (sub) {
            playersByZone = {'bench': _.filter(lineups, p => _.filter(sortedEvents, e => isEventForPlayer(e, p[PLAYER][ID])).length > 0)};
        } else {
            playersByZone = _.groupBy(lineups, p => getPosition(p.pos).z);
        }
        let playerIconDetails = [];
        let zoneForward = [0.05, 0.18, 0.31, 0.44];
        _.forEach(playersByZone, (ps, zone) => {
            let width = sub ? 0.9 : Math.min(1, 0.4 + ps.length * 0.12);
            let forward = sub ? 0.5 : (zoneForward[zone] || 0.25);
            let midIndex = (ps.length - 1) / 2.0;
            let widthPerItem = width / ps.length;
            let minX = _.min(_.map(ps, p => p.x || getPosition(p.pos).f * 100));
            let maxX = _.max(_.map(ps, p => p.x || getPosition(p.pos).f * 100));
            _.forEach(ps, (p, i) => {
                let pos = getPosition(p.pos);
                let y = 0.5 + widthPerItem * (i - midIndex);
                let xDelta = p.x || getPosition(p.pos).f * 100;
                let x = (maxX === minX || sub) ? forward : (forward + 0.02 * Math.round((xDelta - minX) / (maxX - minX)) - 0.01);
                playerIconDetails.push({
                    ...p,
                    [ID]: p[PLAYER][ID],
                    [NAME]: p[PLAYER][NAME],
                    pos: pos,
                    x: x,
                    y: y,
                    [HOME]: home,
                    [EVENT]: _.filter(sortedEvents, e => isEventForPlayer(e, p[PLAYER][ID]))
                })
            })
        })
        return playerIconDetails;
    }

    function verticalPitchReport(pitchOverlays, homeSubOverlays, awaySubOverlays, width) {
        return <div style={{width: width}} className={"mx-auto"}>
            <div style={{
                backgroundColor: '#00a400',
                position: 'relative',
                borderStyle: 'dashed',
                borderColor: '#ffffff',
                borderWidth: 'thin',
                width: width,
                height: 120,
            }}>
                {homeSubOverlays}
            </div>
            <div>
                <Pitch width={width} vertical={true}>
                    {pitchOverlays}
                </Pitch>
            </div>
            <div className="flex-grow-1" style={{
                backgroundColor: '#00a400',
                position: 'relative',
                borderStyle: 'dashed',
                borderColor: '#ffffff',
                borderWidth: 'thin',
                width: width,
                height: 120,
            }}>
                {awaySubOverlays}
            </div>
        </div>;
    }

    function horizontalPitchReport(pitchOverlays, homeSubOverlays, awaySubOverlays, width) {
        return <div className="d-flex justify-content-center">
            <div className="flex-grow-1" style={{
                backgroundColor: '#00a400',
                position: 'relative',
                borderStyle: 'dashed',
                borderColor: '#ffffff',
                borderWidth: 'thin',
                marginRight: '10px'
            }}>
                {homeSubOverlays}
            </div>
            <div>
                <Pitch className="justify-content-center" width={width}>
                    {pitchOverlays}
                </Pitch>
            </div>
            <div className="flex-grow-1" style={{
                backgroundColor: '#00a400',
                position: 'relative',
                borderStyle: 'dashed',
                borderColor: '#ffffff',
                borderWidth: 'thin',
                marginLeft: '10px'
            }}>
                {awaySubOverlays}
            </div>
        </div>;
    }

    return <div ref={ref} style={{width: '100%'}}>
        {vertical ? verticalPitchReport(pitchOverlays, homeSubOverlays, awaySubOverlays, pitchWidth) : horizontalPitchReport(pitchOverlays, homeSubOverlays, awaySubOverlays, pitchWidth)}
    </div>
}

function MatchReport() {
    const {id} = useParams()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [report, setReport] = useState([]);

    useEffect(() => {
        fetch(settings.apiServer + "matchreport/" + id + ".json")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setReport(result);
                    document.title = result[MATCH][HOME][NAME] + ' vs ' + result[MATCH][AWAY][NAME] + ' ' + dayjs(result[MATCH][START]).format('YYYY-MM-DD') + ' Match Report - Football Ratings';
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <Error500/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        let events = _.get(report[MATCH][SCORE], EVENT, []);
        let homeMatchRating = _.round(_.get(report[MATCH][SCORE], RATING, 5), 1);
        return (
            <>
                <Row>
                    <Col md={5} sm={12}>
                        <div className="d-flex flex-row">
                            <div className="me-2 px-auto position-relative">
                                <RatingPill rt={homeMatchRating} left/>
                                <TeamLogoLink {...report[MATCH][HOME]} size={80}/>
                            </div>
                            <div className="me-2 px-auto">
                                <div>
                                    <RatingBadge rt={_.get(report[MATCH][HOME][STATS], RATING, 0)}
                                                 title="Home Team Rating" dp={1} size={5} maxScale={100}/>
                                </div>
                                <div>
                                    <Badge className="em5 px-0 my-auto text-center" bg="secondary"
                                           title="Home Team Rank">{formatRank(_.get(report[MATCH][HOME][STATS], RANK))}</Badge>
                                </div>
                                <div>
                                    <Flag id={report[MATCH][HOME][COUNTRY]} size="lg"/>
                                    <Badge style={{borderRadius: 0, width: 32, height: 20}} className="text-center"
                                           bg="info"
                                           title="Home Team Rank in Country">{formatRank(_.get(report[MATCH][HOME][STATS], RANK_COUNTRY))}</Badge>
                                </div>
                            </div>
                            <div className="text-center flex-grow-1">
                                <h4>
                                    <TeamLink {...report[MATCH][HOME]}/>
                                    <Gender gender={report[MATCH][HOME][GENDER]} hiddenValue={0} fixedWidth/>
                                </h4>
                            </div>
                            <div className="d-block d-md-none">
                                <VerticalScorePartial match={report[MATCH]} home={true} showAggregateInBrackets={true}/>
                            </div>
                        </div>
                    </Col>
                    <Col md={2} xs={2} className="text-center d-none d-md-block">
                        <div className="w-100">
                            <div style={{width: 100}} className="text-center mx-auto">
                                <ScoreElement match={report[MATCH]}/>
                            </div>
                        </div>
                        {_.get(report[MATCH][SCORE], 'ha') != null ?
                            <div className="w-100">
                                <div style={{width: 80}} className="text-center mx-auto">
                                    <ScoreElement match={report[MATCH]} aggregate showTime={false}/>
                                </div>
                            </div> : <></>}
                    </Col>
                    <Col md={5} sm={12}>
                        <div className="d-none d-md-flex flex-row-reverse">
                            <div className="me-2 px-auto position-relative">
                                <RatingPill rt={10 - homeMatchRating}/>
                                <TeamLogoLink {...report[MATCH][AWAY]} size={80}/>
                            </div>
                            <div className="me-2 px-auto">
                                <div>
                                    <RatingBadge rt={_.get(report[MATCH][AWAY][STATS], RATING, 0)}
                                                 title="Away Team Rating" dp={1} size={5} maxScale={100}/>
                                </div>
                                <div>
                                    <Badge className="em5 px-0 my-auto text-center" bg="secondary"
                                           title="Away Team Rank">{formatRank(_.get(report[MATCH][AWAY][STATS], RANK))}</Badge>
                                </div>
                                <div>
                                    <Flag id={report[MATCH][AWAY][COUNTRY]} size="lg"/>
                                    <Badge style={{borderRadius: 0, width: 32, height: 20}} className="px-0 text-center"
                                           bg="info"
                                           title="Away Team Rank in Country">{formatRank(_.get(report[MATCH][AWAY][STATS], RANK_COUNTRY))}</Badge>
                                </div>
                            </div>
                            <div className="text-center flex-grow-1">
                                <h4>
                                    <TeamLink {...report[MATCH][AWAY]}/>
                                    <Gender gender={report[MATCH][AWAY][GENDER]} hiddenValue={0} fixedWidth/>
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex d-md-none flex-row">
                            <div className="me-2 px-auto position-relative">
                                <RatingPill rt={10 - homeMatchRating} left/>
                                <TeamLogoLink {...report[MATCH][AWAY]} size={80}/>
                            </div>
                            <div className="me-2 px-auto">
                                <div>
                                    <RatingBadge rt={_.get(report[MATCH][AWAY][STATS], RATING, 0)}
                                                 title="Away Team Rating" dp={1} size={5} maxScale={100}/>
                                </div>
                                <div>
                                    <Badge className="em5 px-0 my-auto text-center" bg="secondary"
                                           title="Away Team Rank">{formatRank(_.get(report[MATCH][AWAY][STATS], RANK))}</Badge>
                                </div>
                                <div>
                                    <Flag id={report[MATCH][AWAY][COUNTRY]} size="lg"/>
                                    <Badge style={{borderRadius: 0, width: 32, height: 20}} className="px-0 text-center"
                                           bg="info"
                                           title="Away Team Rank in Country">{formatRank(_.get(report[MATCH][AWAY][STATS], RANK_COUNTRY))}</Badge>
                                </div>
                            </div>
                            <div className="text-center flex-grow-1">
                                <h4>
                                    <Link
                                        to={teamProfileUrl(report[MATCH][AWAY][ID])}>{report[MATCH][AWAY][NAME]}</Link>
                                    <Gender gender={report[MATCH][AWAY][GENDER]} hiddenValue={0} fixedWidth/>
                                </h4>
                            </div>
                            <div>
                                <VerticalScorePartial match={report[MATCH]} home={false}
                                                      showAggregateInBrackets={true}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} sm={1} md={2}>
                    </Col>
                    <Col xs={12} sm={10} md={8}>
                        <MatchTimeline events={events}/>
                    </Col>
                    <Col xs={0} sm={1} md={2}>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <FontAwesomeIcon className="fs-1" icon={faCalendar} fixedWidth/>
                        <span>
                        {dayjs(report.m.st).format('D MMM YYYY HH:mm')}
                    </span>
                    </Col>
                    <Col className="text-center">
                        <Link
                            to={"/competition/" + report[MATCH][ROUND][SEASON][COMPETITION][ID] + "/" + report[MATCH][ROUND][SEASON][ID]}>
                            <CompetitionBadge {...report[MATCH][ROUND][SEASON][COMPETITION]}/>
                            <span className="ms-1">
                                {report[MATCH][ROUND][SEASON][COMPETITION][NAME]} ({report[MATCH][ROUND][TYPE][NAME]})
                            </span>
                        </Link>
                    </Col>
                    <Col className="text-center">
                        {report[MATCH][VENUE] != null ?
                            <>
                                <FontAwesomeIcon className="fs-1" icon={faMapPin} fixedWidth/>
                                <span>
                        {report[MATCH][VENUE][NAME]} <Flag id={report[MATCH][VENUE][COUNTRY]} size="1x"/>
                        </span></> : <></>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PitchReport report={report}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mt-3">
                        <MatchHighlights horizontal teamIds={[report[MATCH][HOME][ID], report[MATCH][AWAY][ID]]}
                                         matches={report[MATCH][SCORE] != null ? [report[MATCH]] : []}
                                         maxDisplay={250}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Lineup team={report[MATCH][HOME]} players={report[HOME]} events={events}/>
                    </Col>
                    <Col md={6}>
                        <Lineup team={report[MATCH][AWAY]} players={report[AWAY]} events={events}/>
                    </Col>
                </Row>
            </>
        )
    }
}

export default MatchReport;