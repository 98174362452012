import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import {Icon, Point} from 'leaflet'
import React from 'react';
import _ from 'lodash';
import {Flag} from "./flag";
import {COUNTRY, NAME} from "./alias";

export const Map = ({icons}) => {
    let positionedIcons = _.filter(icons, i => i.lat != null && i.lon != null && (i.lon !== 0 || i.lat !== 0));
    let lats = _.map(positionedIcons, i => i.lat);
    let lons = _.map(positionedIcons, i => i.lon);
    let bounds = [
        [_.min(lats), _.min(lons)],
        [_.max(lats), _.max(lons)]];
    let markers = _.map(icons, (icon, i) => {
        return <Marker key={i} position={[icon.lat, icon.lon]} icon={new Icon({
            iconUrl: icon.url,
            iconRetinaUrl: icon.url,
            iconSize: new Point(32, 32)
        })}>
            <Popup>
                <Flag id={icon[COUNTRY]}/><span className="ms-1 text-dark">{icon[NAME]}</span>
            </Popup>
        </Marker>
    });
    return <MapContainer className={"vh-75 w-100"} bounds={bounds} scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers}
    </MapContainer>;
}