import {getPosition, positions} from "../../data/lookup";
import {Badge} from "react-bootstrap";
import _ from "lodash";
import React from 'react';
import {CODE, NAME, ZONE} from "./alias";

const colors = [
    '#0d5cce',
    '#00a93b',
    '#a96e00',
    '#b00606']

export const zones = ['G', 'D', 'M', 'F'];
export const zonesMedium = ['GKP', 'DEF', 'MID', 'FWD'];
export const zoneNames = ['Goalkeeper', 'Defender', 'Midfielder', 'Forward'];

export function getZone(id) {
    return _.get(positions[id], ZONE, 0);
}

export function getZoneColor(id) {
    return colors[id];
}

export const PositionZone = ({id, style, medium}) => {
    return <Badge bg="custom" className={medium ? "em3" : "em1"} style={{backgroundColor: colors[id], ...style}}
                  title={zoneNames[id]}>{medium ? zonesMedium[id] : zones[id]}</Badge>;
}

export const Position = ({id, style, zone = 0}) => {
    let position = getPosition(id);
    if (zone) {
        return <PositionZone id={position[ZONE]} style={style} medium={zone === 2}/>
    }
    return <Badge bg="custom" className="em3" style={{backgroundColor: colors[position[ZONE]], ...style}}
                  title={position[NAME]}>{position[CODE]}</Badge>;
}

export const ShirtNumber = ({number}) => {
    return <Badge bg="light" text="dark" className="em3" title="Shirt Number">{number}</Badge>;
}