import React from 'react';
import {FootballEvent, formatMatchTime} from "./icons";
import _ from "lodash";
import {ID, PLAYER, RELATED} from "./alias";

export const periodEnds = [0, 45, 90, 105, 120, 120, 120];
export const periodMatchEnds = [0, 5400, 5400, 7200, 7200, 7200, 7200];

function isRelated(e, player) {
    return e[RELATED] === player || _.get(e[RELATED], ID) === player;
}

export function isEventForPlayer(e, player) {
    return (e[PLAYER] === player || _.get(e[PLAYER], ID) === player) || (e.t !== 5 && e.t !== 4 && isRelated(e, player));
}

export const MatchTimeline = ({events, player, startEvent, endEvent}) => {
    let ends = [...periodEnds];
    let plotEvents = _.filter(events, e => (player != null || e.t !== 5) && e.p >= 1 && e.p <= 4);
    var maxP = 2;
    _.forEach(plotEvents, e => {
        ends[e.p] = Math.max(_.get(ends, e.p, 0), _.round(e.s / 60));
        maxP = Math.max(e.p, maxP);
    })

    function calculateXPos(e) {
        let periodEnd = ends[e.p];
        let periodStart = periodEnds[e.p - 1];
        let fractionInPeriod = Math.max(0, Math.min((_.round(e.s / 60) - periodStart) / (periodEnd - periodStart), 1));
        let periodFractionStart = periodEnds[e.p - 1] / periodEnds[maxP];
        let periodFractionEnd = periodEnds[e.p] / periodEnds[maxP];
        let xFraction = periodFractionStart + (periodFractionEnd - periodFractionStart) * fractionInPeriod;
        return _.round(xFraction * 100, 6);
    }

    let icons = _.map(plotEvents, (e, i) => {
        let xPos = calculateXPos(e);
        return [
            <div key={i} style={{
                top: e.h ? -22 : 10,
                left: xPos + '%',
                marginLeft: -10,
                position: 'absolute',
                height: 20,
                width: 20
            }} className="text-center">
                <FootballEvent
                    id={e.t}
                    player={e.pl}
                    rel={e.rl}
                    related={player != null && isRelated(e, player)}
                    className={(player != null && !isEventForPlayer(e, player)) ? "opacity-25" : "opacity-100 z-index-2"}
                    p={e.p}
                    secs={e.s}/>
            </div>,
            <div key={"time-" + i} style={{
                top: 0,
                left: xPos + '%',
                fontSize: 8,
                marginLeft: -10,
                position: 'absolute',
                height: 20,
                width: 20
            }} className="text-center">
                <span>{formatMatchTime(e.p, e.s)}</span>
            </div>
        ]
    })
    for (let i = 1; i < maxP; i++) {
        let xFrac = 100 * periodEnds[i] / periodEnds[maxP];
        icons.push(<div key={"p-" + i} className="bg-dark"
                        style={{width: 1, height: 10, position: 'absolute', left: xFrac + '%'}}/>);
    }
    if (startEvent != null) {
        let xStart = calculateXPos(startEvent);
        let xEnd = endEvent == null ? 100 : calculateXPos(endEvent);
        icons.push(<div key={"active"} className="bg-success bg-opacity-50"
                        style={{width: (xEnd - xStart) + "%", height: 10, position: 'absolute', left: xStart + '%'}}/>);
    }
    return <div className="mx-auto" style={{height: 60, width: '100%'}}>
        <div className="bg-light" style={{
            height: 10,
            marginTop: 25,
            position: 'relative',
        }}>
            {icons}
        </div>
    </div>
};