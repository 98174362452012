import {Card, Col, Dropdown, ListGroup, Row} from "react-bootstrap";
import Matches from "./matches";
import React, {Suspense, useState} from "react";
import {FixedSizeList as List} from "react-window";
import {Gender, GenderToggle, NationalToggle} from "./icons";
import _ from "lodash";
import {TeamRatingTable} from "./team";
import {DefaultDropdown, PlayerList} from "./player";
import {Flag} from "./flag";
import {settings} from "../../config";
import {COUNTRY, GENDER, ID, NAME, NATIONAL, RATING} from "./alias";
import {Link} from "react-router-dom";
import {useWindowContext} from "../../hooks/useWindowContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartSimple,
    faCity,
    faCog,
    faFlag,
    faGlobeAmericas,
    faSortAmountUpAlt,
    faTrophy
} from "@fortawesome/free-solid-svg-icons";
import {preventDefault} from "@fullcalendar/react";
import {breakpoints} from "../../helpers/utils";
import {countries} from "../../data/lookup";
import {RatingBadge100} from "./matchreport";
import fetchData from "../../helpers/fetchData";
import {Helmet} from "react-helmet";

const playerRanks = fetchData(settings.apiServer + "rank/player.json");
const teamRanks = fetchData(settings.apiServer + "rank/team.json");
const competitionRanks = fetchData(settings.apiServer + "rank/competition.json");

const TeamRatings = () => {
    return <TeamRatingsRender teams={teamRanks.read()}/>
}

const TeamRatingsRender = ({teams}) => {
    let {clientWidth} = useWindowContext();
    let title = <Link to="/teamratings" className={"btn btn-primary btn-sm"}>
        <FontAwesomeIcon icon={faChartSimple}/> Team Ratings
    </Link>
    return <TeamRatingTable teams={teams} title={title} display={clientWidth < breakpoints.md ? 5 : 10}/>
}

const Competitions = () => {
    return <CompetitionsRender competitions={competitionRanks.read()}/>
}

const CompetitionsRender = ({competitions}) => {
    const [gender, setGender] = useState(0);
    const [country, setCountry] = useState(null);
    const [national, setNational] = useState(0);
    const [sort, setSort] = useState('rating');

    let {clientWidth} = useWindowContext();

    let items = _.filter(competitions, c => c[GENDER] === gender
        && (country == null || country === c[COUNTRY])
        && c[NATIONAL] === national
    );
    let sortedItems;
    if (sort === 'rating') {
        sortedItems = items;
    } else if (sort === 'country') {
        sortedItems = _.orderBy(items, i => _.get(countries[i[COUNTRY]], NAME), 'asc');
    } else if (sort === 'league') {
        sortedItems = _.orderBy(items, i => i.p, 'desc');
    }

    const RenderedItem = ({index, style}) => {
        let c = sortedItems[index];
        return <ListGroup.Item action as={Link} to={"/competition/" + c[ID]} key={c[ID]} style={style}>
            <div className="d-flex flex-row">
                <div style={{position: 'absolute', left: 3, top: 3}} onClick={(e) => {
                    setCountry(country === c[COUNTRY] ? null : c[COUNTRY]);
                    preventDefault(e);
                }}>
                    <Flag id={c[COUNTRY]} size="2x"/>
                </div>
                <div className="flex-grow-1 ms-4">
                    <span className="ms-2">{c[NAME]}</span>
                </div>
                <div>
                    <RatingBadge100 rt={c[RATING]} title="Competition Rating" dp={1}/>
                </div>
            </div>
        </ListGroup.Item>
    };
    let itemSize = 41;
    let height = (clientWidth < breakpoints.md ? 5 : 10) * itemSize - 1;
    return <Card className={"mb-3"}>
        <Card.Header className="p-2 bg-light text-dark">
            <span className="float-end">
            <NationalToggle selected={national} onChange={setNational} id="competition"/>
                <span className="mx-1"/>
                <GenderToggle selected={gender} onChange={setGender} id="competition"/>
                <span className="ms-1"/>
                <DefaultDropdown id="competition-settings-dropdown" content={<FontAwesomeIcon icon={faCog}/>}>
                    <Dropdown.Header>Sort</Dropdown.Header>
                    <Dropdown.Item onClick={() => setSort('rating')} active={sort === 'rating'}>
                        <FontAwesomeIcon icon={faSortAmountUpAlt} fixedWidth/>
                        <span className="text-dark ms-2">Rating</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSort('country')} active={sort === 'country'}>
                        <FontAwesomeIcon icon={faFlag} fixedWidth/>
                        <span className="text-dark ms-2">Country</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSort('league')} active={sort === 'league'}>
                        <FontAwesomeIcon icon={faTrophy} fixedWidth/>
                        <span className="text-dark ms-2">League</span>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Header>Gender</Dropdown.Header>
                        <Dropdown.Item onClick={() => setGender(0)} active={gender === 0}>
                            <Gender gender={0} fixedWidth/>
                            <span className="text-dark ms-2">Male</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setGender(1)} active={gender === 1}>
                            <Gender gender={1} fixedWidth/>
                            <span className="text-dark ms-2">Female</span>
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                    <Dropdown.Header>International</Dropdown.Header>
                        <Dropdown.Item onClick={() => setNational(0)} active={national === 0}>
                            <FontAwesomeIcon icon={faCity} fixedWidth color={"#148f00"}/>
                            <span className="text-dark ms-2">Club</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setNational(1)} active={national === 1}>
                            <FontAwesomeIcon icon={faGlobeAmericas} fixedWidth className={"text-info"}/>
                            <span className="text-dark ms-2">National</span>
                        </Dropdown.Item>
                </DefaultDropdown>
            </span>
            <Card.Title>Competitions</Card.Title>
        </Card.Header>
        <ListGroup className="list-group-flush" style={{height}}>
            <List
                height={height}
                itemCount={items.length}
                itemSize={itemSize}>
                {RenderedItem}
            </List>
        </ListGroup>
    </Card>
}

const PlayerRatings = () => {
    const players = playerRanks.read();
    return <PlayerRatingsRender players={players}/>
}

const PlayerRatingsRender = ({players}) => {
    const {clientWidth} = useWindowContext();
    let title = <Link to="/playerratings" className={"btn btn-primary btn-sm"}>
        <FontAwesomeIcon icon={faChartSimple}/> Player Ratings
    </Link>;

    return <PlayerList players={players} title={title} size={clientWidth < breakpoints.md ? 2 : 3} genderToggle={true}/>
}

const Home = () => {
    return (
        <Row>
            <Col xl={3} lg={4}>
                <Helmet>
                    <title>Football Ratings</title>
                </Helmet>
                <Suspense fallback={<PlayerRatingsRender/>}>
                    <PlayerRatings/>
                </Suspense>
                <Suspense fallback={<TeamRatingsRender/>}>
                    <TeamRatings/>
                </Suspense>
                <Suspense fallback={<CompetitionsRender/>}>
                    <Competitions/>
                </Suspense>
            </Col>
            <Col xl={9} lg={8}>
                <Matches/>
            </Col>
        </Row>
    );
};
export default Home;