import CookieConsent, {OPTIONS} from "react-cookie-consent";
import {Link} from "react-router-dom";
import React from "react";
import {useCookieConsent} from "../../hooks/useCookieConsent";
import {isPrerender} from "../../helpers/utils";

export const CookieBanner = () => {
    const {cookieConsent, setCookieConsent} = useCookieConsent();
    if (cookieConsent == null || isPrerender()) {
        return null;
    } else {
        return <CookieConsent buttonText="Accept"
                              location={OPTIONS.BOTTOM}
                              disableStyles
                              style={{position: "fixed", width: '100%', zIndex: 1000}}
                              containerClasses="alert alert-dark col-lg-12 bg-100 m-0"
                              enableDeclineButton
                              declineButtonClasses="btn btn-dark btn-sm"
                              declineButtonText="Decline"
                              flipButtons
                              onAccept={() => setCookieConsent(true)}
                              onDecline={() => setCookieConsent(false)}
                              buttonWrapperClasses="d-inline-block btn-group btn-group-sm float-end"
                              contentClasses="d-inline-block"
                              buttonClasses="btn btn-success btn-sm">
            <Link to={"/"}>FootballRatings.com</Link> uses cookies. <Link to="/cookiepolicy">Cookie
            policy</Link>
        </CookieConsent>
    }
}