import {round} from "lodash";
import React from 'react';

export const Pitch = ({width, children, vertical = false}) => {
    let height = vertical ? Math.max(round(width * 1070 / 700), 800) : round(width * 700 / 1070);
    let pitch = vertical ?
        <svg height={height} width={width} preserveAspectRatio="none" viewBox="0 0 70 107">
            <rect height="107" width="70" fill="#00a400"/>
            <rect x="1" y="1" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="11.5" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="22" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="32.5" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="43" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="53.5" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="64" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="74.5" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="85" height="5.25" width="68" fill="#00b000"/>
            <rect x="1" y="95.5" height="5.25" width="68" fill="#00b000"/>
            <g fill="none" stroke="#fff" strokeWidth="0.1" transform="translate(1 1)">
                <path d="M 0 0 v 105 h 68 v -105 Z"/>
                <path d="M 0 52.5 h 68"/>
                <circle r="9.15" cy="52.5" cx="34"/>
                <circle r="0.25" cy="52.5" cx="34" fill="#99ff99" stroke="none"/>
                <g>
                    <path d="M 13.84 0 v 16.5 h 40.32 v -16.5"/>
                    <path d="M 24.84 0 v 5.5 h 18.32 v -5.5"/>
                    <circle r="0.25" cy="10.94" cx="34" fill="#99ff99" stroke="none"/>
                    <path d="M 26.733027 16.5 a 9.15 9.15 0 0 0 14.533946 0"/>
                </g>
                <use transform="rotate(180,34,52.5)"/>
                <path
                    d="M 0 2 a 2 2 0 0 0 2 -2M 66 0 a 2 2 0 0 0 2 2M 68 103 a 2 2 0 0 0 -2 2M 2 105 a 2 2 0 0 0 -2 -2"/>
            </g>
        </svg> :
        <svg height={height} width={width} preserveAspectRatio="none" viewBox="0 0 107 70">
            <rect width="107" height="70" fill="#00a400"/>
            <rect x="1" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="11.5" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="22" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="32.5" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="43" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="53.5" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="64" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="74.5" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="85" y="1" width="5.25" height="68" fill="#00b000"/>
            <rect x="95.5" y="1" width="5.25" height="68" fill="#00b000"/>
            <g fill="none" stroke="#fff" strokeWidth="0.1" transform="translate(1 1)">
                <path d="M 0 0 h 105 v 68 h -105 Z"/>
                <path d="M 52.5 0 v 68"/>
                <circle r="9.15" cx="52.5" cy="34"/>
                <circle r="0.25" cx="52.5" cy="34" fill="#99ff99" stroke="none"/>
                <g>
                    <path d="M 0 13.84 h 16.5 v 40.32 h -16.5"/>
                    <path d="M 0 24.84 h 5.5 v 18.32 h -5.5"/>
                    <circle r="0.25" cx="10.94" cy="34" fill="#99ff99" stroke="none"/>
                    <path d="M 16.5 41.266973 a 9.15 9.15 0 0 0 0 -14.533946"/>
                </g>
                <use transform="rotate(180,52.5,34)"/>
                <path id="Corner arcs"
                      d="M 0 2 a 2 2 0 0 0 2 -2 M 103 0 a 2 2 0 0 0 2 2 M 105 66 a 2 2 0 0 0 -2 2 M 2 68 a 2 2 0 0 0 -2 -2"/>
            </g>
        </svg>

    return <div style={{
        width: width,
        height: height,
        position: 'relative'
    }}>
        {pitch}
        {children}
    </div>
};

export const rotate90 = (name) => {
    if (name === 'top') {
        return 'right';
    } else if (name === 'right') {
        return 'bottom';
    } else if (name === 'bottom') {
        return 'left';
    } else if (name === 'left') {
        return 'top';
    } else {
        return name;
    }
}

export const PitchIcon = ({height, width = 120, x, y, home, children, vertical = false, ...rest}) => {
    let halfH = round(height * 0.5);
    let halfW = round(width * 0.5);
    let yPercent = round(y * 100, 6);
    let xPercent = round(x * 100, 6);
    let yName = home ? 'top' : 'bottom';
    let xName = home ? 'left' : 'right';
    let marginNameH = home ? 'marginTop' : 'marginBottom';
    let marginNameW = (vertical ^ home) ? 'marginLeft' : 'marginRight';
    if (vertical) {
        yName = rotate90(yName);
        xName = rotate90(xName);
    }
    return <div style={{
        cursor: 'pointer',
        [yName]: yPercent + '%',
        [xName]: xPercent + '%',
        [marginNameH]: -halfH,
        [marginNameW]: -halfW,
        position: 'absolute',
        height: height,
        width: width
    }} className="text-center" {...rest}>
        {children}
    </div>
}