export const version = '4.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'md';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  navbarPosition: 'vertical',
  showBurgerMenu: false,
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  apiServer: 'https://api.footballratings.com/',
  imgServer: 'https://img.footballratings.com/'
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
