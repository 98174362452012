import {
    faCity,
    faClock,
    faFemale,
    faFilm,
    faFlag,
    faFutbol,
    faGlobeAmericas,
    faHandsHelping,
    faMale,
    faMountain,
    faPeopleArrows,
    faSquare,
    faTable,
    faTimeline,
    faTimesCircle,
    faUsersViewfinder
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import _ from 'lodash';
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {PositionZone} from "./position";

export const Gender = ({gender, hiddenValue = -1, ...props}) => {
    if (gender === hiddenValue) {
        return <></>
    }
    return <FontAwesomeIcon color={gender === 1 ? '#ff00f2' : '#1672fc'}
                            title={gender === 1 ? 'Female' : 'Male'}
                            alt={gender === 1 ? 'Female' : 'Male'}
                            icon={gender === 1 ? faFemale : faMale} {...props}/>
}

export const GenderToggle = ({selected, onChange, id, both = false}) => {
    return <ToggleButtonGroup size="sm"
                              className="m-0"
                              type="radio"
                              name={"gender-toggle-" + id}
                              onChange={onChange}
                              value={selected}>
        {both ?
            <ToggleButton name={"gender-toggle-both" + id} id={"gender-toggle-both" + id} value={2}
                          className="m-0 py-0 px-2"
                          variant="outline-light">
                Gender
            </ToggleButton> : <></>}
        <ToggleButton name={"gender-toggle-male" + id} id={"gender-toggle-male" + id} value={0}
                      className="m-0 py-0 px-2"
                      variant="outline-light">
            <Gender gender={0} fixedWidth/>
        </ToggleButton>
        <ToggleButton name={"gender-toggle-female" + id} id={"gender-toggle-female" + id} value={1}
                      className="m-0 py-0 px-2"
                      variant="outline-light">
            <Gender gender={1} fixedWidth/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

export const TableToggle = ({selected, onChange, id}) => {
    return <ToggleButtonGroup size="sm"
                              className="m-0"
                              type="radio"
                              name={"table-toggle-" + id}
                              onChange={onChange}
                              value={selected}>
        <ToggleButton name={"table-toggle-picture" + id} id={"table-toggle-picture" + id} value={0}
                      className="m-0 py-0 px-2"
                      title="Display images"
                      alt="Display images"
                      variant="outline-light">
            <FontAwesomeIcon icon={faUsersViewfinder} fixedWidth/>
        </ToggleButton>
        <ToggleButton name={"table-toggle-table" + id} id={"table-toggle-table" + id} value={1}
                      className="m-0 py-0 px-2"
                      title="Display as table"
                      alt="Display as table"
                      variant="outline-light">
            <FontAwesomeIcon icon={faTable} fixedWidth/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

export const PeakToggle = ({selected, onChange, id}) => {
    return <ToggleButtonGroup size="sm"
                              className="m-0"
                              type="radio"
                              name={"peak-toggle-" + id}
                              onChange={onChange}
                              value={selected}>
        <ToggleButton name={"peak-toggle-current" + id} id={"peak-toggle-current" + id} value={0}
                      className="m-0 py-0 px-2"
                      title="Show current"
                      alt="Show current"
                      variant="outline-light">
            <FontAwesomeIcon icon={faClock} fixedWidth/>
        </ToggleButton>
        <ToggleButton name={"peak-toggle-peak" + id} id={"peak-toggle-peak" + id} value={1}
                      className="m-0 py-0 px-2"
                      title="Show Peak"
                      alt="Show Peak"
                      variant="outline-light">
            <FontAwesomeIcon icon={faMountain} fixedWidth/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

export const VideoToggle = ({selected, onChange, id, disableVideo}) => {
    return <ToggleButtonGroup size="sm"
                              className="m-0"
                              type="radio"
                              name={"video-toggle-" + id}
                              onChange={onChange}
                              value={selected}>
        <ToggleButton name={"video-toggle-timeline" + id} id={"video-toggle-timeline" + id} value={0}
                      className="m-0 py-0 px-2"
                      title="Display timeline"
                      alt="Display timeline"
                      variant="outline-light">
            <FontAwesomeIcon icon={faTimeline} fixedWidth/>
        </ToggleButton>
        <ToggleButton name={"video-toggle-video" + id} id={"video-toggle-video" + id} value={1}
                      className="m-0 py-0 px-2"
                      title="Display as video"
                      alt="Display as video"
                      disabled={disableVideo}
                      variant="outline-light">
            <FontAwesomeIcon icon={faFilm} fixedWidth/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

export const PositionToggle = ({selected, onChange, id, all = false}) => {
    return <ToggleButtonGroup size="sm"
                              className="m-0"
                              type="radio"
                              name={"position-toggle-" + id}
                              onChange={onChange}
                              value={selected}>
        {all ?
            <ToggleButton name={"position-toggle-all" + id} id={"position-toggle-all" + id} value={4}
                          className="m-0 py-0 px-1"
                          variant="outline-light">
                All
            </ToggleButton> : <></>}
        <ToggleButton name={"position-toggle-0" + id} id={"position-toggle-0" + id} value={0}
                      className="m-0 py-0 px-1"
                      variant="outline-light">
            <PositionZone id={0} medium/>
        </ToggleButton>
        <ToggleButton name={"position-toggle-1" + id} id={"position-toggle-1" + id} value={1}
                      className="m-0 py-0 px-1"
                      variant="outline-light">
            <PositionZone id={1} medium/>
        </ToggleButton>
        <ToggleButton name={"position-toggle-2" + id} id={"position-toggle-2" + id} value={2}
                      className="m-0 py-0 px-1"
                      variant="outline-light">
            <PositionZone id={2} medium/>
        </ToggleButton>
        <ToggleButton name={"position-toggle-3" + id} id={"position-toggle-3" + id} value={3}
                      className="m-0 py-0 px-1"
                      variant="outline-light">
            <PositionZone id={3} medium/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

export const NationalToggle = ({selected, onChange, id, both = false}) => {
    return <ToggleButtonGroup size="sm"
                              className="m-0"
                              type="radio"
                              name={"national-toggle-" + id}
                              onChange={onChange}
                              value={selected}>
        {both ?
            <ToggleButton name={"national-toggle-both" + id} id={"national-toggle-both" + id} value={2}
                          className="m-0 py-0 px-2"
                          variant="outline-light">
                National
            </ToggleButton> : <></>}
        <ToggleButton name={"national-toggle-club" + id} id={"national-toggle-club" + id} value={0}
                      className="m-0 py-0 px-2"
                      variant="outline-light">
            <FontAwesomeIcon icon={faCity} fixedWidth color={"#148f00"}/>
        </ToggleButton>
        <ToggleButton name={"national-toggle-national" + id} id={"national-toggle-national" + id} value={1}
                      className="m-0 py-0 px-2"
                      variant="outline-light">
            <FontAwesomeIcon icon={faGlobeAmericas} fixedWidth className={"text-info"}/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

export const yellowRedCard = {
    icon: [
        448,
        512,
        [],
        "",
        [
            "M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z",
            "M200 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h152z",
        ]
    ],
    iconName: "yellow-red-card",
    prefix: "fa"
};

export const formatMatchTime = (p, secs, includePeriod = false) => {
    if (p === 0) {
        return "Pre-Match";
    } else if (p === 5) {
        return "Penalties";
    }
    let mins = Math.floor(secs / 60 + 1);
    let periodLengthMins;
    if (p === 1) {
        periodLengthMins = 45;
    } else if (p === 2) {
        periodLengthMins = 90;
        if (secs === 10000) {
            return 'Full Time';
        }
    } else if (p === 3) {
        periodLengthMins = 105;
    } else if (p === 4) {
        periodLengthMins = 120;
    } else {
        periodLengthMins = 120;
    }
    let baseMins = Math.min(periodLengthMins, mins);
    let extraTimeMins = mins - baseMins;
    let formattedMins;
    if (extraTimeMins > 0) {
        formattedMins = baseMins + "'+" + extraTimeMins;
    } else {
        formattedMins = baseMins + "'";
    }
    if (includePeriod) {
        if (p === 1) {
            return '1H ' + formattedMins;
        } else if (p === 2) {
            return '2H ' + formattedMins;
        } else if (p === 3) {
            return 'ET1 ' + formattedMins;
        } else if (p === 4) {
            return 'ET2 ' + formattedMins;
        } else {
            return formattedMins;
        }
    } else {
        return formattedMins;
    }
}

export const isKeyEvent = (type) => {
    return type === 0 || type === 1 || type === 2 || type === 9 || type === 10 || type === 14;
}

export const isOwnGoal = (type) => {
    return type === 1;
}

export const penaltyColor = '#7300ff';

export const FootballEvent = ({id, p, secs, player, rel, related = false, ...props}) => {
    var tooltip = p != null ? formatMatchTime(p, secs) : '';
    let playerName = _.has(player, 'n') ? player.n : player;
    let relatedPlayerName = _.has(rel, 'n') ? rel.n : rel;
    if (playerName != null) {
        tooltip += " " + playerName;
    }
    if (rel != null && !related) {
        let joinText;
        if (id === 0 || id === 1 || id === 9) {
            joinText = " assist by ";
        } else {
            joinText = " for ";
        }
        tooltip += joinText + relatedPlayerName;
    }
    if (related && (id === 0 || id === 1 || id === 9)) {
        return <span className="text-dark" title={'Assist ' + tooltip}>
            <FontAwesomeIcon icon={faHandsHelping} {...props} />
        </span>;
    }
    if (id === 0) {
        return <span className="text-dark" title={'Goal ' + tooltip}><FontAwesomeIcon
            icon={faFutbol} {...props} /></span>;
    } else if (id === 1) {
        return <span title={'Own Goal ' + tooltip}><FontAwesomeIcon icon={faFutbol}
                                                                    color={'#FF0000'} {...props}>OG</FontAwesomeIcon></span>;
    } else if (id === 2) {
        return <span title={'Red Card ' + tooltip}><FontAwesomeIcon icon={faSquare}
                                                                    color={'#FF0000'}  {...props}/></span>;
    } else if (id === 3) {
        return <span title={'Yellow Card ' + tooltip}><FontAwesomeIcon icon={faSquare}
                                                                       color={'#FFCC00'}  {...props}/></span>;
    } else if (id === 4) {
        return <span title={'Sub On ' + tooltip}><FontAwesomeIcon icon={faPeopleArrows} color={'#00FF00'}  {...props}/></span>;
    } else if (id === 5) {
        return <span title={'Sub Off ' + tooltip}><FontAwesomeIcon icon={faPeopleArrows} color={'#FF0000'}  {...props}/></span>;
    } else if (id === 9) {
        return <span title={'Penalty Goal ' + tooltip}><FontAwesomeIcon icon={faFutbol}
                                                                        color={penaltyColor}  {...props}/></span>
    } else if (id === 10) {
        return <span title={'Penalty Miss ' + tooltip}><FontAwesomeIcon icon={faTimesCircle}
                                                                        color={'#FF0000'}  {...props}/></span>;
    } else if (id === 11) {
        return <span title={'Offside ' + tooltip}><FontAwesomeIcon icon={faFlag} color={'#FF9900'}  {...props}/></span>;
    } else if (id === 14) {
        return <span title={'Second Yellow ' + tooltip}>
            <FontAwesomeIcon style={{
                "--fa-primary-color": "#FFCC00",
                "--fa-secondary-color": "#FF0000",
                "--fa-secondary-opacity": 1.0
            }} icon={yellowRedCard}  {...props}/>
        </span>;
    } else {
        return <></>
    }
}