export function matchReportUrl(id) {
    return "/match/" + id;
}

export function teamProfileUrl(id) {
    return "/team/" + id;
}

export function playerProfileUrl(id) {
    return "/player/" + id;
}

export function competitionSeasonUrl(season) {
    return "/competition/" + season.c.i + "/" + season.i;
}

export function competitionUrl(id) {
    return "/competition/" + id;
}