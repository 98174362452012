import React, {useState} from 'react';
import ReactPlayer from "react-player";
import {useMediaViewer} from "../../hooks/useMediaViewer";
import {YoutubeThumb} from "./highlights";
import {useWindowContext} from "../../hooks/useWindowContext";
import {CloseButton} from "react-bootstrap";
import {ID} from "./alias";

export const MediaPlayer = () => {
    let {activeMedia, setActiveMedia} = useMediaViewer();
    let {clientWidth} = useWindowContext();
    let [error, setError] = useState(null);
    if (activeMedia == null) {
        return <></>
    } else {
        let width = clientWidth < 640 ? clientWidth : Math.max(640, clientWidth / 3);
        let height = Math.round(width * 9 / 16);
        return <div className='player-wrapper' style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 100,
            margin: 0,
            padding: 0,
            width: width,
            height: height
        }}>
            <CloseButton onClick={() => setActiveMedia(null)} style={{position: 'absolute', top: 0, right: 0}}/>
            {error === activeMedia[ID] ? <YoutubeThumb thumbHeight={height} thumbWidth={width} video={activeMedia} opensInNewWindow={true}/> :
                <ReactPlayer
                    className='react-player'
                    url={'https://www.youtube.com/watch?v=' + activeMedia[ID]}
                    width='100%'
                    height='100%'
                    controls={true}
                    onError={() => setError(activeMedia[ID])}
                />}
        </div>
    }
}