import React, {Suspense, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Badge, Button, Card, Dropdown, Form, ListGroup, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import _ from "lodash";
import {Flag} from "./flag";
import {getZone, Position, PositionZone, zoneNames} from "./position";
import {ColorScaleProgressBar, hotScale} from './colorprogress';
import {
    faArrowUp,
    faBolt,
    faBoxesStacked,
    faCaretDown,
    faCircleNodes,
    faClock,
    faCog,
    faDove,
    faFutbol,
    faGraduationCap,
    faHandFist,
    faHandsHelping,
    faMedal,
    faMoon,
    faPaintBrush,
    faPercent,
    faPersonFalling,
    faPersonMilitaryRifle,
    faPersonRunning,
    faPlusMinus,
    faSearch,
    faShield,
    faSortAmountUpAlt,
    faSun,
    faTable,
    faTrophy,
    faUserClock,
    faUserGraduate,
    faUsers,
    faUsersViewfinder
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import {FootballEvent, Gender, GenderToggle, penaltyColor} from "./icons";
import {settings} from "../../config";
import {TeamLogo} from "./team";
import {useImage} from "react-image";
import {sizeString} from "./img";
import {isValidNumber, stopDefault} from "../../helpers/utils";
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import {Loading} from "./spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {yearDiff} from "./comparison";
import {FixedSizeList as List} from "react-window";
import {competitionSeasonUrl, competitionUrl, playerProfileUrl, teamProfileUrl} from "../../helpers/urls";
import {useWindowContext} from "../../hooks/useWindowContext";
import {formatFixed} from "../../helpers/numberutils";
import {TCIRadar} from "../chart/tciradar";
import {countries} from "../../data/lookup";
import {CompetitionBadge, formatCompetitionSeasonName} from "./competition";
import {
    ADVANCING,
    AERIALS,
    ASSISTING,
    ATT,
    AWAY,
    BLOCKING,
    CLUB,
    COMPETITION,
    COUNTRY,
    CREATIVITY,
    DATE,
    DEF,
    DUELS,
    GENDER,
    ID,
    INFLUENCE,
    NAME,
    PASSING,
    POSITION,
    RANK,
    RATING,
    SEASON_SECONDARY,
    SHOOTING,
    STATS,
    TACKLING,
    TEAM,
    THREAT,
    WEIGHT,
    ZONE
} from "./alias";
import {filteredSearch} from "../../helpers/searchIndex";
import {playerImageData} from "../../helpers/imageIndex";

export const fieldDisplay = ['r', 'rz', 'rc', 'rl', 'lrt', RATING, ATT, DEF, THREAT, CREATIVITY, INFLUENCE, SHOOTING,
    ASSISTING,
    PASSING,
    ADVANCING,
    TACKLING,
    BLOCKING,
    AERIALS,
    DUELS,
    'trt', 'g', 'a', 'cp', 'cpi', 'winrate', 'avpoints', 'penaltysuccess', 'pg', 'penaltiestaken', 's', 's90', 'cy', 'cr', 'og', 'gt', 'go', 'goaldiff', 'goalsfraction', 'goalsassistfraction', 'g90', 'a90', 'gt90', 'go90', 'xga90', 'xgp90', 'xps90', 'xgc90', 'xgf90', 'xgpf', 'stfpg']

export const axes = {
    rating: {
        min: 0,
        max: 100
    },
    rank: {
        min: 1,
        log: true,
        reverse: true
    },
    absent: {
        hide: true,
        min: 0,
        max: 1,
    },
    goals: {
        min: 0
    },
    goals90: {
        min: 0
    },
    apps: {
        min: 0
    },
    sot: {
        min: 0
    },
    mins: {
        min: 0
    },
    diff: {},
    diff90: {},
    points: {
        min: 0
    }
}

let defaultMin = 27000;

export const fields = {
    lrt: {
        name: 'Lifetime Rating',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faMedal, color: '#ff9900'},
        barexclude: true,
    },
    rl: {
        name: 'Lifetime Rank',
        reverse: true,
        lowerIsBetter: true,
        min: 1,
        max: 1000,
        faIcon: {icon: faTrophy, color: '#ff9900'},
        axis: 'rank',
        axisMin: 1,
        barexclude: true,
    },
    [RATING]: {
        name: 'Rating',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faMedal, color: '#ff9900'}
    },
    trt: {
        name: 'Team Rating',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faMedal, color: '#ff9900'},
        fn: x => _.get(x[CLUB], RATING)
    },
    [ATT]: {
        name: 'Attack',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPersonRunning, color: '#ff9900'}
    },
    [DEF]: {
        name: 'Defend',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faShield, color: '#ff9900'}
    },
    [THREAT]: {
        name: 'Threat',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faBolt, color: '#ff9900'}
    },
    [CREATIVITY]: {
        name: 'Creativity',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPaintBrush, color: '#ff9900'}
    },
    [INFLUENCE]: {
        name: 'Influence',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faUserGraduate, color: '#ff9900'}
    },
    [SHOOTING]: {
        name: 'Shooting',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPersonMilitaryRifle, color: '#ff9900'}
    },
    [ASSISTING]: {
        name: 'Assisting',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faHandsHelping, color: '#ff9900'}
    },
    [PASSING]: {
        name: 'Passing',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faCircleNodes, color: '#ff9900'}
    },
    [ADVANCING]: {
        name: 'Advancing',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faArrowUp, color: '#ff9900'}
    },
    [TACKLING]: {
        name: 'Tackling',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPersonFalling, color: '#ff9900'}
    },
    [BLOCKING]: {
        name: 'Blocking',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faBoxesStacked, color: '#ff9900'}
    },
    [AERIALS]: {
        name: 'Aerials',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faDove, color: '#ff9900'}
    },
    [DUELS]: {
        name: 'Duels',
        min: 0,
        max: 100,
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faHandFist, color: '#ff9900'}
    },
    r: {
        name: 'Current Rank',
        reverse: true,
        lowerIsBetter: true,
        min: 1,
        max: 1000,
        faIcon: {icon: faSortAmountUpAlt, color: '#ff9900'},
        axis: 'rank',
        axisMin: 1,
        barexclude: true,
    },
    rz: {
        name: 'Position Rank',
        reverse: true,
        lowerIsBetter: true,
        min: 1,
        max: 1000,
        iconfn: (x) => <PositionZone id={getZone(x.pos)} style={{width: 20}}/>,
        axis: 'rank',
        axisMin: 1,
        barexclude: true,
    },
    rc: {
        name: 'Country Rank',
        min: 1,
        max: 1000,
        reverse: true,
        lowerIsBetter: true,
        iconfn: (x) => <Flag id={x.c} size={"1x"}/>,
        axis: 'rank',
        axisMin: 1,
        barexclude: true,
    },
    g: {
        name: 'Goals',
        min: 0,
        max: 250,
        sum: true,
        tooltip: 'Goals scored by player',
        axis: 'goals',
        icon: <FootballEvent id={0} fixedWidth/>
    },
    g90: {
        name: 'Goals /90',
        min: 0,
        max: 0.5,
        sum: true,
        tooltip: 'Goals scored by player per 90 mins',
        axis: 'goals90',
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.g / x.s * 5400,
        denomfn: x => x.s,
        denommin: defaultMin,
    },
    xga: {
        name: 'Goals Added',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(Goals scored - XG) by player',
        axis: 'diff',
        dp: 3,
        icon: <FootballEvent id={0} fixedWidth/>
    },
    xga90: {
        name: 'Goals Added /90',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(Goals scored - XG) by player per 90 mins',
        axis: 'diff90',
        dp: 3,
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.xga / x.xs * 5400,
        denomfn: x => x.xs,
        denommin: defaultMin,
    },
    xgp: {
        name: 'Goals Prevented',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(XG Post Shot - Goals Conceded) by player',
        axis: 'diff',
        dp: 3,
        gk: true,
        icon: <FootballEvent id={0} fixedWidth/>,
    },
    xgp90: {
        name: 'Goals Prevented /90',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(XG Post Shot - Goals Conceded) by player per 90 mins',
        axis: 'diff90',
        dp: 3,
        icon: <FootballEvent id={0} fixedWidth/>,
        gk: true,
        fn: (x) => x.xgp / x.xs * 5400,
        denomfn: x => x.xs,
        denommin: defaultMin,
    },
    xgf: {
        name: 'XG Faced',
        min: 0,
        max: 2,
        sum: true,
        tooltip: 'XG Post Shot faced by player',
        axis: 'goals',
        dp: 2,
        gk: true,
        icon: <FootballEvent id={0} fixedWidth/>,
    },
    xgf90: {
        name: 'XG Faced /90',
        min: 0,
        max: 2,
        sum: true,
        tooltip: 'XG Post Shot faced by player per 90 mins',
        axis: 'goals90',
        dp: 2,
        gk: true,
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.xgf / x.xs * 5400,
        denomfn: x => x.xs,
        denommin: defaultMin,
    },
    xgpf: {
        name: 'Prevented /XG Faced',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(XG Post Shot - Goals Conceded) / XG Post Shot Faced by player',
        axis: 'diff',
        icon: <FootballEvent id={0} fixedWidth/>,
        gk: true,
        dp: 3,
        fn: (x) => x.xgp / x.xgf,
        denomfn: x => x.xgf,
        denommin: 5,
    },
    xgc: {
        name: 'NP Conceded',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: 'Goals conceded during regular play (excluding penalties, own goals)',
        axis: 'diff90',
        icon: <FootballEvent id={0} fixedWidth/>,
        gk: true,
        dp: 2,
        fn: (x) => x.xgf - x.xgp,
    },
    xgc90: {
        name: 'NP Conceded /90',
        min: 0,
        max: 2,
        sum: true,
        tooltip: 'Goals conceded during regular play (excluding penalties, own goals) per 90 mins',
        axis: 'goals90',
        icon: <FootballEvent id={0} fixedWidth/>,
        gk: true,
        dp: 2,
        fn: (x) => (x.xgf - x.xgp) / x.xs * 5400,
        denomfn: x => x.xs,
        denommin: defaultMin
    },
    stf: {
        name: 'SOT Faced',
        min: 0,
        max: 10,
        sum: true,
        tooltip: 'Shots on Target Faced by player',
        axis: 'sot',
        icon: <FootballEvent id={0} fixedWidth/>,
        gk: true,
    },
    stfpg: {
        name: 'SOT Faced /Goal',
        min: 0,
        max: 10,
        sum: true,
        tooltip: 'Shots on Target Faced by player per goal conceded',
        axis: 'goals90',
        icon: <FootballEvent id={0} fixedWidth/>,
        gk: true,
        fn: (x) => x.stf / x.stg,
        denomfn: x => x.stg,
        denommin: 5,
    },
    xs: {
        name: 'Tracked Mins',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: 'Detailed Match Mins',
        axis: 'mins',
        faIcon: {icon: faClock},
        fn: (x) => x.xs / 60,
    },
    xps: {
        name: 'Shot Quality',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(XG Post Shot - XG) by player',
        axis: 'diff',
        dp: 3,
        icon: <FootballEvent id={0} fixedWidth/>,
    },
    xps90: {
        name: 'Shot Quality /90',
        min: -0.5,
        max: 0.5,
        sum: true,
        tooltip: '(XG Post Shot - XG) by player per 90 mins',
        axis: 'diff90',
        dp: 3,
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.xps / x.xs * 5400,
        denomfn: x => x.xs,
        denommin: defaultMin,
    },
    a: {
        name: 'Assists',
        min: 0,
        max: 250,
        sum: true,
        tooltip: 'Assists made by player',
        icon: <FootballEvent id={0} fixedWidth related/>,
        axis: 'goals',
    },
    a90: {
        name: 'Assists /90',
        min: 0,
        max: 0.5,
        sum: true,
        tooltip: 'Assists made by player per 90 mins',
        icon: <FootballEvent id={0} fixedWidth related/>,
        axis: 'goals',
        fn: (x) => x.a / x.s * 5400,
        denomfn: x => x.s,
        denommin: defaultMin,
    },
    cp: {
        name: 'Apps',
        min: 0,
        max: 500,
        sum: true,
        tooltip: 'Total number of club matches where player played part of match',
        faIcon: {icon: faGraduationCap, color: '#22ff00'},
        axis: 'apps',
    },
    cpi: {
        name: 'Int Caps',
        min: 0,
        max: 150,
        sum: true,
        tooltip: 'Total number of international matches where player played part of match',
        faIcon: {icon: faGraduationCap, color: '#00ffea'},
        axis: 'apps',
    },
    s: {
        name: 'Mins',
        min: 0,
        max: 40000,
        sum: true,
        dp: 0,
        fn: (x) => x.s / 60,
        tooltip: 'Total mins played',
        faIcon: {icon: faClock},
        axis: 'mins',
    },
    s90: {
        name: 'Av Mins',
        min: 0,
        max: 90,
        sum: true,
        fn: (x) => x.s / 60 / x.nt,
        denomfn: x => x.nt,
        tooltip: 'Average mins per match',
        faIcon: {icon: faUserClock},
        axis: 'rating',
        dp: 1,
    },
    cy: {
        name: 'Yellow Cards',
        min: 0,
        max: 150,
        sum: true,
        lowerIsBetter: true,
        axis: 'apps',
        icon: <FootballEvent id={3} fixedWidth/>,
    },
    cr: {
        name: 'Red Cards',
        min: 0,
        max: 10,
        sum: true,
        lowerIsBetter: true,
        axis: 'apps',
        icon: <FootballEvent id={2} fixedWidth/>
    },
    pg: {
        name: 'Penalty Goals',
        min: 0,
        max: 100,
        sum: true,
        axis: 'goals',
        icon: <FootballEvent id={9} fixedWidth/>
    },
    gt: {
        name: 'Team Goals',
        min: 0,
        max: 1000,
        sum: true,
        tooltip: 'Number of goals scored by team while player was on pitch',
        axis: 'apps',
        icon: <FootballEvent id={0} fixedWidth/>
    },
    gt90: {
        name: 'Team Goals /90',
        min: 0,
        max: 2.5,
        sum: true,
        tooltip: 'Number of goals scored by team while player was on pitch',
        axis: 'apps',
        icon: <FootballEvent id={0} fixedWidth/>,
        fn: (x) => x.gt / x.s * 5400,
        denomfn: x => x.s,
        denommin: defaultMin,
    },
    go: {
        name: 'Conceded',
        min: 0,
        max: 1000,
        sum: true,
        lowerIsBetter: true,
        tooltip: 'Number of goals conceded by team while player was on pitch',
        axis: 'apps',
        faIcon: {icon: faFutbol, color: '#ff0000'},
    },
    go90: {
        name: 'Conceded /90',
        min: 0,
        max: 2.5,
        sum: true,
        lowerIsBetter: true,
        tooltip: 'Number of goals conceded per 90 mins by team while player was on pitch',
        axis: 'apps',
        faIcon: {icon: faFutbol, color: '#ff0000'},
        fn: (x) => x.go / x.s * 5400,
        denomfn: x => x.s,
        denommin: defaultMin,
    },
    og: {
        name: 'Own Goals',
        min: 0,
        max: 10,
        sum: true,
        lowerIsBetter: true,
        axis: 'goals',
        icon: <FootballEvent id={1} fixedWidth/>
    },
    goalsfraction: {
        name: 'Goals / Team',
        min: 0,
        max: 25,
        sum: true,
        fn: x => 100 * x.g / x.gt,
        denomfn: x => x.gt,
        tooltip: 'Fraction of team goals scored by player while on pitch',
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPercent}
    },
    goalsassistfraction: {
        name: 'G + A / Team',
        min: 0,
        max: 25,
        sum: true,
        fn: x => 100 * (x.g + x.a) / x.gt,
        denomfn: x => x.gt,
        tooltip: 'Fraction of team goals scored or assisted by player while on pitch',
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPercent}
    },
    penaltiestaken: {
        name: 'Penalty Taken',
        min: 0,
        max: 100,
        sum: true,
        fn: x => x.pg + x.pm,
        axis: 'goals',
        icon: <FootballEvent id={9} fixedWidth/>
    },
    penaltysuccess: {
        name: 'Penalty Success',
        min: 0,
        max: 100,
        sum: true,
        fn: x => 100 * x.pg / (x.pg + x.pm),
        denomfn: x => (x.pg + x.pm),
        tooltip: 'Penalties scored as a fraction of those taken',
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPercent, color: penaltyColor}
    },
    goaldiff: {
        name: 'Av Goal Diff /90',
        min: -5,
        max: 5,
        sum: true,
        fn: x => (x.gt - x.go) / x.s * 5400,
        denomfn: x => x.s,
        tooltip: 'Team goals scored minus goals conceded while player was on pitch',
        axis: 'diff',
        faIcon: {icon: faPlusMinus},
        denommin: defaultMin,
    },
    winrate: {
        name: 'Win Rate',
        min: 0,
        max: 100,
        sum: true,
        fn: (x) => 100 * x.w / (x.w + x.d + x.l),
        denomfn: x => x.w + x.d + x.l,
        tooltip: '% of matches won that player played in',
        axis: 'rating',
        dp: 1,
        faIcon: {icon: faPercent}
    },
    avpoints: {
        name: 'Av Points',
        min: 0,
        max: 3,
        sum: true,
        fn: (x) => (x.w * 3 + x.d) / (x.w + x.d + x.l),
        denomfn: x => x.w + x.d + x.l,
        tooltip: 'Av league points for team matches that player played in (W=3, D=1, L=0)',
        axis: 'points',
        faIcon: {icon: faTable}
    }
}

export const PLAYER_CARD_SIZE = 194;
export const TABLE_LINE_HEIGHT = 41;

export function createPlayerChildBars(range) {
    return _.get(range[CLUB], ID) != null ?
        <Link to={teamProfileUrl(range[CLUB][ID])}>
            <TeamLogo {...range[CLUB]} size={32}/>
        </Link> : <></>;
}

export function createCompetitionBars(range) {
    return _.get(range[COMPETITION], ID) != null ?
        <Link to={competitionUrl(range[COMPETITION][ID])}>
            <CompetitionBadge {...range[COMPETITION]} height={18}/>
        </Link> : <></>;
}

export function fetchPlayerDetails(id) {
    return fetch(settings.apiServer + "player/" + id + ".json")
        .then(res => res.json())
}

export function calculateStatValue(stats, field, fields, smooth = false, missingValue) {
    if (stats == null) {
        return missingValue;
    }
    let config = fields[field];
    if (config.fn != null) {
        let val = config.fn(stats);
        if (smooth && config.denommin != null && config.denomfn != null) {
            let denom = config.denomfn(stats)
            if (denom < config.denommin) {
                val = val * denom / config.denommin;
            }
        }
        return val;
    } else {
        return stats[field];
    }
}

export function calculateStatDenom(stats, field, fields, smooth = false) {
    if (stats == null) {
        return 1;
    }
    let config = fields[field];
    if (config.denomfn != null) {
        let denom = config.denomfn(stats)
        if (smooth && config.denommin != null) {
            return Math.max(denom, config.denommin);
        }
        if (denom == null) {
            return 0;
        }
        return denom;
    } else {
        return 1;
    }
}

export function calculateItemValue(item, field, date, statsKey = 's', fields) {
    if (field === 'age') {
        if (item.dob == null) {
            return null;
        } else {
            return dayjs(date).diff(item.dob, 'year', true);
        }
    } else if (field === 'lat') {
        if (item.lat != null) {
            return item.lat;
        } else {
            return _.get(countries[item.c], 'lat', null);
        }
    } else if (field === 'lon') {
        if (item.lon != null) {
            return item.lon;
        } else {
            return _.get(countries[item.c], 'lon', null);
        }
    } else {
        let val = calculateStatValue(item[statsKey], field, fields);
        if (val == null) {
            return item[field];
        } else {
            return val;
        }
    }
}

export function calculatePeak(stats, key, fields) {
    let values = _.filter(_.map(stats, (p, date) => ({
        date: date,
        value: calculateStatValue(p, key, fields)
    })), p => isValidNumber(p.value));
    return fields[key].reverse ? _.minBy(values, x => x.value) : _.maxBy(values, x => x.value);
}

export function calculatePeaks(stats, fields) {
    let fieldBests = {};
    _.each(fields, (val, key) => {
        fieldBests[key] = calculatePeak(stats, key, fields);
    })
    return fieldBests;
}

export const StatProgressBar = ({
                                    fields,
                                    field,
                                    item,
                                    stats,
                                    onClick,
                                    selected,
                                    small = false,
                                    min = null,
                                    max = null,
                                    bestValue = null
                                }) => {
    let config = fields[field];
    let icon = config.iconfn != null ? config.iconfn(item) : config.icon;
    if (min == null || config.reverse) {
        min = config.min;
    }
    if (max == null) {
        max = config.max;
    }

    let maxDp = config.dp != null ? config.dp : 2;
    let tooltip = (config.tooltip || config.name) + (bestValue != null ? " Peak of " + _.round(bestValue.value, maxDp) + " on " + dayjs(bestValue.date).format("DD MMM YYYY") : '');
    return <ColorScaleProgressBar key={field}
                                  name={config.name}
                                  min={config.reverse ? max : min}
                                  max={config.reverse ? min : max}
                                  value={calculateStatValue(stats, field, fields)}
                                  bestValue={bestValue}
                                  icon={icon}
                                  faIcon={config.faIcon}
                                  tooltip={tooltip}
                                  maxDp={maxDp}
                                  small={small}
                                  selected={selected}
                                  onClick={onClick != null ? (e) => {
                                      onClick(field);
                                      stopDefault(e);
                                  } : null}/>
}

export const PlayerRatingsReel = ({
                                      players,
                                      display = 2,
                                      statsKey = 's',
                                      ratingScale = 100,
                                      seasonView = false,
                                      onClubClick,
                                      onPositionClick,
                                      referenceDate,
                                      toggleSort,
                                      fieldsToDisplay = ['a90', 'goalsassistfraction', 'rc', 'cp', 's90']
                                  }) => {
    const ref = useRef(null);

    const {clientWidth} = useWindowContext();
    const [width, setWidth] = useState(500);

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, [clientWidth]);

    let thumbHeight = 145;
    let bottomMargin = 6
    let itemHeight = thumbHeight + 43 + bottomMargin;

    let radarSize = Math.max(Math.min(width - 250, thumbHeight), 10);

    let tciKeys = [RATING, DEF, CREATIVITY, INFLUENCE, THREAT, ATT];
    let forcedFields = ['g90'];
    let forcedGkFields = ['go90'];

    let fieldMaxes = {};
    _.each(_.concat(fieldsToDisplay, forcedFields, forcedGkFields), f => {
        fieldMaxes[f] = _.max(_.filter(_.map(players, p => calculateStatValue(p[statsKey], f, fields)), isValidNumber));
    })

    const PlayerRatingCard = ({index, style}) => {
        let p = players[index];
        let stats = _.get(p, statsKey, {});
        let date = dayjs(p[DATE]);
        let data = _.map(tciKeys, k => _.get(stats, k));
        let gk = p.pos === 0;
        let filteredFields = _.slice(_.uniq(_.concat(gk ? forcedGkFields : forcedFields, fieldsToDisplay)), 0, 6);
        let bars = _.map(filteredFields, f => <StatProgressBar fields={fields}
                                                               key={f}
                                                               field={f}
                                                               item={p}
                                                               stats={stats}
                                                               small
                                                               min={0}
                                                               max={Math.max(fieldMaxes[f], 1)}
                                                               onClick={k => toggleSort(k)}/>);
        let age = yearDiff(p.dob, dayjs(seasonView ? p.ss.en : (referenceDate || new Date())));
        return <div key={p.id} style={{...style}}><ListGroup.Item
            className="p-0 mx-0 mb-2 bordered border-primary position-relative"
            style={{
                borderRadius: 8,
                marginBottom: bottomMargin
            }}>
            <Link className="text-center p-1 rounded-bottom-0 w-100 btn btn-outline-primary"
                  to={seasonView ? competitionSeasonUrl(p.ss) : playerProfileUrl(p.i)}>
                {seasonView ? formatCompetitionSeasonName(p.ss) : displayName(p.n, 30)}
                {date.isBefore(dayjs().add(-6, 'month')) ?
                    <Badge pill bg="light" title="Stats Effective Date" className="text-dark position-absolute fs--2"
                           style={{right: 1, top: 1}}>{date.format('DD MMM YY')}</Badge> : <></>}
            </Link>
            <div className="d-flex flex-row">
                <div>
                    <div>
                        <Badge className="em4 px-0 py-2 m-0 text-center" bg="secondary">
                            {stats.r}.
                        </Badge>
                    </div>
                    <div className="mt-1">
                        <Badge className="em4 px-0 py-2 m-0 text-center" bg="custom"
                               style={{backgroundColor: hotScale(stats.rt / ratingScale)}} title="Player Rating">
                            {formatFixed(stats.rt, 1)}
                        </Badge>
                    </div>
                    <div className="mt-1 text-center cursor-pointer"
                         onClick={() => onClubClick(_.get(_.get(stats, 'cl'), 'i'))}>
                        {_.get(stats, 'cl') != null ? <TeamLogo {...stats.cl} size={32}/> : <></>}
                    </div>
                    <div className="mt-0 ms-0 text-center">
                        <Badge className="em3 m-0 text-center" bg="custom"
                               style={{backgroundColor: hotScale(p.dob != null ? age / 50 : 0.5)}} title="Age">
                            {p.dob != null ? formatFixed(age, 2) : '-'}
                        </Badge>
                    </div>
                    <div className="mt-0 ms-0 text-center cursor-pointer"
                         onClick={() => onPositionClick(getZone(p.pos))}>
                        <Position id={p.pos} zone={2}/>
                    </div>
                </div>
                <div className={"py-1 flex-grow-1"}>
                    <ListGroup variant="flush" className="overflow-x-hidden" style={{height: thumbHeight}}>
                        {bars}
                    </ListGroup>
                </div>
                <div className="m-0 px-2 h-100" style={{width: radarSize}}>
                    <TCIRadar data={data} maxValue={ratingScale}
                              onClick={(index) => toggleSort(tciKeys[_.get(index[0], 'index', 0)])}/>
                </div>
            </div>
        </ListGroup.Item>
        </div>
    }

    return <ListGroup ref={ref}>
        <List height={itemHeight * Math.min(display, players.length)}
              itemCount={players.length}
              itemSize={itemHeight}
              className={"overflow-x-hidden"}>
            {PlayerRatingCard}
        </List>
    </ListGroup>
}

export const PlayerRatingsTable = ({
                                       players,
                                       display = 10,
                                       statsKey = STATS,
                                       ratingScale = 100,
                                       seasonView = false,
                                       displayStat = RATING,
                                       onClubClick,
                                       onPositionClick,
                                       onCountryClick
                                   }) => {
    const ref = useRef(null);

    const RenderedItem = ({index, style}) => {
        let p = players[index];
        let stats = _.get(p, statsKey, {});
        return <ListGroup.Item action as={Link}
                               to={seasonView ? competitionSeasonUrl(p[SEASON_SECONDARY]) : playerProfileUrl(p[ID])}
                               key={p[ID]} style={style}>
            <div className="d-inline-block" style={{marginTop: -4, marginLeft: -8, cursor: 'pointer'}} onClick={(e) => {
                stopDefault(e);
                onClubClick(_.get(stats[CLUB], [ID]));
            }}>
                <TeamLogo {...stats[CLUB]} size={32}/>
            </div>
            {seasonView ? <></> : <span className="ms-1">{stats[RANK]}.</span>}
            <span
                className="ms-1">{seasonView ? formatCompetitionSeasonName(p[SEASON_SECONDARY]) : displayName(p[NAME], 30)}</span>
            <span className="float-end">
            <span className="me-1 cursor-pointer" onClick={(e) => {
                stopDefault(e);
                onPositionClick(getZone(p[POSITION]));
            }}>
                <Position id={p[POSITION]} zone/>
            </span>
                {seasonView ? <></> :
                    <span className="mx-1 cursor-pointer" onClick={(e) => {
                        stopDefault(e);
                        onCountryClick(p[COUNTRY]);
                    }}>
                <Flag id={p[COUNTRY]}/>
            </span>}
                <Badge className="em4 px-0 ms-2 my-auto text-center" bg="custom" title="Player Rating"
                       style={{backgroundColor: hotScale(calculateStatValue(p[statsKey], displayStat, fields) / ratingScale)}}>{formatFixed(calculateStatValue(p[statsKey], displayStat, fields), 1)}</Badge>
            </span>
        </ListGroup.Item>
    };
    let height = display * TABLE_LINE_HEIGHT;
    return <ListGroup ref={ref}>
        <List
            height={height}
            itemCount={players.length}
            itemSize={TABLE_LINE_HEIGHT}>
            {RenderedItem}
        </List>
    </ListGroup>
}

export const PlayerList = ({
                               players,
                               title = 'Squad',
                               statsKey = STATS,
                               size = 5,
                               ratingScale = 100,
                               genderToggle = false,
                               seasonView = false,
                               defaultSort = RATING,
                               countryState,
                               genderState,
                               clubState,
                               positionState,
                               referenceDate
                           }) => {
    const [table, setTable] = useState(0);
    const [position, setPosition] = positionState || useState(4);
    const [club, setClub] = clubState || useState(null);
    const [country, setCountry] = countryState || useState(null);
    const [gender, setGender] = genderState || useState(0);
    const [sort, setSort] = useState(defaultSort);
    const toggleSort = (field) => setSort(field === sort ? defaultSort : field);
    const [fieldsToDisplay, setFieldsToDisplay] = useState(['a90', 'goalsassistfraction', 'rc', 'cp', 's90']);

    const toggleFieldToDisplay = (item) => {
        if (fieldsToDisplay.includes(item)) {
            setFieldsToDisplay(_.filter(fieldsToDisplay, f => f !== item));
        } else {
            setFieldsToDisplay(_.concat([item], fieldsToDisplay));
            setSort(item);
        }
    }

    let list = _.map(fields, (value, key) => {
        return <Dropdown.Item key={key} onClick={() => toggleFieldToDisplay(key)}
                              active={fieldsToDisplay.includes(key)}>
            {value.name}
        </Dropdown.Item>
    })

    let toggleClub = cl => cl === club ? setClub(null) : setClub(cl);
    let togglePosition = pos => pos === position ? setPosition(4) : setPosition(pos);
    let toggleCountry = c => c === country ? setCountry(null) : setCountry(c);

    let content;
    if (players == null) {
        content = <Loading/>
    } else {
        let filteredPlayers = _.filter(players, p => (position === 4 || (getZone(p.pos) === position))
            && (club == null || _.get(p[statsKey][CLUB], ID) === club)
            && (country == null || p[COUNTRY] === country)
            && (!genderToggle || gender === p[GENDER]));

        let sortedPlayers;
        if (sort == null) {
            sortedPlayers = filteredPlayers;
        } else {
            let pairs = _.filter(_.map(filteredPlayers, p => ({
                val: calculateStatValue(p[statsKey], sort, fields),
                p: p
            })), pair => isValidNumber(pair.val));
            sortedPlayers = _.map(_.orderBy(pairs, p => p.val, fields[sort].lowerIsBetter ? "asc" : "desc"), pair => pair.p);
        }

        if (table === 1) {
            content =
                <PlayerRatingsTable players={sortedPlayers}
                                    display={_.round(size * PLAYER_CARD_SIZE / TABLE_LINE_HEIGHT)}
                                    statsKey={statsKey}
                                    seasonView={seasonView}
                                    ratingScale={ratingScale}
                                    onClubClick={toggleClub}
                                    displayStat={sort}
                                    onPositionClick={togglePosition}
                                    onCountryClick={toggleCountry}/>
        } else {
            content = <PlayerRatingsReel players={sortedPlayers}
                                         display={size}
                                         statsKey={statsKey}
                                         seasonView={seasonView}
                                         ratingScale={ratingScale}
                                         onClubClick={toggleClub}
                                         onPositionClick={togglePosition}
                                         onCountryClick={toggleCountry}
                                         toggleSort={toggleSort}
                                         fieldsToDisplay={fieldsToDisplay}
                                         referenceDate={referenceDate}/>
        }
    }
    return <Card className={"mb-3"}>
        <Card.Header className="p-2 bg-light text-dark">
            <span className="float-end">
                {genderToggle ? <GenderToggle selected={gender} onChange={setGender} id="player"/> :
                    <span key="gender"/>}
                <span className="ms-1"/>
                <DefaultDropdown id="player-settings-dropdown" content={<FontAwesomeIcon icon={faCog}/>}>
                    <Dropdown.Header>Display Mode</Dropdown.Header>
                    <Dropdown.Item onClick={() => setTable(0)} active={table === 0}>
                        <FontAwesomeIcon icon={faUsersViewfinder}/>
                        <span className="text-dark ms-2">Card View</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTable(1)} active={table === 1}>
                        <FontAwesomeIcon icon={faTable}/>
                        <span className="text-dark ms-2">Table View</span>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Header>Position</Dropdown.Header>
                    <Dropdown.Item onClick={() => setPosition(0)} active={position === 0}>
                        <PositionZone id={0}/>
                        <span className="text-dark ms-2">{zoneNames[0]}</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPosition(1)} active={position === 1}>
                        <PositionZone id={1}/>
                        <span className="text-dark ms-2">{zoneNames[1]}</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPosition(2)} active={position === 2}>
                        <PositionZone id={2}/>
                        <span className="text-dark ms-2">{zoneNames[2]}</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPosition(3)} active={position === 3}>
                        <PositionZone id={3}/>
                        <span className="text-dark ms-2">{zoneNames[3]}</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPosition(4)} active={position === 4}>
                        <FontAwesomeIcon icon={faUsers}/>
                        <span className="text-dark ms-2">All Positions</span>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Header>Gender</Dropdown.Header>
                    <Dropdown.Item onClick={() => setGender(0)} active={gender === 0}>
                        <Gender gender={0} fixedWidth/>
                        <span className="text-dark ms-2">Male</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setGender(1)} active={gender === 1}>
                        <Gender gender={1} fixedWidth/>
                        <span className="text-dark ms-2">Female</span>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Header>Statistic</Dropdown.Header>
                    {list}
                </DefaultDropdown>
            </span>
            <Card.Title><span className="text-nowrap">{title}</span></Card.Title>
        </Card.Header>
        {content}
    </Card>
}

export const FieldSelectorDropdown = ({content, setField, availableOptions = fields}) => {
    let list = _.map(availableOptions, (value, key) => {
        return <Dropdown.Item key={key} onClick={() => setField(key)}>
            {value.name}
        </Dropdown.Item>
    })
    return <DefaultDropdown content={<>{content}<FontAwesomeIcon icon={faCaretDown} className="ms-1"/></>}>
        {list}
    </DefaultDropdown>;
}

export const DefaultDropdown = ({content, children, id="settings-dropdown-toggle"}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <Dropdown className="d-inline-block" onToggle={toggle}>
            <Dropdown.Toggle
                id={id}
                title="Settings"
                as={Button}
                variant="primary"
                size="sm"
                className="py-0 px-1"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                bsPrefix="toggle"
            >
                {content}
            </Dropdown.Toggle>
            <Dropdown.Menu show={dropdownOpen}>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export const PlayerSelectorOverlay = ({gender, id}) => {
    const [show, setShow] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    let height = 600;
    return <>
        <Button variant="primary" size={"sm"} className="py-0" onClick={handleShow}>
            <FontAwesomeIcon icon={faSearch}/> Compare
        </Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="py-2" closeButton>
                <Form.Control
                    id="player-search"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    className="rounded-pill search-input"
                    value={searchInputValue}
                    onChange={({target}) => setSearchInputValue(target.value)}
                />
            </Modal.Header>
            <Card className={"mb-2"}>
                <ListGroup className="list-group-flush" style={{height}}>
                    {show ? <PlayerSelectorList searchInputValue={searchInputValue} id={id} height={height}
                                                gender={gender}/> : <></>}
                </ListGroup>
            </Card>
        </Modal>
    </>
}

export const PlayerSelectorList = ({searchInputValue, id, gender, height}) => {
    const [filteredIndex, setFilteredIndex] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        filteredSearch(o => o.type === 'player' && o[GENDER] === gender)
            .then(index => setFilteredIndex(index))
    }, []);

    useEffect(() => {
        if (filteredIndex != null) {
            setSearchResults(_.map(filteredIndex.search(searchInputValue, 100), item => item.item));
        }
    }, [filteredIndex, searchInputValue])

    const RenderedItem = ({index, style}) => {
        let player = searchResults[index];
        let targetUrl = "/playercomparison/" + id + "-" + player[ID];
        return <ListGroup.Item as={Link} to={targetUrl} key={player[ID]} style={style} action>
            <div style={{position: 'absolute', left: 8, top: 4}}>
                <TeamLogo i={player[TEAM]} size={32}/>
            </div>
            <span className="ms-4">
                {displayName(player[NAME], 25)}
            </span>
            <span className="float-end">
                <span className="me-1">
                    {player[AWAY] ?
                        <FontAwesomeIcon title={"Active"} icon={faSun} size="sm" fixedWidth color={"#ff9900"}/> :
                        <FontAwesomeIcon title={"Retired"} icon={faMoon} size="sm" color={"#999999"} fixedWidth/>}
                </span>
                <PositionZone id={player[ZONE]}/>
                <Badge className="em4 px-0 ms-1 my-auto text-center" bg="custom" title="Player Rating"
                       style={{backgroundColor: hotScale(_.get(player, WEIGHT) / 100)}}>{_.round(_.get(player, WEIGHT), 2)}</Badge>
            </span>
        </ListGroup.Item>
    };

    let loaded = filteredIndex != null;

    if (loaded) {
        return <List
            height={height}
            itemCount={searchResults.length}
            itemSize={TABLE_LINE_HEIGHT}>
            {RenderedItem}
        </List>
    } else {
        return <Loading/>
    }
}

export const displayName = (name, maxLength = 12, truncate = false) => {
    if (name == null) {
        return '';
    }
    const nameParts = name.split(" ");
    let displayName = nameParts[nameParts.length - 1];
    let showFirstName = false;
    for (let i = nameParts.length - 2; i >= 1; i--) {
        let namePart = nameParts[i];
        if (namePart.length + displayName.length + 1 + (showFirstName ? nameParts[0].length + 1 : 0) > maxLength) {
            break;
        }
        if (namePart.length > 3 && !showFirstName) {
            if (displayName.length + 1 + nameParts[0].length <= maxLength) {
                showFirstName = true;
            } else {
                break;
            }
            if (namePart.length + displayName.length + 1 + nameParts[0].length > maxLength) {
                break;
            }
        }
        displayName = namePart + ' ' + displayName;
    }
    if (nameParts.length > 1 && displayName.length + 1 + nameParts[0].length <= maxLength) {
        showFirstName = true;
    }
    if (showFirstName) {
        displayName = nameParts[0] + ' ' + displayName;
    }
    if (truncate && displayName.length > maxLength) {
        return _.truncate(displayName, {length: maxLength, omission: ''});
    }
    return _.upperFirst(displayName);
}

const SIZES = [40, 80, 160];

export const PlayerImageRender = ({id, name, size}) => {
    let srcList = [];
    const players = playerImageData.read()
    if (players.has(id)) {
        srcList.push(settings.imgServer + 'players/' + sizeString(size, SIZES) + id + '.png')
    }
    srcList.push(settings.imgServer + 'errors/noplayer.svg');
    const {src} = useImage({srcList})
    return <img alt={name} title={name} src={src} width={size} height={size}/>;
};

export const PlayerImage = (props) => <Suspense fallback={<div style={{
    height: props.size,
    width: props.size,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingTop: (props.size - 21) / 2
}}><Loading/></div>}><PlayerImageRender {...props}/></Suspense>