import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import App from './App';
import Main from './Main';

const container = document.getElementById('main');
if (container.hasChildNodes()) {
    hydrateRoot(container, <Main><App/></Main>, {onRecoverableError: () => {}});
} else {
    const root = createRoot(container);
    root.render(<Main><App/></Main>);
}
