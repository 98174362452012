import React from "react";
import {Table} from "react-bootstrap";

export const CookiePolicy = () => {
    return <Table variant="light" striped bordered hover>
        <thead>
            <tr>
                <th>Provider</th>
                <th>Name</th>
                <th>Purpose</th>
                <th>Type</th>
                <th>Duration</th>
            </tr>
        </thead>
        <tbody>
        <tr>
            <td>FootballRatings</td>
            <td>sid</td>
            <td>This cookie is used for user identification of a user session</td>
            <td>First party, Session</td>
            <td>1 year</td>
        </tr>
        <tr>
            <td>FootballRatings</td>
            <td>CookieConsent</td>
            <td>This cookie records whether a user has consented to the storage of cookies</td>
            <td>First party, Session</td>
            <td>1 year</td>
        </tr>
        <tr>
            <td>Google Analytics</td>
            <td>_gat</td>
            <td>This cookie name is associated with Google Universal Analytics, according to <a
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google
                documentation</a> it is used to throttle the request rate - limiting the collection of data on high
                traffic sites. It expires after 10 minutes.
            </td>
            <td>First party, Session</td>
            <td>1 minute</td>
        </tr>
        <tr>
            <td>Google Analytics</td>
            <td>_gid</td>
            <td>This cookie stores a unique user session identifier.</td>
            <td>First & Third party, Session</td>
            <td>24 hours</td>
        </tr>
        <tr>
            <td>Google Analytics</td>
            <td>_ga</td>
            <td>This cookie is typically written to the browser upon the first visit to the Websites. If the cookie has
                been deleted by the browser operator, and the browser subsequently visits pwc.com, a new _ga cookie is
                written with a different unique ID. In most cases, this cookie is used to understand whether a visitor
                is a single time visitor or a repeat visitor to the Websites and it is updated with each page view.
                Additionally, this cookie is provided with a unique ID that Google Analytics uses to ensure both the
                validity and accessibility of the cookie as an extra security measure.
            </td>
            <td>First & Third party, Persistent</td>
            <td>2 years</td>
        </tr>
        <tr>
            <td>Google Analytics</td>
            <td>__utmb</td>
            <td>This cookie is used to determine new sessions/visits.</td>
            <td>First party</td>
            <td>30 mins from set/update</td>
        </tr>
        </tbody>
    </Table>
}