import React, {useEffect, useRef, useState, Suspense} from "react";
import {settings} from "../../config";
import {
    calculateStatValue,
    DefaultDropdown,
    displayName,
    fields,
    FieldSelectorDropdown,
    PLAYER_CARD_SIZE,
    PlayerList,
    TABLE_LINE_HEIGHT
} from "./player";
import ReactSlider from "react-slider";
import {fields as teamFields, getTeamImageUrl, TeamRatingTable} from "./team";
import {Badge, Button, Col, Dropdown, Row} from "react-bootstrap";
import {useSearchParamsState} from "../../hooks/useSearchParamsState";
import {Loading} from "./spinner";
import _ from "lodash"
import {chartJsDefaultTooltip} from "../../helpers/chartjs-utils";
import {getZone, getZoneColor, zonesMedium} from "./position";
import {breakpoints, getColor, getGrays, rgbaColor, stopPropagation} from "../../helpers/utils";
import {playerComparisonUrl} from "./playercomparison";
import {Link} from "react-router-dom";
import {Bar, getElementAtEvent, Scatter} from "react-chartjs-2";
import {useWindowContext} from "../../hooks/useWindowContext";
import {getChartColor} from "./comparison";
import dayjs from "dayjs";
import {
    faArrowRight,
    faArrowUp,
    faChartColumn,
    faCircle,
    faCog,
    faFlag,
    faMagnifyingGlassMinus,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatCompetitionSeasonName, formatSeasonName} from "./competition";
import {teamComparisonUrl} from "./teamcomparison";
import {countries} from "../../data/lookup";
import {flagUrl} from "./flag";
import {
    CLUB,
    COMPETITION,
    COUNTRY,
    END,
    GENDER,
    ID,
    NAME,
    NATIONAL,
    PLAYERID,
    SEASON,
    SEASON_SECONDARY,
    SEASON_STATS,
    START,
    STATS,
    TEAM
} from "./alias";

export const formatRank = (rank) => {
    if (rank <= 0 || rank === 2147483647) {
        return '-';
    } else {
        return rank;
    }
}

export const BASE_DATE = dayjs('1950-01-01');

export const daysAsIso = (days) => {
    return BASE_DATE.add(days, 'day').format('YYYY-MM-DD')
}

export const isoAsDays = (iso) => {
    return dayjs(iso).diff(BASE_DATE, 'day');
}

const previous = (iso, dayOfWeek) => {
    let day = dayjs(iso);
    let dayWithDayOfWeek = day.day(dayOfWeek);
    if (!dayWithDayOfWeek.isAfter(day)) {
        return dayWithDayOfWeek.format('YYYY-MM-DD');
    } else {
        return dayWithDayOfWeek.add(-1, "week").format('YYYY-MM-DD');
    }
}

export const Thumb = (props, state) => {
    let value = BASE_DATE.add(state.valueNow, 'day').format('MMM YYYY');
    return <Badge bg="custom"
                  {...props}
                  className="text-center"
                  style={{
                      ...props.style,
                      width: 70,
                      backgroundColor: getColor('secondary'),
                      cursor: 'grab',
                  }}>
        {value}
    </Badge>;
}

export const Track = (props) => {
    let style = {
        ...props.style,
        color: '#fff',
        top: 0,
        bottom: 0,
        margin: "auto",
        backgroundColor: getGrays()['900'],
        height: 8,
        borderRadius: 999,
    };
    return <div {...props} style={style}/>
}

export const DoubleTrack = (props, state) => {
    let style = {
        ...props.style,
        color: '#fff',
        top: 0,
        bottom: 0,
        margin: "auto",
        height: 8,
        borderRadius: 999,
    };
    if (state.index === 1) {
        style['backgroundColor'] = getColor('secondary');
    } else {
        style['backgroundColor'] = '#333';
    }
    return <div {...props} style={style}/>
}


function calculateItemValue(item, field, date, statsKey = 's', fields) {
    if (field === 'age') {
        if (item.dob == null) {
            return null;
        } else {
            return dayjs(date).diff(item.dob, 'year', true);
        }
    } else if (field === 'lat') {
        if (item.lat != null) {
            return item.lat;
        } else {
            return _.get(countries[item[COUNTRY]], 'lat', null);
        }
    } else if (field === 'lon') {
        if (item.lon != null) {
            return item.lon;
        } else {
            return _.get(countries[item[COUNTRY]], 'lon', null);
        }
    } else {
        let val = calculateStatValue(item[statsKey], field, fields, true);
        if (val == null) {
            return item[field];
        } else {
            return val;
        }
    }
}

export const PlayerRatings = () => <Suspense fallback={<Loading/>}><Ratings type="player"/></Suspense>
export const TeamRatings = () => <Suspense fallback={<Loading/>}><Ratings type="team"/></Suspense>

export const Ratings = ({type = 'player'}) => {
    let {clientHeight, clientWidth} = useWindowContext();
    const [result, setResult] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    let maxDate = dayjs().diff(BASE_DATE, 'day');

    const [filterParams, setFilterParams] = useSearchParamsState({
        x: {type: 'string', default: 'def'},
        y: {type: 'string', default: 'att'},
        [GENDER]: {type: 'number', default: 0},
        [NATIONAL]: {type: 'number', default: 0},
        [COUNTRY]: {type: 'number', default: null},
        [TEAM]: {type: 'string', default: null},
        [PLAYERID]: {type: 'string', default: null},
        team: {type: 'string', default: null},
        icon: {type: 'string', default: 'team'},
        season: {type: 'string', default: null},
        only: {type: 'boolean', default: false},
        pos: {type: 'number', default: 4},
        club: {type: 'string', default: null},
        minDate: {type: 'string', default: null},
        date: {type: 'string', default: daysAsIso(maxDate)},
    })

    useEffect(() => {
        let url;
        if (filterParams[TEAM] != null) {
            url = "team/" + filterParams[TEAM] + ".json";
        } else if (filterParams[PLAYERID] != null) {
            url = "player/" + filterParams[PLAYERID] + ".json";
        } else if (filterParams.team != null) {
            url = "teamplayers/" + filterParams.team + ".json";
        } else if (filterParams.season != null) {
            url = "seasons/" + filterParams.season + ".json";
        } else if (filterParams.date >= daysAsIso(maxDate)) {
            url = "rank/" + type + ".json";
        } else {
            url = "rank/" + type + "/" + previous(filterParams.date, 1) + ".json";
        }
        document.title = _.upperFirst(type) + " Comparison " + filterParams.date + " - Football Ratings";
        fetch(settings.apiServer + url)
            .then(res => res.json())
            .then(
                (result) => {
                    setResult(result);
                }
            )
    }, [filterParams.date]);

    const toggleSelectedItems = (itemId) => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(_.filter(selectedItems, p => p !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    }

    if (result == null) {
        return <Loading/>
    }

    let competitors;
    let effectiveMinDate = filterParams.minDate;
    if (filterParams[TEAM] != null || filterParams[PLAYERID] != null) {
        competitors = _.orderBy(result.z, s => s[SEASON_SECONDARY][START], "desc");
        if (effectiveMinDate == null && competitors.length > 0) {
            effectiveMinDate = daysAsIso(isoAsDays(competitors[0][SEASON_SECONDARY][START]) - 30 * 365);
        }
    } else if (filterParams.season != null) {
        competitors = type === 'player' ? result[PLAYERID] : result[TEAM];
    } else {
        competitors = result;
    }
    let statsKey = (filterParams.only || filterParams[TEAM] != null || filterParams[PLAYERID] != null) ? SEASON_STATS : STATS;

    let filteredItems = _.filter(competitors, p => p[GENDER] === filterParams[GENDER]
        && (filterParams.pos === 4 || getZone(p.pos) === filterParams.pos)
        && (p[NATIONAL] == null || p[NATIONAL] === filterParams[NATIONAL])
        && ((filterParams[TEAM] == null && filterParams[PLAYERID] == null) || p[SEASON_SECONDARY][END] >= effectiveMinDate && p[SEASON_SECONDARY][START] <= filterParams.date)
        && (filterParams.club == null || _.get(p[statsKey][CLUB], ID) === filterParams.club)
        && (filterParams[COUNTRY] == null || p[COUNTRY] === filterParams[COUNTRY])
    );


    let extraOptions;
    if (type === 'player') {
        extraOptions = {age: {name: 'Age'}, lon: {name: 'Longitude'}, lat: {name: 'Latitude'}}
    } else if (type === 'team') {
        extraOptions = {lon: {name: 'Longitude'}, lat: {name: 'Latitude'}}
    }

    let referenceDate = filterParams.season == null ? filterParams.date : result[STATS][END];
    let availableOptions = {...(type === 'player' ? fields : teamFields), ...extraOptions};

    let scatterData = _.filter(_.map(filteredItems, point => {
        let date = (filterParams[TEAM] != null || filterParams[PLAYERID] != null) ? point[SEASON_SECONDARY][END] : referenceDate;
        return ({
            x: calculateItemValue(point, filterParams.x, date, statsKey, availableOptions),
            y: calculateItemValue(point, filterParams.y, date, statsKey, availableOptions),
            point: point,
            g: type === 'player' ? getZone(point.pos) : (filterParams[TEAM] != null ? point[SEASON_SECONDARY][COMPETITION][NAME] : 0),
            n: (filterParams[TEAM] != null || filterParams[PLAYERID] != null) ? formatCompetitionSeasonName(point[SEASON_SECONDARY]) : (type === 'player' ? displayName(point[NAME], 10) : point.n)
        });
    }), p => p.x != null && p.y != null);

    let groups = _.groupBy(scatterData, 'g');
    let datasets;
    if (type === 'player') {
        datasets = _.map(zonesMedium, (z, i) => {
            let filter = groups[i];
            return ({
                type: 'scatter',
                data: filter,
                color: getZoneColor(i),
                borderColor: getZoneColor(i),
                backgroundColor: rgbaColor(getZoneColor(i), 0.5),
                label: z,
                radius: 6,
                hoverRadius: 8,
                pointStyle: _.map(filter, d => {
                    if (filterParams.icon === 'team') {
                        let imageUrl = getTeamImageUrl(20, _.get(d.point[statsKey][CLUB], ID));
                        if (imageUrl != null) {
                            let image = new Image(20, 20);
                            image.src = imageUrl;
                            return image;
                        } else {
                            return 'circle';
                        }
                    } else if (filterParams.icon === 'country') {
                        let imageUrl = flagUrl(null, d.point[COUNTRY]);
                        if (imageUrl != null) {
                            let image = new Image(28, 20);
                            image.src = imageUrl;
                            return image;
                        } else {
                            return 'circle';
                        }
                    } else {
                        return 'circle';
                    }
                }),
            });
        });
    } else {
        datasets = _.map(_.values(groups), (val, i) => {
            return {
                type: 'scatter',
                data: val,
                color: getChartColor(i),
                borderColor: getChartColor(i),
                backgroundColor: rgbaColor(getChartColor(i), 0.5),
                pointStyle: _.map(scatterData, d => {
                    if (filterParams.icon === 'team') {
                        let imageUrl = getTeamImageUrl(20, d.point[ID]);
                        if (imageUrl != null) {
                            let image = new Image(20, 20);
                            image.src = imageUrl;
                            return image;
                        } else {
                            return 'circle';
                        }
                    } else if (filterParams.icon === 'country') {
                        let imageUrl = flagUrl(null, d.point[COUNTRY]);
                        if (imageUrl != null) {
                            let image = new Image(28, 20);
                            image.src = imageUrl;
                            return image;
                        } else {
                            return 'circle';
                        }
                    } else {
                        return 'circle';
                    }
                }),
                radius: 6,
                hoverRadius: 8,
                label: val[0].g
            };
        })
    }
    let data = {datasets: datasets};

    const options = {
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: function (context) {
                    let value = context.dataset.data[context.dataIndex];
                    return (value != null && value.point != null && selectedItems.includes(value.point[ID])) ? '#ff9900' : context.dataset.color;
                },
                font: {
                    weight: 'bold'
                },
                formatter: function (value) {
                    return value.n;
                },
                listeners: {
                    click: function (context) {
                        let value = context.dataset.data[context.dataIndex];
                        toggleSelectedItems(value.point[ID])
                        return true;
                    }
                },
                offset: 1,
                padding: 0
            },
            tooltip: chartJsDefaultTooltip(),
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy'
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'xy',
                }
            }
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                reverse: availableOptions[filterParams.x].reverse === true,
                title: {
                    display: clientWidth >= breakpoints.md,
                    text: availableOptions[filterParams.x].name
                },
                grid: {
                    color: rgbaColor(getColor('black'), 0.1)
                },
            },
            y: {
                type: 'linear',
                position: 'left',
                reverse: availableOptions[filterParams.y].reverse === true,
                title: {
                    display: clientWidth >= breakpoints.md,
                    text: availableOptions[filterParams.y].name
                },
                grid: {
                    color: rgbaColor(getColor('black'), 0.05)
                },
            }
        }
    };

    let displayItems;
    if (selectedItems.length > 0) {
        displayItems = _.filter(filteredItems, p => selectedItems.includes(p[ID]));
    } else {
        displayItems = filteredItems;
    }

    let bodyViewHeight = clientHeight - 80;
    let sideBarHeight = bodyViewHeight - 80;

    let bar;
    if (filterParams.season != null) {
        bar = <h4>{result[SEASON][COMPETITION][NAME] + " " + formatSeasonName(result[SEASON])}</h4>
    } else if (filterParams.team != null) {
        bar = <></>
    } else if (filterParams[TEAM] != null || filterParams[PLAYERID] != null) {
        bar = <ReactSlider
            value={[isoAsDays(effectiveMinDate), isoAsDays(filterParams.date)]}
            renderTrack={DoubleTrack}
            renderThumb={Thumb}
            min={_.min(_.map(competitors, c => isoAsDays(c[SEASON_SECONDARY][END]))) - 365}
            max={maxDate}
            onAfterChange={(value) => setFilterParams({minDate: daysAsIso(value[0]), date: daysAsIso(value[1])})}
            className="horizontal-slider mt-1 pe-4"
            ariaLabel={['Min Date', 'Max Date']}
            ariaValuetext={state => `Thumb value ${state.valueNow}`}
            pearling
            step={7}
        />
    } else {
        bar = <ReactSlider
            value={isoAsDays(filterParams.date)}
            renderTrack={Track}
            renderThumb={Thumb}
            min={0}
            max={maxDate}
            onAfterChange={(value) => setFilterParams({date: daysAsIso(value)})}
            className="horizontal-slider mt-1 pe-4"
            ariaLabel={"Max Date"}
            ariaValuetext={state => `Thumb value ${state.valueNow}`}
            pearling
            step={7}
        />
    }

    let reel;

    if (type === 'player') {
        let title = <Link to={playerComparisonUrl(_.map(displayItems, p => p[ID]))} size="sm"
                          className="py-1 btn btn-primary btn-sm me-1"><FontAwesomeIcon
            icon={faChartColumn}/> Compare</Link>
        reel = <PlayerList players={displayItems}
                           title={title}
                           size={_.floor(sideBarHeight / PLAYER_CARD_SIZE)}
                           genderToggle={true}
                           statsKey={statsKey}
                           seasonView={filterParams.p != null}
                           referenceDate={referenceDate}
                           ratingScale={(filterParams.only || filterParams.p != null) ? 10 : 100}
                           countryState={[filterParams.c, c => setFilterParams({c: c})]}
                           clubState={[filterParams.club, c => setFilterParams({club: c})]}
                           positionState={[filterParams.pos, c => setFilterParams({pos: c})]}
                           genderState={[filterParams.g, c => setFilterParams({g: c})]}
        />;
    } else {
        let title = <Link to={teamComparisonUrl(_.map(displayItems, p => p.i))} size="sm"
                          className="btn btn-primary btn-sm me-1"><FontAwesomeIcon icon={faChartColumn}/> Compare</Link>
        reel = <TeamRatingTable teams={displayItems}
                                title={title}
                                statsKey={statsKey}
                                seasonView={filterParams.t != null}
                                display={_.floor(sideBarHeight / TABLE_LINE_HEIGHT)}
                                countryState={[filterParams.c, c => setFilterParams({c: c})]}
                                nationalState={[filterParams.o, c => setFilterParams({o: c})]}
                                genderState={[filterParams.g, c => setFilterParams({g: c})]}
        />
    }

    return <Row>
        <Col xl={3} lg={4}>
            {bar}
            {reel}
        </Col>
        <Col xl={9} lg={8}>
            <div style={{height: bodyViewHeight}} className="position-relative w-100 pt-4 pt-lg-0">
                <div className="position-absolute" style={{top: 0, left: 0}}>
                    <span className="me-1">
                        <FieldSelectorDropdown
                            content={<span><FontAwesomeIcon icon={faArrowRight}
                                                            className="me-1"/>{availableOptions[filterParams.x].name}</span>}
                            setField={(x) => setFilterParams({x: x})}
                            availableOptions={availableOptions}/>
                    </span>
                    <span className="me-1">
                        <FieldSelectorDropdown
                            content={<span><FontAwesomeIcon icon={faArrowUp}
                                                            className={"me-1"}/>{availableOptions[filterParams.y].name}</span>}
                            setField={(y) => setFilterParams({y: y})}
                            availableOptions={availableOptions}/>
                    </span>
                    <DefaultDropdown id="rating-settings-dropdown" content={<FontAwesomeIcon icon={faCog}/>}>
                        <Dropdown.Header>Icon Style</Dropdown.Header>
                        <Dropdown.Item onClick={() => setFilterParams({icon: 'team'})}
                                       active={filterParams.icon === 'team'}>
                            <FontAwesomeIcon icon={faUsers}/>
                            <span className="text-dark ms-2">Team</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setFilterParams({icon: 'country'})}
                                       active={filterParams.icon === 'country'}>
                            <FontAwesomeIcon icon={faFlag}/>
                            <span className="text-dark ms-2">Country</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setFilterParams({icon: 'circle'})}
                                       active={filterParams.icon === 'circle'}>
                            <FontAwesomeIcon icon={faCircle}/>
                            <span className="text-dark ms-2">Point</span>
                        </Dropdown.Item>
                    </DefaultDropdown>
                </div>
                <ScatterChart data={data}
                              options={options}
                              availableOptions={availableOptions}
                              onClick={e => {
                                  _.each(e, element => {
                                      let dataset = datasets[element.datasetIndex];
                                      if (dataset != null) {
                                          let datum = dataset.data[element.index];
                                          if (datum != null) {
                                              toggleSelectedItems(datum.point[ID]);
                                              stopPropagation(e);
                                          }
                                      }
                                  })
                              }}/>
            </div>

        </Col>
    </Row>
}

export const ScatterChart = ({data, options, onClick}) => {
    const chartRef = useRef(null);

    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    if (onClick != null) {
        options.onHover = (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        }
    }

    return <>
        <div className="position-absolute" style={{top: 0, right: 0}}>
            <Button variant={"light"} size="sm" onClick={handleResetZoom}><FontAwesomeIcon title="Reset Zoom"
                                                                                           icon={faMagnifyingGlassMinus}/></Button>
        </div>
        <Scatter type="scatter" ref={chartRef} data={data} options={options}
                 onClick={onClick != null ? (e) => onClick(getElementAtEvent(chartRef.current, e)) : null}/>
    </>
}

export const BarChart = ({data, options, onClick}) => {
    const chartRef = useRef(null);

    if (onClick != null) {
        options.onHover = (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        }
    }

    return <Bar type="bar" ref={chartRef} data={data} options={options}
                onClick={onClick != null ? (e) => onClick(getElementAtEvent(chartRef.current, e)) : null}/>
}