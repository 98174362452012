import ReactSlider from "react-slider";
import dayjs from "dayjs";
import {Badge, Button, Card, Col, Row, Table} from "react-bootstrap";
import {hotScale} from "./colorprogress";
import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";
import {getColor, getGrays, isValidNumber, rgbaColor} from "../../helpers/utils";
import {chartJsDefaultTooltip} from "../../helpers/chartjs-utils";
import {Line} from "react-chartjs-2";
import {Link, useParams} from "react-router-dom";
import {useSearchParamsState} from "../../hooks/useSearchParamsState";
import {
    axes,
    calculatePeak,
    calculateStatValue,
    createPlayerChildBars,
    displayName,
    fetchPlayerDetails,
    fieldDisplay,
    fields,
    PlayerSelectorOverlay
} from "./player";
import {
    createTeamChildBars,
    fetchTeamDetails,
    fieldDisplay as teamFieldDisplay,
    fields as teamFields,
    TeamLogo,
    TeamSelectorOverlay
} from "./team";
import Error500 from "../errors/Error500";
import {Loading} from "./spinner";
import {playerProfileUrl, teamProfileUrl} from "../../helpers/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PeakToggle} from "./icons";
import {playerComparisonUrl} from "./playercomparison";
import {teamComparisonUrl} from "./teamcomparison";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {GENDER, ID, NAME, NATIONAL, START, STATS} from "./alias";

export const chartColors = ['#ff241c',
    '#1083e3',
    '#eca426',
    '#9411c0',
    '#0bb600',
    '#d278c5']

export function getChartColor(i) {
    return chartColors[i % chartColors.length];
}

export const Thumb = (props, state, dateOffset, year, maxValue = 45) => {
    let value;
    if (dateOffset != null) {
        if (year) {
            value = dayjs(dateOffset).add(state.valueNow * daysInYear, 'day').format('MMM YY');
        } else {
            value = dayjs(dateOffset).add(state.valueNow, 'day').format('MMM YY');
        }
    } else {
        value = state.valueNow.toFixed(2);
    }
    return <Badge bg="custom"
                  {...props}
                  className="px-0 text-center"
                  style={{
                      ...props.style,
                      backgroundColor: hotScale(state.valueNow / maxValue),
                      cursor: 'grab',
                      width: 40
                  }}>
        {value}
    </Badge>;
}

export const Track = (props, state) => {
    let style = {
        ...props.style,
        color: '#fff',
        top: 0,
        bottom: 0,
        margin: "auto",
        height: 8,
        borderRadius: 999,
    };
    if (state.index === 0) {
        style['backgroundColor'] = hotScale(state.value / 45.0);
        style['backgroundImage'] = 'linear-gradient(to right, ' + hotScale(14 / 50.0) + ',' + hotScale(state.value / 45.0) + ')';
    } else {
        style['backgroundColor'] = '#333';
    }
    return <div {...props} style={style}/>
}

const daysInYear = 365.242194416666;

export function addAge(date, age) {
    return dayjs(date).add(age * daysInYear, 'day');
}

export function yearDiff(startDate, endDate) {
    return dayjs(endDate).diff(startDate, 'year', true);
}

export function createRangeBars(clubRanges, calcValue, minValue, maxValue, childRender, itemWidth = 32, itemHeight = 32) {
    return _.map(clubRanges, (range, i) => {
        let start = calcValue(range.min);
        let end = calcValue(range.max);
        let lengthScale = (maxValue - minValue) / 100.0;
        return <div key={i} className={"bg-light"} style={{
            position: 'absolute',
            left: ((start - minValue) / lengthScale) + '%',
            border: '1px solid ' + getGrays()['600'],
            height: "100%",
            width: ((end - start) / lengthScale) + '%'
        }}>
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: "50%",
                    marginLeft: -itemWidth / 2,
                    marginTop: -itemHeight / 2,
                    zIndex: 1
                }}>
                {childRender(range)}
            </div>
        </div>
    });
}

export function mapXValues(data, xValueFn) {
    return _.map(data, d => ({x: xValueFn(d.x), y: d.y}));
}

export function calcPlotData(dates, stats, field, fieldConfig, ratePeriods = 52) {
    let isRate = fieldConfig.sum;
    let fn = fieldConfig.fn;
    let denomfn = fieldConfig.denomfn;
    return _.filter(_.map(dates, (k, i) => {
        let stat = stats[k];
        let val = fn != null ? fn(stat) : stat[field];
        let denom = null;
        if (denomfn != null) {
            denom = denomfn(stat);
            val *= denom;
        }
        let num = null;
        if (isRate) {
            let otherStat = stats[dates[Math.max(i - ratePeriods, 0)]];
            if (otherStat != null) {
                let otherVal = (fn != null ? fn(otherStat) : otherStat[field])
                if (denom != null) {
                    let otherDenom = denomfn(otherStat);
                    otherVal *= otherDenom;
                    denom -= otherDenom;
                    if (fieldConfig.denommin != null && denom < fieldConfig.denommin) {
                        denom = fieldConfig.denommin;
                    }
                }
                num = val - otherVal;
            }
        } else {
            num = val;
        }
        if (denom != null) {
            return {x: k, y: num / denom};
        } else {
            return {x: k, y: num};
        }
    }), p => isValidNumber(p.y));
}

export function calcRanges(dates, stats, key, keyFunction) {
    let ranges = [];
    if (keyFunction == null) {
        keyFunction = x => x[key];
    }
    _.each(dates, d => {
        let id = _.get(keyFunction(stats[d]), ID);
        if (ranges.length === 0 || (_.get(ranges[ranges.length - 1][key], ID) !== id && id != null)) {
            if (ranges.length > 0) {
                ranges[ranges.length - 1].max = d;
            }
            ranges.push({[key]: keyFunction(stats[d]), min: d, max: d});
        } else {
            ranges[ranges.length - 1].max = d;
        }
    })
    return ranges;
}

export function calcGaps(dates, stats, occKey, minDays) {
    let last = 0;
    let currentIsGap = false;
    let gaps = [];
    _.each(dates, d => {
        let occ = _.get(stats[d], occKey);
        if (occ <= last) {
            if (currentIsGap) {
                gaps[gaps.length - 1].max = d;
            } else {
                currentIsGap = true;
                gaps.push({min: d, max: d});
            }
        } else {
            currentIsGap = false;
        }
        last = occ;
    })
    return _.filter(gaps, g => dayjs(g.max).diff(g.min, 'day') >= minDays);
}

export function toGapData(gaps) {
    let gapData = [];
    _.each(gaps, g => {
        gapData.push({x: dayjs(g.min).add(-1, 'hour').valueOf(), y: -1});
        gapData.push({x: g.min, y: 1});
        gapData.push({x: g.max, y: 1});
        gapData.push({x: dayjs(g.max).add(1, 'hour').valueOf(), y: -1});
    })
    return gapData;
}

export const LineChart = ({data, options}) => {
    const chartRef = useRef(null);

    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    return <div className="position-relative w-100 h-100">
        <Button variant={"light"} size="sm" className="position-absolute" style={{top: 0, right: 0}}
                onClick={handleResetZoom}><FontAwesomeIcon icon={faMagnifyingGlassMinus}/></Button>
        <Line type="line" ref={chartRef} data={data} options={options}/>
    </div>
}

export function chartOptions(axes, ageAxis, min, max) {
    let options = {
        plugins: {
            datalabels: false,
            tooltip: chartJsDefaultTooltip(),
            legend: {
                labels: {
                    filter: item => item.text != null
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                }
            }
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    color: rgbaColor(getColor('black'), 0.1)
                },
                type: ageAxis ? 'linear' : 'time',
                time: {
                    tooltipFormat: 'DD MMM YYYY'
                },
                min: min,
                max: max,
                ticks: {
                    padding: 3
                },
                afterFit: (axis) => {
                    axis.paddingRight = 0;
                }
            }
        }
    };

    for (let i = 0; i < axes.length; i++) {
        let axis = axes[i];
        let axisName = axis.name;
        let ops = {
            min: axis.min,
            max: axis.max,
            reverse: axis.reverse === true,
            ticks: {
                padding: 0
            },
            type: axis.log ? 'logarithmic' : 'linear'
        }
        if (axis.hide) {
            ops['grid'] = {
                display: false
            }
            ops['ticks'] = {
                display: false
            }
            ops['gridLines'] = {
                drawBorder: false
            }
            ops.position = 'right';
        } else {
            ops['grid'] = {
                color: rgbaColor(getColor('black'), 0.1),
                drawBorder: true
            }
            ops.position = i % 2 === 1 ? 'right' : 'left';
        }
        options.scales[axisName] = ops;
    }
    return options;
}

export function Comparison({type}) {
    const {ids} = useParams()
    const [error, setError] = useState(null);
    const [items, setItems] = useState(null);
    let idsAsArray = _.uniq(_.split(ids, "-")).slice(0, chartColors.length);
    const [filterParams, setFilterParams] = useSearchParamsState({
        age: {type: 'boolean', default: false},
        field: {type: 'string', default: 'r'}
    })
    let field = filterParams.field;

    useEffect(() => {
        Promise.all(_.concat(
            _.map(idsAsArray, id => type === 'player' ? fetchPlayerDetails(id) : fetchTeamDetails(id))))
            .then(
                (result) => {
                    document.title = _.join(_.map(result, r => r[NAME]), ' vs ') + ' Comparison - Football Ratings';
                    setItems(result)
                },
                (error) => {
                    setError(error);
                }
            );
    }, []);

    if (error) {
        return <Error500/>;
    } else if (items == null) {
        return <Loading/>;
    } else {
        let itemFields = type === 'player' ? fields : teamFields;
        let rangeKey = type === 'player' ? 'cl' : 'c';
        let fieldConfig = itemFields[field];

        let itemData = _.map(items, (item, i) => {
            let stats = item[STATS];
            let dob = type === 'player' ? dayjs(item.dob) : dayjs(item[START] + '-01-01');

            let dates = _.sortBy(_.keys(stats));
            if (dob.isAfter(dayjs().add(-5, 'year'))) {
                if (dates.length > 0) {
                    dob = dayjs(dates[0]).add(-16, 'year');
                } else {
                    dob = dayjs().add(-25, 'year');
                }
            }

            let ranges = calcRanges(dates, stats, rangeKey);
            let age = yearDiff(dob, dayjs());
            let minAge, maxAge = age;
            if (dates.length > 0) {
                minAge = yearDiff(dob, dates[0]);
                maxAge = yearDiff(dob, dates[dates.length - 1]);
            } else {
                minAge = 16;
            }

            let xValueFn = filterParams.age ? k => yearDiff(dob, k) : k => dayjs(k).valueOf();
            let plotData = calcPlotData(dates, stats, field, itemFields[field]);
            let gaps = calcGaps(dates, stats, 'oc', 120);
            let gapData = toGapData(gaps);

            const dataset = {
                type: 'line',
                label: item.n,
                borderColor: getChartColor(i),
                backgroundColor: rgbaColor(getColor('gray-100'), 0),
                borderWidth: 2,
                fill: false,
                pointStyle: false,
                yAxisID: fieldConfig.axis,
                data: mapXValues(plotData, xValueFn),
                tension: 0
            };

            const absentDataset = {
                type: 'line',
                borderColor: getChartColor(i),
                backgroundColor: rgbaColor(getChartColor(i), 0.2),
                borderWidth: 0,
                fill: true,
                data: mapXValues(gapData, xValueFn),
                yAxisID: 'absent',
                pointStyle: false,
                tension: 0
            }

            return {
                dataset,
                absentDataset,
                dates,
                stats,
                ranges,
                dob,
                minAge,
                maxAge,
                item,
                startDate: dates[0],
                maxDate: dates[dates.length - 1]
            }
        })

        let minAge = _.min(_.map(itemData, p => p.minAge));
        let maxAge = _.max(_.map(itemData, p => p.maxAge));
        let minDate = _.min(_.map(itemData, p => p.startDate));
        let maxDate = _.max(_.map(itemData, p => p.maxDate));
        let minDob = _.min(_.map(itemData, p => p.dob));

        let datasets = _.concat(_.map(itemData, p => p.dataset), _.map(itemData, p => p.absentDataset));
        let selectedAxes = _.map(_.uniq(_.map(datasets, d => d.yAxisID)), k => ({name: k, ...axes[k]}));
        const options = chartOptions(selectedAxes, filterParams.age, null, null);

        let data = {datasets: datasets};

        let params = {
            ids,
            items,
            idsAsArray,
            itemData,
            filterParams,
            setFilterParams,
            minAge,
            maxAge,
            data,
            options,
            itemFields,
            type,
            minDob,
            minDate,
            maxDate
        };
        return <ComparisonRender {...params}/>
    }
}

const ComparisonRender = ({
                              ids,
                              items,
                              idsAsArray,
                              itemData,
                              filterParams,
                              setFilterParams,
                              minAge,
                              maxAge,
                              data,
                              options,
                              itemFields,
                              type,
                              minDob,
                              minDate,
                              maxDate
                          }) => {
    let itemFieldDisplay = type === 'player' ? fieldDisplay : teamFieldDisplay;
    const [selectedMaxAge, setSelectedMaxAge] = useState(null);
    const [showPeak, setShowPeak] = useState(0);

    const itemTargetUrl = (item) => {
        if (idsAsArray.length === 1) {
            return type === 'player' ? playerProfileUrl(item[ID]) : teamProfileUrl(item[ID]);
        } else {
            let newIds = _.filter(idsAsArray, x => x !== item[ID]);
            return type === 'player' ? playerComparisonUrl(newIds) : teamComparisonUrl(newIds);
        }
    }

    let appliedMaxAge = selectedMaxAge || maxAge;

    _.each(itemData, p => {
        if (p.dates.length > 0) {
            if (selectedMaxAge != null) {
                let maxSelectedDate = addAge(filterParams.age ? p.dob : minDob, appliedMaxAge).format('YYYY-MM-DD');
                let index = Math.min(Math.max(_.sortedIndex(p.dates, maxSelectedDate), 0), p.dates.length - 1);
                p.latestStats = p.stats[p.dates[index]] || {};
            } else {
                p.latestStats = p.stats[p.dates[p.dates.length - 1]] || {};
            }
        } else {
            p.latestStats = {};
        }
    })

    let membership = _.map(itemData, (data, i) => {
        let startDate;
        let minValue;
        let maxValue;
        if (filterParams.age) {
            startDate = data.dob;
            minValue = minAge;
            maxValue = maxAge;
        } else {
            startDate = minDob;
            minValue = yearDiff(minDob, minDate);
            maxValue = yearDiff(minDob, maxDate);
        }
        let rangeBars = createRangeBars(data.ranges, k => yearDiff(startDate, k), minValue, maxValue, type === 'player' ? createPlayerChildBars : createTeamChildBars)
        return <div key={data.item.i} className="d-flex d-flex-row">
            <Link to={itemTargetUrl(data.item)} className="em rounded-2"
                  style={{
                      width: 40,
                      color: '#fff',
                      fontSize: 8,
                      padding: 0,
                      marginRight: 5,
                      backgroundColor: getChartColor(i)
                  }}>
                {type === 'team' ? <TeamLogo {...data.item} size={32}/> : displayName(data.item[NAME], 12, false)}
            </Link>
            <div className="flex-grow-1">
                <div style={{position: 'relative', width: '100%', height: 40}}>
                    {rangeBars}
                </div>
            </div>
        </div>
    })

    let statRows = _.map(_.filter(itemFieldDisplay, field => itemFields[field].iconfn == null), (field) => {
        let config = itemFields[field];
        let icon = config.icon != null ? config.icon :
            <FontAwesomeIcon size="1x" fixedWidth className="em3" {...config.faIcon}/>
        let values = _.map(itemData, d => {
            return showPeak ? _.get(calculatePeak(d.stats, field, itemFields), 'value') : calculateStatValue(d.latestStats, field, itemFields);
        });
        let bestValue = (config.reverse || config.lowerIsBetter) ? _.min(values) : _.max(values);
        let statRows = _.map(itemData, (d, i) => {
            let value = values[i];
            let maxDp = config.dp != null ? config.dp : 2;
            return <td width={80} key={items[i][ID]}
                       className={classNames("pe-1 me-0", value === bestValue ? 'fw-bold' : '')}
                       style={{
                           textAlign: 'right',
                           color: value === bestValue ? '#ff9900' : null
                       }}>{!Number.isFinite(value) ? '-' : _.round(value, maxDp)}</td>
        })
        return <tr key={field} className="pe-1 cursor-pointer" title={config.tooltip}
                   onClick={() => setFilterParams({field: field})}>
            <th key="icon" className="text-nowrap ps-2">
                {icon} {config.name}
            </th>
            {statRows}
        </tr>
    })

    let statHeaderIcons = _.map(items, (item, i) => {
        return <th key={item[ID]} className="pe-0 ps-0">
            <Link className="em m-0 rounded-3" to={itemTargetUrl(item)}
                  style={{
                      color: '#fff',
                      width: 80,
                      height: type === 'team' ? 80 : 40,
                      fontSize: 12,
                      backgroundColor: getChartColor(i),
                  }}>
                {type === 'team' ? <TeamLogo {...item} size={80}/> : displayName(item[NAME], 15, false)}
            </Link>
        </th>
    })

    return (
        <Row>
            <Col>
                <Card className="mb-3">
                    <Card.Header className="p-2 bg-light text-dark">
                        <Card.Title>
                            {type === 'player' ? 'Player' : 'Team'} Comparison
                            <div className="float-end">
                                    <span className="mx-1">
                                        <PeakToggle selected={showPeak} onChange={setShowPeak}/>
                                    </span>
                                {type === 'player' ?
                                    <PlayerSelectorOverlay gender={items[0][GENDER]} id={ids}/> :
                                    <TeamSelectorOverlay gender={items[0][GENDER]} national={items[0][NATIONAL]}
                                                         id={ids}/>}
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <div style={{maxHeight: '80vh', overflow: 'auto'}}>
                        <Table variant="light" striped bordered hover size="sm">
                            <thead>
                            <tr>
                                <th key={"blank"}/>
                                {statHeaderIcons}
                            </tr>
                            </thead>
                            <tbody>
                            {statRows}
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </Col>
            <Col md={12} xl={6} xxl={7}>
                <Row>
                    <Col className="pe-3">
                        <div className="d-flex d-flex-row">
                            <Button style={{width: 40, marginRight: 5}} size="sm"
                                    variant={filterParams.age ? "primary" : "light"}
                                    className="px-0"
                                    onClick={() => setFilterParams({age: filterParams.age ? 0 : 1})}>Age</Button>
                            <div className="flex-grow-1">
                                <ReactSlider
                                    value={appliedMaxAge}
                                    renderTrack={Track}
                                    renderThumb={(props, state) => Thumb(props, state, filterParams.age ? null : minDob, true)}
                                    min={minAge}
                                    max={maxAge}
                                    onChange={(value) => setSelectedMaxAge(value)}
                                    className="horizontal-slider"
                                    ariaLabel={['Min Strength', 'Max Strength']}
                                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                    pearling
                                    step={0.02}
                                />
                            </div>
                        </div>
                        <div className="position-relative">
                            {membership}
                            <div className="position-absolute" style={{left: 40, top: 0, right: 0, bottom: 0}}>
                                <div style={{
                                    position: 'absolute',
                                    height: '100%',
                                    borderLeft: '2px solid #ff9900',
                                    backgroundColor: rgbaColor(getColor('white'), 0.5),
                                    width: (100 - (appliedMaxAge - minAge) / (maxAge - minAge) * 100) + '%',
                                    top: 0,
                                    right: 0,
                                    overflowX: 'hidden',
                                    zIndex: 20,
                                }}/>
                            </div>
                        </div>
                        <div className="w-100" style={{height: 500}}>
                            <LineChart data={data} options={options}/>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
