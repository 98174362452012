import React, {useEffect, useState} from 'react';
import {Badge, Dropdown, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import {displayName} from "../../custom/player";
import {TeamLogo} from "../../custom/team";
import {Loading} from "../../custom/spinner";
import {faMoon, faSearch, faSun} from "@fortawesome/free-solid-svg-icons";
import {search} from "../../../helpers/searchIndex";
import {Flag} from "../../custom/flag";
import {hotScale} from "../../custom/colorprogress";
import {formatFixed} from "../../../helpers/numberutils";
import {Gender} from "../../custom/icons";
import {PositionZone} from "../../custom/position";
import _ from "lodash"
import {playerProfileUrl, teamProfileUrl} from "../../../helpers/urls";
import {ACTIVE, COUNTRY, GENDER, ID, NAME, TEAM, WEIGHT, ZONE} from "../../custom/alias";

const PlayerSearchContent = ({item}) => {
    return <Dropdown.Item className="p-2" as={Link} to={playerProfileUrl(item[ID])}>
        <Flex alignItems="center">
            <span className="p-1">
                <TeamLogo size={32} i={item[TEAM]}/>
            </span>
            <div className="ms-2">
                <h6 className="mb-0">{displayName(item[NAME], 25)}</h6>
            </div>
            <Gender gender={item[GENDER]}/>
            <div className="text-end flex-grow-1">
                <span className="me-1">
                    {item[ACTIVE] ?
                        <FontAwesomeIcon title={"Active"} icon={faSun} size="sm" fixedWidth color={"#ff9900"}/> :
                        <FontAwesomeIcon title={"Retired"} icon={faMoon} size="sm" color={"#999999"} fixedWidth/>}
                </span>
                <PositionZone id={item[ZONE]}/>
                <span className="ms-1">
                    <Flag id={item[COUNTRY]} size="1x"/>
                </span>
                <Badge className="em2 px-0 ms-1 my-auto text-center" bg="custom" title="Peak Rating"
                       style={{backgroundColor: hotScale(item[WEIGHT] / 100)}}>{formatFixed(item[WEIGHT], 0)}</Badge>
            </div>
        </Flex>
    </Dropdown.Item>
}

const TeamSearchContent = ({item}) => {
    return <Dropdown.Item className="p-2" as={Link} to={teamProfileUrl(item[ID])}>
        <Flex alignItems="center">
            <span className="p-1">
                <TeamLogo size={32} {...item}/>
            </span>
            <div className="ms-2">
                <h6 className="mb-0">{item[NAME]}</h6>
            </div>
            <Gender gender={item[GENDER]}/>
            <div className="text-end flex-grow-1">
                <Flag id={item[COUNTRY]} size="1x"/>
                <Badge className="em2 px-0 ms-1 my-auto text-center" bg="custom" title="Peak Rating"
                       style={{backgroundColor: hotScale(item[WEIGHT] / 100)}}>{formatFixed(item[WEIGHT], 0)}</Badge>
            </div>
        </Flex>
    </Dropdown.Item>
}

const TypedSearchContent = ({item}) => {
    if (item.type === 'player') {
        return <PlayerSearchContent item={item}/>
    } else if (item.type === 'team') {
        return <TeamSearchContent item={item}/>
    } else {
        return <></>
    }
}

const RenderSearchItems = ({searchInputValue, setDropdownOpen}) => {
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        search(searchInputValue, 100).then(results => {
            setSearchResults(_.map(results, item => item.item));
            if (searchInputValue) {
                setDropdownOpen(true);
            }
        })
    }, [searchInputValue]);

    return <div className="scrollbar py-3" style={{maxHeight: '24rem'}}>
        {searchResults.map((item, id) => (
            <TypedSearchContent key={id} item={item}/>
        ))}
        {searchResults.length === 0 && (
            <p className="fs-1 fw-bold text-center mb-0">No Results Found.</p>
        )}
    </div>;
}

const SearchBox = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');

    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <Dropdown onToggle={toggle} className="search-box">
            <Dropdown.Toggle
                id="search-box-dropdown-toggle"
                as="div"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                bsPrefix="toggle"
            >
                <Form className="position-relative">
                    <Form.Control
                        id="site-search"
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        className="rounded-pill search-input"
                        value={searchInputValue}
                        onChange={({target}) => setSearchInputValue(target.value)}
                    />
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="position-absolute text-400 search-box-icon"
                    />
                    {searchInputValue && (
                        <div className="search-box-close-btn-container">
                            <FalconCloseButton
                                size="sm"
                                noOutline
                                onClick={() => setSearchInputValue('')}
                            />
                        </div>
                    )}
                </Form>
            </Dropdown.Toggle>
            <Dropdown.Menu show={dropdownOpen}>
                {dropdownOpen ?
                    <RenderSearchItems searchInputValue={searchInputValue} setDropdownOpen={setDropdownOpen}/> :
                    <Loading/>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SearchBox;
