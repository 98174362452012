import React from 'react';
import {Card} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFutbol, faHome} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

const Error500 = () => (
    <Card className="text-center h-100">
        <Card.Body className="p-5">
            <FontAwesomeIcon icon={faFutbol} className="text-primary" size="lg"/>
            <span className={classNames('font-sans-serif', 'text-primary', 'ms-1')}> FootballRatings</span>
            <div className="display-1 text-300 fs-error">500</div>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Whoops, something went wrong!
            </p>
            <hr/>
            <p>
                Try refreshing the page, or going back and attempting the action again.
            </p>
            <a className="btn btn-primary btn-sm mt-3" href="/">
                <FontAwesomeIcon icon={faHome} className="me-2"/>
                Take me home
            </a>
        </Card.Body>
    </Card>
);

export default Error500;
