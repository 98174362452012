import React from 'react';
import dayjs from "dayjs";

const Footer = () => (
    <footer className="footer position-relative">
        <p className="mb-0 text-600 text-center fs--1 mt-4 mb-3">
            Football player ratings, team ratings and match performance ratings for over 3 million matches, 1
            million players and 50,000 teams.
            <br/>
            Live scores and ratings from top matches including England Premier League, France Ligue 1, Spain La Liga, German Bundesliga,
            Champions League.
            <br/>
            Over 100 years of football history covering all major men's and women's football competitions.
            <br/>
            Copyright © <span>{dayjs().format('YYYY')}</span> FootballRatings
        </p>
    </footer>
);

export default Footer;
