import React, {createContext, useContext, useState} from 'react';
import {getCookieConsentValue} from "react-cookie-consent";

export const isCookieConsented = () => !((/false/i).test(getCookieConsentValue()));

export const CookieConsentContext = createContext(null);

export const CookieConsentProvider = ({children}) => {
    const [cookieConsent, setCookieConsent] = useState(isCookieConsented());
    return (
        <CookieConsentContext.Provider value={{cookieConsent, setCookieConsent}}>
            {children}
        </CookieConsentContext.Provider>
    );
};

export const useCookieConsent = () => useContext(CookieConsentContext);