import React, {useContext, useEffect, useState} from 'react';

export function computeWindowHeight() {
    return Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
    );
}

export function computeWindowWidth() {
    return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
    );
}

export const WindowContext = React.createContext(null);

export const WindowContextProvider = ({children}) => {
    const [clientDimensions, setClientDimensions] = useState({
        clientHeight: computeWindowHeight(),
        clientWidth: computeWindowWidth()
    });
    useEffect(() => {
        const handleResize = () => {
            setClientDimensions({clientHeight: computeWindowHeight(), clientWidth: computeWindowWidth()});
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <WindowContext.Provider value={clientDimensions}>
            {children}
        </WindowContext.Provider>
    );
};

export const useWindowContext = () => useContext(WindowContext);