import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import React, {useContext} from 'react';
import {Nav, NavDropdown} from 'react-bootstrap';
import {faCog, faMoon, faSearch, faSun} from "@fortawesome/free-solid-svg-icons";

const TopNavRightSideNavItem = ({toggleShowSmallSearch}) => {
    const {
        config: {isDark},
        setConfig
    } = useContext(AppContext);
    return (
        <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul">
            <Nav.Item as={'li'}>
                <Nav.Link className="me-1 p-0 theme-control-toggle d-md-none" onClick={() => toggleShowSmallSearch()}>
                    <div className="theme-control-toggle-label">
                        <FontAwesomeIcon icon={faSearch} size="1x"/>
                    </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as={'li'}>
                <NavDropdown title={<div className="theme-control-toggle-label" title="Website Settings">
                    <FontAwesomeIcon icon={faCog}/>
                </div>} align="end" className="dropdown-hide theme-control-toggle m-0 p-0">
                    <NavDropdown.Item key="light" onClick={() => setConfig('isDark', false)} active={!isDark}>
                        <FontAwesomeIcon icon={faSun}/>
                        <span className="text-dark ms-2">Light Theme</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item key="dark" onClick={() => setConfig('isDark', true)} active={isDark}>
                        <FontAwesomeIcon icon={faMoon}/>
                        <span className="text-dark ms-2">Dark Theme</span>
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav.Item>
        </Nav>
    );
};

export default TopNavRightSideNavItem;
