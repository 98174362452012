import _ from "lodash";
import {settings} from "../config";
import fuzzysort from 'fuzzysort'
import {NAME, WEIGHT} from "../components/custom/alias";

function removeAccents(str) {
    if (str == null) {
        return str;
    } else {
        return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replace(/[ø]/g, "o")
            .replace(/[Ø]/g, "O")
            .replace(/[æ]/g, "ae")
            .replace(/[Æ]/g, "AE")
            .replace(/[ə]/g, "e")
            .replace(/[Œ]/g, "OE")
            .replace(/[œ]/g, "oe")
            .replace(/[ß]/g, "ss")
            .replace(/[§]/g, "s")
            .replace(/[łı]/g, "l")
            .replace(/[đ]/g, "d")
            .replace(/[Đ]/g, "D")
            .replace(/[þ]/g, "p");
    }
}

const buildSearchObject = (sortedItems, keys) => {
    let targets = sortedItems.map(t => ({s: fuzzysort.prepare(removeAccents(t[NAME])), item: t}));
    const search = (searchInputValue, limit = 100) => {
        if (searchInputValue) {
            let searchResult = fuzzysort.go(removeAccents(searchInputValue), targets, {
                threshold: -Infinity,
                limit: limit * 2,
                all: false,
                keys: 's'
            })
            let results = _.map(searchResult, (item, i) => ({item: item.obj.item, score: i}));
            return _.sortBy(results, r => r.score + 0.5 * (100 - r.item[WEIGHT])).slice(0, limit);
        } else {
            return _.map(sortedItems.slice(0, limit), o => ({item: o, score: 0}));
        }
    }

    const filteredCopy = (filter) => {
        let items = _.filter(sortedItems, filter);
        return buildSearchObject(items, keys);
    }

    return {
        search: search,
        filteredCopy: filteredCopy
    }
}

const searchIndexHolder = (function(){
    let promise = null;
    function createSearchIndex() {
        return fetch(settings.apiServer + "index/search.json")
            .then(res => res.json())
            .then(items => {
                let {players, teams} = items;
                let options = _.filter(_.concat(
                    _.map(players, x => ({type: 'player', ...x})),
                    _.map(teams, x => ({type: 'team', ...x}))), x => x[NAME] != null);
                let sorted = _.orderBy(options, WEIGHT, 'desc');
                return buildSearchObject(sorted, [NAME]);
            })
    }
    return {
        get: function() {
            if (promise == null) {
                promise = createSearchIndex();
            }
            return promise;
        }
    }
})();

export function filteredSearch(filter) {
    return new Promise((resolve) => {
        searchIndexHolder.get().then(se => resolve(se.filteredCopy(filter)));
    })
}

export function search(searchInputValue, limit = 100) {
    return new Promise((resolve) => {
        searchIndexHolder.get().then(se => resolve(se.search(searchInputValue, limit)));
    })
}