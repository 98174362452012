import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {useCookieConsent} from "./hooks/useCookieConsent";
import {setAnalyticsCollectionEnabled} from "@firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBTgeIumqam_hVz3qwDYErBVIsXh5c1bSc",
    authDomain: "football-ratings-f4aa2.firebaseapp.com",
    projectId: "football-ratings",
    storageBucket: "football-ratings.appspot.com",
    messagingSenderId: "719752521306",
    appId: "1:719752521306:web:576ea038101e99f9c55a63",
    measurementId: "G-1FKCL3TMP1"
};

const isProduction = () => document.location.hostname.search("footballratings.com") !== -1;
const app = isProduction() ? initializeApp(firebaseConfig) : null;

export function useFirebaseRoutesAnalytics() {
    if (isProduction()) {
        const location = useLocation();
        const analytics = getAnalytics(app);
        const {cookieConsent} = useCookieConsent();
        setAnalyticsCollectionEnabled(analytics, cookieConsent);

        useEffect(() => {
            setAnalyticsCollectionEnabled(analytics, cookieConsent);
            if (cookieConsent) {
                logEvent(analytics, 'page_view', {
                    page_title: document.title,
                    page_location: document.location.href,
                    page_path: location.pathname
                });
            }
        }, [location, cookieConsent]);
        return analytics;
    }
}