import React from 'react';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import Layout from './layouts/Layout';
import {WindowContextProvider} from "./hooks/useWindowContext";
import {ErrorBoundary} from "react-error-boundary";
import Error500 from "./components/errors/Error500";
import MediaViewerProvider from "./hooks/useMediaViewer";
import {MediaPlayer} from "./components/custom/video";
import {useFirebaseRoutesAnalytics} from "./firebase";
import {logEvent} from "firebase/analytics";
import {CookieConsentProvider} from "./hooks/useCookieConsent";

import 'react-toastify/dist/ReactToastify.min.css';
import {Chart as ChartJS, registerables} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);
ChartJS.register(ChartDataLabels);

const App = () => {
    return (
        <Router>
            <CookieConsentProvider>
                <RootApp/>
            </CookieConsentProvider>
        </Router>
    );
};

const RootApp = () => {
    const location = useLocation();
    const analytics = useFirebaseRoutesAnalytics();
    return <ErrorBoundary key={location.pathname} FallbackComponent={Error500} onError={(error) => {
        logEvent(analytics, 'exception', {description: error});
    }}>
        <MediaViewerProvider>
            <WindowContextProvider>
                <MediaPlayer/>
                <Layout/>
            </WindowContextProvider>
        </MediaViewerProvider>
    </ErrorBoundary>
}

export default App;
