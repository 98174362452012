import chroma from "chroma-js";
import {Badge, ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React from 'react';
import {getColor, isValidNumber} from "../../helpers/utils";
import classNames from "classnames";
import {formatFixed} from "../../helpers/numberutils";

export const hotScale = chroma.scale(['#516511', '#296b20', '#22653a', '#187368',
    '#127394', '#0059ff', '#3d12ee', '#ad169a',
    '#da1515']);

export const VerticalProgressBar = ({
                                        min,
                                        max,
                                        now,
                                        barWidth = 8,
                                        bottomColor = getColor('secondary'),
                                        topColor = getColor('primary'),
                                        title="Team Rating Balance"
                                    }) => {
    let height = (now - min) / (max - min) * 100;
    return <div className="progress rounded-0" style={{width: barWidth, height: '100%', backgroundColor: bottomColor}}>
        <div className="progress-bar rounded-0" role="progressbar" aria-valuenow={now} aria-valuemin={min}
             aria-valuemax={max} title={title}
             style={{width: barWidth, height: height + '%', backgroundColor: topColor}}>
        </div>
    </div>
}

export const RatingBadge = ({value, maxValue = 100, dp = 1, className = "em3"}) => {
    return <Badge className={className} bg="custom" style={{backgroundColor: hotScale(value / maxValue)}}>
        {formatFixed(value, dp)}
    </Badge>
}

export const ColorScaleProgressBar = ({
                                          name,
                                          value,
                                          min,
                                          max,
                                          icon,
                                          faIcon,
                                          maxDp = 2,
                                          tooltip,
                                          onClick,
                                          selected = false,
                                          showEmpty = false,
                                          small = false,
                                      }) => {
    if (!showEmpty && !isValidNumber(value)) {
        return <></>
    }
    let fraction = Math.min(Math.max((value - min) / (max - min), 0), 1);
    let iconRender = (faIcon != null) ? <FontAwesomeIcon size="1x" fixedWidth className="em2" {...faIcon}/> : icon;
    let number = _.round(value, maxDp);

    return <ListGroup.Item action={onClick != null} onClick={onClick} active={selected}
                           className={classNames("py-0", small ? "border-0 bg-transparent" : "")}>
        <OverlayTrigger
            defaultShow={false}
            delay={{hide: 0, show: 1000}}
            overlay={(props) => <Tooltip {...props}>{tooltip || name}</Tooltip>}
            placement="bottom"
        >
            <div className="d-flex flex-row">
                <div style={{flex: '0 1 ' + (small ? '20px' : '240px')}}>
                    <div>
                        {iconRender}
                        <span className={classNames(small ? "d-none" : "")}>{name}</span>
                        {small ? <></> : <span className="float-end">{number}</span>}
                    </div>
                </div>
                <div className="flex-grow-1">
                    <div className="progress shadow-none rounded-3 ms-2 my-1">
                        <div role="progressbar"
                             style={{backgroundColor: hotScale(fraction), width: _.round(fraction * 100, 2) + "%"}}
                             className="progress-bar" aria-valuenow={value} aria-valuemin={min} aria-valuemax={max} title={name}>
                            {(small && fraction >= 0.2) ? <span className={"d-inline"}>{number}</span> : <></>}
                        </div>
                        {(small && fraction < 0.2) ? <span className={"d-inline"}>{number}</span> : <></>}
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    </ListGroup.Item>
}