import {countries} from "data/lookup"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobe, faGlobeAfrica, faGlobeAmericas, faGlobeAsia, faGlobeOceania} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import {settings} from "../../config";
import React from 'react';
import {FixedSizeList as List} from "react-window";
import {Badge} from "react-bootstrap";
import {hotScale} from "./colorprogress";
import {Loading} from "./spinner";
import {formatFixed} from "../../helpers/numberutils";
import {ID, NAME} from "./alias";

export function flagUrl(code, countryId) {
    if (code == null) {
        let country = countries[countryId];
        if (country != null) {
            code = getCode(country, 'un');
        }
    }
    if (code == null) {
        return null;
    }
    return settings.imgServer + "flags/" + code.toLowerCase() + ".svg";
}

function getCode(country, defaultCode='INT') {
    let code = _.get(country, 'i2') || _.get(country, 'cd') || defaultCode;
    if (code === 'E') {
        code = "GB-ENG";
    } else if (code === 'S') {
        code = "GB-SCT";
    } else if (code === 'NIR') {
        code = "GB-NIR";
    } else if (code === 'WAL') {
        code = "GB-WLS";
    } else if (code === 'EUR') {
        code = "EU";
    }
    return code;
}

export const Flag = ({id, size = "lg", placeholder=false}) => {
    let country = countries[id];
    let name = _.get(country, NAME, 'World');
    if (placeholder) {
        return <span>{name}</span>
    }
    let code = getCode(country);
    if (code === 'NAM') {
        return <FontAwesomeIcon icon={faGlobeAmericas} size={size} title={name} fixedWidth style={{color: '#00ecff'}}/>;
    } else if (code === 'SAM') {
        return <FontAwesomeIcon icon={faGlobeAmericas} size={size} title={name} fixedWidth style={{color: '#00ecff'}}/>;
    } else if (code === 'INT') {
        return <FontAwesomeIcon icon={faGlobe} size={size} title={name} fixedWidth style={{color: '#ffc400'}}/>;
    } else if (code === 'AFR') {
        return <FontAwesomeIcon icon={faGlobeAfrica} size={size} title={name} fixedWidth style={{color: '#00ecff'}}/>;
    } else if (code === 'ASI') {
        return <FontAwesomeIcon icon={faGlobeAsia} size={size} title={name} fixedWidth style={{color: '#00ecff'}}/>;
    } else if (code === 'OCE') {
        return <FontAwesomeIcon icon={faGlobeOceania} size={size} title={name} fixedWidth style={{color: '#00ecff'}}/>;
    }
    if (size === 'xs') {
        size = 10;
    } else if (size === 'sm') {
        size = 12;
    } else if (size === '1x') {
        size = 16;
    } else if (size === 'lg') {
        size = 20;
    } else if (size === '2x') {
        size = 32;
    }
    return <img alt={name} width={_.round(size * 4 / 3)} height={size} title={name} src={flagUrl(code)}/>
}

export const CountrySelector = ({selected, onSelected, options, height}) => {
    let validOptions = _.filter(options, o => countries[o[ID]] != null);
    if (validOptions.length === 0) {
        return <Loading/>
    }
    let itemSize = 22;
    const RenderedItem = ({index, style}) => {
        let option = validOptions[index];
        let countryId = option[ID];
        let country = countries[countryId];
        let isSelected = selected === countryId;
        return <div key={"c-" + countryId} className="cursor-pointer" style={{...style}}
                    onClick={() => onSelected(isSelected ? null : countryId)}>
            <Flag id={countryId} size="lg"/>
            <Badge className="em3 mx-1" bg="custom" style={{backgroundColor: hotScale(option.ht / 100)}}>
                {formatFixed(option.ht, 1)}
            </Badge>
            <Badge pill bg={isSelected ? 'dark' : 'light'} className={isSelected ? 'text-light' : 'text-dark'}>
                {country[NAME]}
            </Badge>
            <Badge className="em3 mx-1 text-center float-end" bg="secondary">
                {option.count}
            </Badge>
        </div>
    }
    return <List
        height={height}
        itemCount={validOptions.length}
        itemSize={itemSize}>
        {RenderedItem}
    </List>
}