import {getColor, rgbaColor} from "../../helpers/utils";
import {chartJsDefaultTooltip} from "../../helpers/chartjs-utils";
import {getElementAtEvent, Radar} from "react-chartjs-2";
import React, {useRef} from "react";

export const TCIRadar = ({
                             data,
                             minValue = 0,
                             maxValue = 10,
                             showLabels = true,
                             color = getColor('success'),
                             onClick = null
                         }) => {
    const chartRef = useRef();

    const datasets = {
        labels: ['TOT', 'DEF', 'CRE', 'INF', 'THR', 'ATT'],
        datasets: [
            {
                backgroundColor: rgbaColor(color, 0.6),
                data: data,
                borderWidth: 1
            }
        ]
    }

    const options = {
        plugins: {
            datalabels: false,
            tooltip: chartJsDefaultTooltip(),
            legend: {
                display: false
            }
        },
        maintainAspectRatio: false,
        scales: {
            r: {
                pointLabels: {
                    display: showLabels,
                    padding: 5
                },
                grid: {
                    color: rgbaColor(getColor('black'), 0.1)
                },
                ticks: {
                    display: false,
                    showLabelBackdrop: false,
                    stepSize: maxValue / 5.0,
                    padding: 0,
                },
                suggestedMin: minValue,
                suggestedMax: maxValue,
            }
        }
    }

    if (onClick != null) {
        options.onHover = (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        }
    }
    return <Radar ref={chartRef} data={datasets} options={options} onClick={onClick != null ? (e) => onClick(getElementAtEvent(chartRef.current, e)) : null}/>;
}