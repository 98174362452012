import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowContext} from "../../hooks/useWindowContext";
import {useSearchParamsState} from "../../hooks/useSearchParamsState";
import _ from "lodash";
import {
    createTeamChildBars,
    fetchTeamDetails,
    fieldDisplay as teamFieldDisplay,
    fields as teamFields,
    TeamLogoLink,
    TeamSelectorOverlay,
    TopPlayersForTeam
} from "./team";
import Error500 from "../errors/Error500";
import {Loading} from "./spinner";
import dayjs from "dayjs";
import {
    addAge,
    calcGaps,
    calcPlotData,
    calcRanges,
    chartColors,
    chartOptions,
    createRangeBars,
    getChartColor,
    LineChart,
    mapXValues,
    Thumb,
    toGapData,
    yearDiff
} from "./comparison";
import {getColor, getGrays, isValidNumber, rgbaColor} from "../../helpers/utils";
import {Flag} from "./flag";
import {Position} from "./position";
import {Gender} from "./icons";
import {Button, ButtonGroup, Card, Col, ListGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MatchHighlights} from "./highlights";
import {
    axes,
    calculatePeaks,
    calculateStatDenom,
    calculateStatValue,
    createCompetitionBars,
    createPlayerChildBars,
    fetchPlayerDetails,
    fieldDisplay,
    fields,
    PlayerSelectorOverlay,
    StatProgressBar
} from "./player";
import {hotScale} from "./colorprogress";
import {formatSeasonName} from "./competition";
import {BarChart, BASE_DATE, daysAsIso, isoAsDays} from "./ranks";
import {faChartColumn, faClock, faDumbbell, faMountain, faTrophy} from "@fortawesome/free-solid-svg-icons";
import {
    BIO,
    CLUB,
    COMPETITION,
    COUNTRY,
    END,
    FRIENDLY,
    GENDER,
    ID,
    NAME,
    NATIONAL,
    PRIMARY,
    RANK,
    RATING,
    SEASON_SECONDARY,
    SEASON_STATS,
    START,
    STATS,
    TEXT,
    YOUTH
} from "./alias";
import ReactSlider from "react-slider";

export const PlayerProfile = () => <ItemProfile type="player"/>
export const TeamProfile = () => <ItemProfile type="team"/>

export const Track = (props) => {
    let style = {
        ...props.style,
        color: '#fff',
        top: 0,
        bottom: 0,
        margin: "auto",
        backgroundColor: getGrays()['900'],
        height: 8,
        borderRadius: 999,
    };
    return <div {...props} style={style}/>
}


export const DoubleTrack = (props, state) => {
    let style = {
        ...props.style,
        color: '#fff',
        top: 0,
        bottom: 0,
        margin: "auto",
        height: 8,
        borderRadius: 999,
    };
    if (state.index === 1) {
        style['backgroundColor'] = hotScale((state.value[0] + state.value[1]) / 90.0);
        style['backgroundImage'] = 'linear-gradient(to right, ' + hotScale(state.value[0] / 50.0) + ',' + hotScale(state.value[1] / 45.0) + ')';
    } else {
        style['backgroundColor'] = '#333';
    }
    return <div {...props} style={style}/>
}

export function ItemProfile({type}) {
    const {id} = useParams()
    const [error, setError] = useState(null);
    const [item, setItem] = useState(null);

    useEffect(() => {
        let source;
        if (type === 'player') {
            source = fetchPlayerDetails(id);
        } else {
            source = fetchTeamDetails(id);
        }
        source.then((result) => {
                let item = result;
                document.title = item[NAME] + ' ' + _.upperFirst(type) + ' Profile - Football Ratings';
                setItem(item);
            },
            (error) => setError(error)
        )
    }, []);

    if (error) {
        return <Error500/>;
    } else if (item == null) {
        return <Loading/>;
    } else {
        return <ProfilePage type={type} id={id} item={item}/>;
    }
}

const ProfilePage = ({type, id, item}) => {
    const [selectedSquadDays, setSelectedSquadDays] = useState(null);
    const [squadType, setSquadType] = useState('c');
    const [filterParams, setFilterParams] = useSearchParamsState({
        age: {type: 'boolean', default: false},
        field: {type: 'string', default: [RANK], multiple: true}
    })

    const updateSquadType = type => {
        setSquadType(type);
        setSelectedSquadDays(null);
    }
    let stats = item[STATS];
    let dob = type === 'player' ? dayjs(item.dob) : dayjs(item[START] + '-01-01');
    let itemFields = type === 'player' ? fields : teamFields;
    let rangeKey = type === 'player' ? CLUB : COMPETITION;
    let dates = _.sortBy(_.keys(stats));
    if (dob.isAfter(dayjs().add(-5, 'year'))) {
        if (dates.length > 0) {
            dob = dayjs(dates[0]).add(-16, 'year');
        } else {
            dob = dayjs().add(-25, 'year');
        }
    }
    const calcAge = (date) => yearDiff(dob, date);
    let age = calcAge(dayjs());
    let gaps = calcGaps(dates, stats, 'oc', 120);
    let minAge, maxAge = age;
    if (dates.length > 0) {
        minAge = calcAge(dates[0]);
        maxAge = calcAge(dates[dates.length - 1]);
    } else {
        minAge = type === 'player' ? 16 : 0;
    }
    let rangeBars = createRangeBars(calcRanges(dates, stats, rangeKey), calcAge, minAge, maxAge, type === 'player' ? createPlayerChildBars : createTeamChildBars, type === 'player' ? 32 : 60);
    let secondaryRangeBars;
    if (type === 'player') {
        secondaryRangeBars = createRangeBars(calcRanges(dates, stats, COMPETITION, s => _.get(_.get(s, CLUB), COMPETITION)), calcAge, minAge, maxAge, createCompetitionBars, 60, 27);
    } else {
        secondaryRangeBars = null;
    }
    let xValueFn = filterParams.age ? calcAge : k => dayjs(k).valueOf();

    let gapData = toGapData(gaps);
    let fieldBests = calculatePeaks(stats, itemFields);
    let datasets = [];
    _.map(filterParams.field, (field, i) => {
        let fieldConfig = itemFields[field];
        let plotData = calcPlotData(dates, stats, field, fieldConfig);
        datasets.push({
            type: 'line',
            label: fieldConfig.name + (fieldConfig.sum ? ' In Previous Year' : ''),
            borderColor: getChartColor(i),
            backgroundColor: rgbaColor(getColor('gray-100'), 0),
            borderWidth: 2,
            fill: false,
            data: mapXValues(plotData, xValueFn),
            yAxisID: fieldConfig.axis,
            pointStyle: false,
            tension: 0
        })
    });

    datasets.push({
        type: 'line',
        label: 'Absent',
        borderColor: getChartColor(0),
        backgroundColor: rgbaColor(getChartColor(0), 0.2),
        borderWidth: 0,
        fill: true,
        data: mapXValues(gapData, xValueFn),
        yAxisID: 'absent',
        pointStyle: false,
        tension: 0
    });

    let selectedAxes = _.map(_.uniq(_.map(datasets, d => d.yAxisID)), k => ({name: k, ...axes[k]}));
    let data = {datasets: datasets};
    const options = chartOptions(selectedAxes, filterParams.age, filterParams.age ? minAge : null, filterParams.age ? maxAge : null);
    let params = {
        stats,
        dates,
        rangeBars,
        secondaryRangeBars,
        filterParams,
        fieldBests,
        item,
        id,
        setFilterParams,
        minAge,
        maxAge,
        data,
        options,
        itemFields,
        type,
        dob,
        age
    };
    let sideCol;
    if (type === 'team') {
        let season = null;
        if (selectedSquadDays != null) {
            season = _.maxBy(_.filter(item[SEASON_STATS], z => !z[SEASON_SECONDARY][COMPETITION][YOUTH] && !z[SEASON_SECONDARY][COMPETITION][FRIENDLY] && z[SEASON_SECONDARY][COMPETITION][PRIMARY] && z[SEASON_SECONDARY][START] <= daysAsIso(selectedSquadDays)), z => z[SEASON_SECONDARY][START]);
        }
        let currentDays = isoAsDays(dayjs().format('YYYY-MM-DD'));
        sideCol = <Col sm={12} md={12} xl={3}>
            {!item[NATIONAL] ?
                <ButtonGroup size="sm" className="w-100">
                    <Button title="Current Squad" onClick={() => updateSquadType('c')} variant="secondary"
                            active={squadType === 'c'}>
                        <FontAwesomeIcon icon={faClock} fixedWidth/> Now
                    </Button>
                    <Button title="Highest lifetime rating accumulated at club" onClick={() => updateSquadType('l')}
                            variant="secondary" active={squadType === 'l'}>
                        <FontAwesomeIcon icon={faTrophy} fixedWidth/> Club Legends
                    </Button>
                    <Button title="Highest lifetime rating players that played at club"
                            onClick={() => updateSquadType('g')} variant="secondary" active={squadType === 'g'}>
                        <FontAwesomeIcon icon={faDumbbell} fixedWidth/> Greats
                    </Button>
                    <Button title="Peak rating at club" onClick={() => updateSquadType('p')} variant="secondary"
                            active={squadType === 'p'}>
                        <FontAwesomeIcon icon={faMountain} fixedWidth/> Peak
                    </Button>
                </ButtonGroup> : <></>}
            <ReactSlider
                value={selectedSquadDays || currentDays}
                renderTrack={Track}
                renderThumb={(props, state) => Thumb(props, state, BASE_DATE, false, currentDays)}
                min={0}
                max={currentDays}
                onAfterChange={(value) => setSelectedSquadDays(value)}
                className="horizontal-slider"
                ariaLabel={'Squad Date'}
                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                pearling
                step={30}
            />
            <TopPlayersForTeam team={item}
                               season={season != null ? season.ss.i : null}
                               referenceDate={season != null ? season.ss.st : null}
                               type={squadType}/>
        </Col>;
    } else {
        sideCol = <></>;
    }
    return <ProfileRender {...params} sideCol={sideCol}/>
}

export function statsDiff(minStats, maxStats, itemFields) {
    return _.mapValues(maxStats, (val, key) => _.get(itemFields[key], 'sum', false) ? val - _.get(minStats, key, 0) : val);
}

const ProfileTitle = ({type, item, date}) => {
    if (type === 'player') {
        if (date == null) {
            date = Object.keys(item[STATS])[Object.keys(item[STATS]).length - 1]
        }
        return <Card.Title>
            {item.n}
            <span className="mx-1">
                    <TeamLogoLink {...item[STATS][date][CLUB]} size={20}/>
                </span>
            <span className="mx-1">
                    <Flag id={item[COUNTRY]} size="lg"/>
                </span>
            <span className="mx-1 fs-0">
                    <Position id={item.pos}/>
                </span>
            <Gender gender={item[GENDER]} size="1x"/>
            <div className="float-end">
                <PlayerSelectorOverlay gender={item[GENDER]} id={item[ID]}/>
            </div>
        </Card.Title>
    } else {
        return <Card.Title>
            {item.n}
            <span className="mx-1">
                    <TeamLogoLink {...item} size={20}/>
                </span>
            <span className="mx-1">
                    <Flag id={item[COUNTRY]} size="lg"/>
                </span>
            <Gender gender={item[GENDER]} size="1x"/>
            <div className="float-end">
                <TeamSelectorOverlay gender={item[GENDER]} national={item[NATIONAL]} id={item[ID]}/>
            </div>
        </Card.Title>
    }
}

const Bio = ({item}) => {
    let text = _.get(item[BIO], TEXT);
    if (text != null) {
        return <footer className="footer position-relative">
            <p className="mb-0 text-600 text-center fs--1 mt-4 mb-3">
                {text}
            </p>
        </footer>
    } else {
        return <></>
    }
}

const ProfileRender = ({
                           stats,
                           dates,
                           rangeBars,
                           secondaryRangeBars,
                           filterParams,
                           fieldBests,
                           item,
                           id,
                           setFilterParams,
                           minAge,
                           maxAge,
                           data,
                           options,
                           itemFields,
                           type,
                           dob,
                           age,
                           sideCol
                       }) => {
    let itemFieldDisplay = type === 'player' ? _.filter(fieldDisplay, f => item.pos === 0 || !fields[f].gk) : teamFieldDisplay;
    const [selectedMinAge, setSelectedMinAge] = useState(null);
    const [selectedMaxAge, setSelectedMaxAge] = useState(null);
    const [selectedCompetition, setSelectedCompetition] = useState(null);
    let {clientHeight} = useWindowContext();

    let toggleField = f => {
        if (filterParams.field.includes(f)) {
            if (filterParams.field.length > 1) {
                setFilterParams({field: _.filter(filterParams.field, f2 => f !== f2)});
            }
        } else {
            setFilterParams({field: _.concat(filterParams.field, [f])});
        }
    }

    let minStats, maxStats;
    let minDate, maxDate;
    let appliedMaxAge = selectedMaxAge || age;
    if (dates.length > 0) {
        appliedMaxAge = selectedMaxAge || maxAge;
        if (selectedMaxAge != null) {
            let maxSelectedDate = addAge(dob, appliedMaxAge).format('YYYY-MM-DD');
            let index = Math.min(Math.max(_.sortedIndex(dates, maxSelectedDate), 0), dates.length - 1);
            maxDate = dates[index];
            maxStats = stats[maxDate] || {};
        } else {
            maxDate = dates[dates.length - 1];
            maxStats = stats[maxDate] || {};
        }
        if (selectedMinAge != null) {
            let date = addAge(dob, selectedMinAge).format('YYYY-MM-DD');
            let index = Math.min(Math.max(_.sortedIndex(dates, date), 0), dates.length - 1);
            minDate = dates[index];
            minStats = stats[minDate] || {};
        } else {
            minDate = dates[0];
            minStats = {};
        }
    } else {
        minStats = {};
        maxStats = {};
    }

    let appliedMinAge = selectedMinAge || minAge;
    let statDiff = statsDiff(minStats, maxStats, itemFields);
    let selectedMinFraction = (appliedMinAge - minAge) / (maxAge - minAge);
    let selectedMaxFraction = (appliedMaxAge - minAge) / (maxAge - minAge);

    let statRows = _.map(itemFieldDisplay, (field) => {
        return <StatProgressBar fields={itemFields}
                                key={field}
                                field={field}
                                item={item}
                                selected={filterParams.field.includes(field)}
                                stats={statDiff}
                                onClick={toggleField}
                                bestValue={fieldBests[field]}/>;
    })

    let barGroups = _.groupBy(_.filter(item[SEASON_STATS], z => z[SEASON_SECONDARY][END] >= minDate && z[SEASON_SECONDARY][START] <= maxDate && (selectedCompetition == null || z[SEASON_SECONDARY][COMPETITION][ID] === selectedCompetition[ID])), z => selectedCompetition == null ? z[SEASON_SECONDARY][COMPETITION][ID] : z[SEASON_SECONDARY][ID]);
    let barFields = _.filter(filterParams.field, x => !itemFields[x].barexclude);
    let barField = barFields.length === 0 ? RATING : barFields[barFields.length - 1];
    let average = !itemFields[barField].sum || itemFields[barField].denomfn != null;
    let barPoints = _.map(barGroups, val => {
        let y;
        if (average) {
            let sumWx = 0, sumW = 0;
            _.each(val, s => {
                let statValue = calculateStatValue(s.z, barField, itemFields, false, 0);
                if (isValidNumber(statValue)) {
                    let weight = calculateStatDenom(s.z, barField, itemFields);
                    sumWx += statValue * weight;
                    sumW += weight;
                }
            })
            y = sumWx / Math.max(sumW, itemFields[barField].denommin || 0);
        } else {
            y = _.sumBy(val, s => calculateStatValue(s.z, barField, itemFields, false, 0));
        }
        let x = selectedCompetition == null ? val[0].ss.c.cd : formatSeasonName(val[0].ss);
        let name = selectedCompetition == null ? val[0].ss.c.n : formatSeasonName(val[0].ss);
        let selection = selectedCompetition == null ? val[0].ss.c : null;
        let date = val[0].ss.st;
        return ({x, y, name, selection, date});
    });

    if (selectedCompetition == null) {
        barPoints = _.orderBy(barPoints, 'y', itemFields[barField].lowerIsBetter ? 'asc' : 'desc');
    } else {
        barPoints = _.orderBy(barPoints, 'date');
    }

    const barData = {
        labels: _.map(barPoints, val => val.x),
        datasets: [{
            data: _.map(barPoints, val => val.y),
            backgroundColor: _.map(chartColors, c => rgbaColor(c, 0.2)),
            borderColor: chartColors,
            borderWidth: 1
        }]
    }

    let axis = axes[itemFields[barField].axis];

    let barTitle = (selectedCompetition != null ? selectedCompetition[NAME] + " " : "") + itemFields[barField].name + (selectedCompetition != null ? " By Season" : " By Competition");

    const barConfig = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return barPoints[context[0].dataIndex].name;
                    }
                }
            },
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value) => _.round(value, _.get(itemFields[barField], 'dp', 1))
            }
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                reverse: axis.reverse,
                type: axis.log ? 'logarithmic' : 'linear'
            }
        }
    };

    let statBarHeight = Math.max(175, clientHeight - 700);
    return (
        <>
            <Row>
                <Col sm={12} md={12} xl={type === 'team' ? 6 : 9}>
                    <Row>
                        <Col sm={12} lg={8}>
                            <Card>
                                <Card.Header className="p-2 bg-light text-dark">
                                    <ProfileTitle item={item} type={type} date={maxDate}/>
                                </Card.Header>
                                <ListGroup variant="flush" className="overflow-x-hidden"
                                           style={{height: statBarHeight}}>
                                    {statRows}
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Card>
                                <Card.Header className="p-2 bg-light text-dark">
                                    <Button as={Link} variant="primary" size={"sm"} className="py-0 w-100"
                                            to={type === 'player' ? "/playerratings?p=" + item[ID] : "/teamratings?t=" + item[ID]}>
                                        <FontAwesomeIcon icon={faChartColumn} className={"me-1"}/>{barTitle}
                                    </Button>
                                </Card.Header>
                                <div className="w-100" style={{height: statBarHeight}}>
                                    <BarChart data={barData} options={barConfig} onClick={e => {
                                        if (e.length > 0) {
                                            setSelectedCompetition(barPoints[e[0].index].selection);
                                        }
                                    }}/>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex d-flex-row">
                                <Button style={{width: 50}} size="sm" variant={filterParams.age ? "primary" : "light"}
                                        className="px-0"
                                        onClick={() => setFilterParams({age: !filterParams.age})}>Age</Button>
                                <div className="flex-grow-1">
                                    <ReactSlider
                                        value={[appliedMinAge, appliedMaxAge]}
                                        renderTrack={DoubleTrack}
                                        renderThumb={Thumb}
                                        min={minAge}
                                        max={maxAge}
                                        onChange={(value) => {
                                            setSelectedMinAge(value[0]);
                                            setSelectedMaxAge(value[1]);
                                        }}
                                        className="horizontal-slider"
                                        ariaLabel={['Min Strength', 'Max Strength']}
                                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                        pearling
                                        step={0.01}
                                    />
                                </div>
                            </div>
                            <div className="d-flex d-flex-row">
                                <Button disabled style={{width: 50}} size="sm" variant={"light"}
                                        className="px-0">{type === 'player' ? "Team" : "Div"}</Button>
                                <div className="flex-grow-1">
                                    <div style={{position: 'relative', width: '100%', height: 40}}>
                                        <div style={{
                                            position: 'absolute',
                                            height: '100%',
                                            borderLeft: '2px solid #ff9900',
                                            backgroundColor: rgbaColor(getColor('white'), 0.5),
                                            width: (100 - selectedMaxFraction * 100) + '%',
                                            top: 0,
                                            right: 0,
                                            zIndex: 20,
                                        }}/>
                                        <div style={{
                                            position: 'absolute',
                                            height: '100%',
                                            borderRight: '2px solid #ff9900',
                                            backgroundColor: rgbaColor(getColor('white'), 0.5),
                                            top: 0,
                                            left: 0,
                                            width: (selectedMinFraction * 100) + '%',
                                            zIndex: 20,
                                        }}/>
                                        {rangeBars}
                                    </div>
                                </div>
                            </div>
                            {secondaryRangeBars != null ?
                                <div className="d-flex d-flex-row">
                                    <Button disabled style={{width: 50}} size="sm" variant={"light"}
                                            className="px-0 py-0">Div</Button>
                                    <div className="flex-grow-1">
                                        <div style={{position: 'relative', width: '100%', height: 22}}>
                                            <div style={{
                                                position: 'absolute',
                                                height: '100%',
                                                borderLeft: '2px solid #ff9900',
                                                backgroundColor: rgbaColor(getColor('white'), 0.5),
                                                width: (100 - selectedMaxFraction * 100) + '%',
                                                top: 0,
                                                right: 0,
                                                zIndex: 20,
                                            }}/>
                                            <div style={{
                                                position: 'absolute',
                                                height: '100%',
                                                borderRight: '2px solid #ff9900',
                                                backgroundColor: rgbaColor(getColor('white'), 0.5),
                                                top: 0,
                                                left: 0,
                                                width: (selectedMinFraction * 100) + '%',
                                                zIndex: 20,
                                            }}/>
                                            {secondaryRangeBars}
                                        </div>
                                    </div>
                                </div>
                                : <></>}
                            <div className="w-100" style={{height: Math.min(clientHeight, 500)}}>
                                <LineChart data={data} options={options}/>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {sideCol}
                <Col sm={12} md={12} xl={3}>
                    <MatchHighlights {...{[type === 'player' ? 'playerIds' : 'teamIds']: [id]}}
                                     highlightName={type === 'player' ? item[NAME] : null}/>
                </Col>
            </Row>
            <Row>
                <Bio item={item}/>
            </Row>
        </>
    )
}