import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Badge, ButtonGroup, Card, Col, ListGroup, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {CountrySelector} from "./flag";
import {TeamLinkShortener, TeamLogoLink} from "./team";
import _ from "lodash";
import {FootballEvent, formatMatchTime, Gender, GenderToggle, isKeyEvent, isOwnGoal, NationalToggle} from "./icons";
import Button from "react-bootstrap/Button";
import {hotScale} from "./colorprogress";
import Calendar from "react-calendar";
import ReactSlider from 'react-slider'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoltLightning,
    faCalendarDays,
    faChevronLeft,
    faChevronRight,
    faFilter
} from "@fortawesome/free-solid-svg-icons";
import {Loading} from "./spinner";
import {settings} from "../../config";
import {FixedSizeList as List} from "react-window";
import Error500 from "../errors/Error500";
import {computeWindowHeight, useWindowContext} from "../../hooks/useWindowContext";
import {
    AWAY,
    COMPETITION,
    COUNTRY,
    EVENT,
    GENDER,
    HOME,
    ID,
    MATCH,
    NATIONAL,
    PERIOD,
    PLAYER,
    RATING,
    RELATED,
    ROUND,
    SCORE,
    SEASON,
    SECONDS,
    START,
    STATS,
    STATUS,
    TYPE
} from "./alias";
import {matchReportUrl} from "../../helpers/urls";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import {periodMatchEnds} from "./matchtimeline";
import classNames from "classnames";
import {MatchHighlights} from "./highlights";
import {breakpoints} from "../../helpers/utils";
import {CompetitionBadge} from "./competition";
import {RatingBadge100} from "./matchreport";

const Thumb = (props, state) =>
    <Badge bg="custom"
           {...props}
           className="px-0 text-center"
           style={{
               ...props.style,
               backgroundColor: hotScale(state.valueNow / 100.0),
               cursor: 'grab',
               width: 28
           }}>
        {state.valueNow}
    </Badge>;

const Track = (props, state) => {
    let style = {
        ...props.style,
        color: '#fff',
        top: 0,
        bottom: 0,
        margin: "auto",
        height: 8,
        borderRadius: 999,
    };
    if (state.index === 1) {
        style['backgroundImage'] = 'linear-gradient(to right, ' + hotScale(state.value[0] / 100.0) + ',' + hotScale(state.value[1] / 100.0) + ')';
    } else {
        style['backgroundColor'] = '#333';
    }
    return <div {...props} style={style}/>
}

export function getCompetition(match) {
    return _.get(_.get(_.get(match, ROUND), SEASON), COMPETITION);
}

export function processFixtureList(fixtureList) {
    let matches = _.get(fixtureList, MATCH, []);
    let rounds = _.keyBy(_.get(fixtureList, ROUND, []), ID);
    _.forEach(matches, m => {
        m[ROUND] = rounds[m[ROUND]];
    });
    return matches;
}

function Matches() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fixtures, setFixtures] = useState([]);
    const [delta, setDelta] = useState({[MATCH]: {}});
    const [date, setDate] = useState(new Date());
    const [strength, setStrength] = useState([0, 100]);
    const [gender, setGender] = useState(2);
    const [national, setNational] = useState(2);
    const [country, setCountry] = useState(null);
    const [inPlayOnly, setInPlayOnly] = useState(false);
    const [filtered, setFiltered] = useState(true);
    const [showCalendar, setShowCalendar] = useState(false);

    function computeHeight() {
        return Math.max(computeWindowHeight() - 340, 300);
    }

    const {clientHeight, clientWidth} = useWindowContext();
    const [scrollListHeight, setScrollListHeight] = useState(computeHeight());

    useLayoutEffect(() => {
        let newHeight = computeHeight();
        if (Math.abs(newHeight - scrollListHeight) > 100) {
            setScrollListHeight(newHeight);
        }
    }, [clientHeight]);

    function loadFixtures() {
        fetch(settings.apiServer + "fixtures/" + dayjs(date).format('YYYY-MM-DD') + ".json")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setFixtures(processFixtureList(result));
                    setError(false);
                },
                (error) => {
                    setIsLoaded(true);
                    setFixtures([]);
                    setError(error);
                }
            )
    }

    function loadDelta() {
        fetch(settings.apiServer + "fixtures/delta.json")
            .then(res => res.json())
            .then(
                (result) => {
                    let matches = processFixtureList(result);
                    setDelta({
                        [MATCH]: {...delta[MATCH], ..._.keyBy(matches, ID)},
                    });
                }
            )
    }

    let isToday = dayjs().isSame(date, 'day');

    useEffect(() => {
        if (date != null) {
            setIsLoaded(false);
            loadFixtures();
            if (isToday) {
                const interval = setInterval(() => loadDelta(), 10000);
                return () => clearInterval(interval);
            }
        }
    }, [date]);

    let content;
    let filterText;
    let matches = _.map(fixtures, m => delta[MATCH][m[ID]] || m);
    let countries = _.orderBy(_.map(_.groupBy(matches, m => m[ROUND][SEASON][COMPETITION][COUNTRY]), (values) => {
        return {
            i: values[0][ROUND][SEASON][COMPETITION][COUNTRY],
            count: values.length,
            ht: _.max(_.map(values, v => (_.get(v[HOME][STATS], RATING, 0) + _.get(v[AWAY][STATS], RATING, 0)) / 2))
        }
    }), 'ht', 'desc');
    if (error) {
        content = <Error500/>;
        filterText = '0';
    } else if (!isLoaded) {
        content = <Loading/>;
        filterText = '0';
    } else {
        let filteredMatches = _.orderBy(_.filter(matches, m => {
                if (!filtered) {
                    return true;
                }
                let avStrength = (_.get(m[HOME][STATS], RATING, 0) + _.get(m[AWAY][STATS], RATING, 0)) / 2;
                if (avStrength < strength[0] || avStrength > strength[1]) {
                    return false;
                }
                let comp = m[ROUND][SEASON][COMPETITION];
                if (country != null && comp[COUNTRY] !== country) {
                    return false;
                }
                if (gender !== 2 && comp[GENDER] !== gender) {
                    return false;
                }
                if (inPlayOnly && !isInProgress(m)) {
                    return false;
                }
                if (national !== 2 && comp[NATIONAL] !== national) {
                    return false;
                }
                return true;
            }), [m => m[ROUND][SEASON][COMPETITION][RATING], 'st', m => _.get(m[HOME], RATING, 0) + _.get(m[AWAY], RATING, 0)],
            ["desc", "asc", "desc"]);
        filterText = filtered ? filteredMatches.length : matches.length;
        content = <MatchList matches={filteredMatches} scrollListHeight={scrollListHeight}/>
    }
    return <Row>
        <Col xl={8} lg={12}>
            <Row>
                <Col lg={5} xxl={4} className={classNames(showCalendar ? "d-block" : "d-none")}>
                    <Card className={"mb-3"}>
                        <Calendar onChange={setDate} value={date}
                                  showFixedNumberOfWeeks={clientWidth > breakpoints.lg}/>
                    </Card>
                </Col>
                <Col>
                    <Card className={"mb-3"}>
                        <Card.Header className="bg-light p-2">
                            <Button variant={filtered ? "secondary" : "soft-secondary"} size="sm"
                                    className="float-end text-center"
                                    title="Fixtures Displayed / Total" onClick={() => setFiltered(!filtered)}>
                                <FontAwesomeIcon icon={faFilter}/> {filterText}
                            </Button>
                            <Button variant={inPlayOnly ? "warning" : "soft-warning"} size="sm"
                                    className="float-end text-center me-1"
                                    title="Fixtures Displayed / Total"
                                    onClick={() => setInPlayOnly(!inPlayOnly)}>
                                <FontAwesomeIcon icon={faBoltLightning}/> Live
                            </Button>
                            <ButtonGroup variant="secondary" size="sm float-start text-center">
                                <Button variant="secondary" className="px-2" title="Previous Day"
                                        onClick={() => setDate(dayjs(date).add(-1, 'day').toDate())}>
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                </Button>
                                <Button variant="secondary" className="px-2" title="Show Calendar"
                                        onClick={() => setShowCalendar(!showCalendar)}>
                                    <FontAwesomeIcon icon={faCalendarDays}
                                                     className="d-none d-md-inline"/> {dayjs(date).format('D MMM')}
                                </Button>
                                <Button variant="secondary" className="px-2" title="Next Day"
                                        onClick={() => setDate(dayjs(date).add(1, 'day').toDate())}>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </Button>
                            </ButtonGroup>
                            <Card.Title className="text-center mt-1 d-none d-md-block">Fixtures</Card.Title>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <ReactSlider
                                value={strength}
                                renderTrack={Track}
                                renderThumb={Thumb}
                                onChange={(value) => setStrength(value)}
                                className="horizontal-slider"
                                ariaLabel={['Min Strength', 'Max Strength']}
                                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                pearling
                                minDistance={10}
                            />
                            <Row className="py-1">
                                <Col className={"text-start pe-0"}>
                                    <GenderToggle id="fixtures" selected={gender} onChange={setGender} both={true}/>
                                </Col>
                                <Col className={"text-end ps-0"}>
                                    <NationalToggle id="fixtures" selected={national} onChange={setNational}
                                                    both={true}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CountrySelector selected={country} onSelected={setCountry} options={countries}
                                                     height={110}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className={"mb-3 text-nowrap"}>
                        {content}
                    </Card>
                </Col>
            </Row>
        </Col>
        <Col xl={4} lg={12}>
            <MatchHighlights matches={matches}/>
        </Col>
    </Row>
}

function renderEvents(homeEvents) {
    return _.map(homeEvents, (e, i) => (
        <div key={i} className="position-relative d-inline-block" style={{height: 36, width: 20, marginTop: -6}}>
            <Badge bg={"light"} className="position-absolute text-dark"
                   style={{fontSize: 8, left: 0, width: '100%', top: 0}}>
                {formatMatchTime(e[PERIOD], e[SECONDS])}
            </Badge>
            <div className="position-absolute" style={{bottom: 0}}>
                <FootballEvent id={e[TYPE]} p={e[PERIOD]} secs={e[SECONDS]} rel={e[RELATED]}
                               player={e[PLAYER]}
                               fixedWidth/>
            </div>
        </div>));
}

export const VerticalScorePartial = ({
                                         match,
                                         home,
                                         aggregate,
                                         showPenalties = true,
                                         showAggregateInBrackets = false
                                     }) => {
    let totalScore;
    let score = match[SCORE];
    if (aggregate) {
        let teamScore = _.get(score, home ? HOME : AWAY, 0);
        totalScore = _.get(score, home ? 'ha' : 'aa', 0) + teamScore;
    } else {
        totalScore = _.get(score, home ? HOME : AWAY, '-');
    }
    if (showPenalties && hasPenalties(score)) {
        if (home) {
            totalScore = <span>{totalScore}<small>{"(" + _.get(score, 'hp', 0) + ")"}</small></span>;
        } else {
            totalScore = <span>{totalScore}<small>{"(" + _.get(score, 'ap', 0) + ")"}</small></span>;
        }
    }
    if (showAggregateInBrackets && !hasPenalties(score) && hasNonZeroAggregate(score)) {
        totalScore =
            <span>{totalScore}<small>{"(" + (_.get(score, home ? 'ha' : 'aa', 0) + totalScore) + ")"}</small></span>;
    }
    let type = aggregate ? "info" : (isInProgress(match) ? "warning" : "success");
    return <Button variant={type}
                   className={classNames("mx-1 p-0 em2 fs-0 h-100", home ? "rounded-bottom-0" : "rounded-top-0", _.get(score, 'rs') === (home ? 0 : 2) ? ("bg-" + type) : ("btn-outline-" + type + " bg-white bg-opacity-10 text-" + type))}>
        {totalScore}
    </Button>
}

export function hasPenalties(score) {
    let homePenalties = _.get(score, 'hp', 0);
    let awayPenalties = _.get(score, 'ap', 0);
    return homePenalties !== 0 || awayPenalties !== 0;
}

export function hasNonZeroAggregate(score) {
    let home = _.get(score, 'ha', 0);
    let away = _.get(score, 'aa', 0);
    return home !== 0 || away !== 0;
}

function isInProgress(match) {
    let status = match[STATUS];
    let score = match[SCORE];
    let period = _.get(score, PERIOD, 0);
    let seconds = _.get(score, SECONDS, 0);
    return status <= 1 && seconds > 0 && period < 6;
}

export const ScoreElement = ({
                                 match,
                                 aggregate = false,
                                 showTime = true,
                                 showPenalties = true,
                                 showAggregateInBrackets = false,
                                 alignTop = false,
                                 placeholder = false
                             }) => {
    let status = match[STATUS];
    let score = match[SCORE];
    let period = _.get(score, PERIOD, 0);
    let seconds = _.get(score, SECONDS, 0);
    if (period > 0 && seconds > 0) {
        let homeScore = aggregate ? (_.get(score, 'ha', 0) + score[HOME]) : score[HOME];
        let awayScore = aggregate ? (_.get(score, 'aa', 0) + score[AWAY]) : score[AWAY];

        if (showPenalties && !aggregate) {
            if (hasPenalties(score)) {
                homeScore = <span>{homeScore}<span className="fs--2">{"(" + _.get(score, 'hp', 0) + ")"}</span></span>;
                awayScore = <span>{awayScore}<span className="fs--2">{"(" + _.get(score, 'ap', 0) + ")"}</span></span>;
            }
        }
        if (showAggregateInBrackets && !hasPenalties(score) && hasNonZeroAggregate(score)) {
            homeScore = <span>{homeScore}<span
                className="fs--2">{"(" + (_.get(score, 'ha', 0) + homeScore) + ")"}</span></span>;
            awayScore = <span>{awayScore}<span
                className="fs--2">{"(" + (_.get(score, 'aa', 0) + awayScore) + ")"}</span></span>;
        }
        if (placeholder) {
            return <span>{homeScore} - {awayScore}</span>
        }

        let result = score.rs;
        let homeWin = result === 0;
        let awayWin = result === 2;
        let inProgress = isInProgress(match);
        let time;
        if (inProgress) {
            time = formatMatchTime(period, seconds);
        } else {
            if (period === 3 || period === 4) {
                time = "After ET";
            } else if (period === 5) {
                time = "After PEN";
            } else {
                time = "Full Time";
            }
        }
        let type = inProgress ? "warning" : (aggregate ? "info" : "success");

        let timeElem;
        if (inProgress) {
            timeElem = <div className="w-100 m-0 progress rounded-2 position-relative text-dark" style={{
                height: 12,
                fontSize: 8,
                padding: 1,
            }}>
                <div className={"progress-bar progress-bar-" + type}
                     role="progressbar"
                     title={time}
                     aria-valuenow={seconds}
                     aria-valuemin={0}
                     aria-valuemax={periodMatchEnds[period]}
                     style={{width: (Math.min(seconds / periodMatchEnds[period], 1) * 100) + '%'}}>
                </div>
                <span className="w-100 h-100 text-center position-absolute">{time}</span>
            </div>;
        } else {
            timeElem = <Badge bg="secondary" className="w-100 m-0 position-relative d-block" style={{
                fontSize: 8,
                height: 12,
                padding: 2,
                borderRadius: 5,
            }}>
                {time}
            </Badge>;
        }

        return <div className="text-start w-100 p-0" style={{marginTop: alignTop ? -6 : 0}}>
            {showTime ? timeElem : <></>}
            <ButtonGroup size="sm" className="m-0" style={{verticalAlign: 'top', width: '100%'}}>
                <Button variant={homeWin ? type : "outline-" + type} className="m-0 p-0 em2">
                    {homeScore}
                </Button>
                <Button variant={awayWin ? type : "outline-" + type} className="m-0 p-0 em2">
                    {awayScore}
                </Button>
            </ButtonGroup>
        </div>
    } else if (status === 3) {
        return <span>Suspended</span>
    } else if (status === 4) {
        return <span>P - P</span>
    } else if (status === 5 || status === 7 || status === 8) {
        return <span>CAN</span>
    } else if (status === 6) {
        return <span>Awarded</span>
    } else if (status === 9) {
        return <span>Retired</span>
    } else {
        return <span>{dayjs(match[START]).format('HH:mm')}</span>
    }
}

export const MatchList = ({matches, scrollListHeight}) => {
    const navigate = useNavigate();
    let orderedRounds = _.uniq(_.map(matches, m => m[ROUND][SEASON][COMPETITION][ID]))
    let rows;
    if (orderedRounds.length <= 1) {
        rows = matches;
    } else {
        let matchesByRound = _.groupBy(matches, m => m[ROUND][SEASON][COMPETITION][ID]);
        rows = [];
        _.forEach(orderedRounds, (r) => {
            let ms = matchesByRound[r];
            rows.push({round: ms[0][ROUND]});
            rows.push(...ms);
        });
    }

    const RenderedItem = ({index, style, placeholder}) => {
        let row = rows[index];
        let round = row.round;
        if (round != null) {
            let comp = round[SEASON][COMPETITION];
            return <ListGroup.Item action as={Link} key={index} style={style} variant="light" className="px-0"
                                   to={"/competition/" + comp[ID] + "/" + round[SEASON][ID]}>
                <div className="d-flex flex-row px-0">
                    <div className="ms-1">
                        <CompetitionBadge {...comp} height={24}/>
                    </div>
                    <div className="mx-1 flex-grow-1">
                        {comp.n}
                        <Gender gender={comp.g} className={"mx-1"} hiddenValue={0}/>
                    </div>
                </div>
            </ListGroup.Item>
        } else {
            let match = row;
            let scoreElem = <ScoreElement match={match} showAggregateInBrackets={true} alignTop
                                          placeholder={placeholder}/>;
            let events = placeholder ? [] : _.get(match[SCORE], EVENT, []);
            let keyEvents = _.filter(events, e => isKeyEvent(e[TYPE]));
            let sortedEvents = _.sortBy(keyEvents, [e => e[PERIOD]], [e => e[SECONDS]]);
            let homeEvents = _.filter(sortedEvents, e => isOwnGoal(e[TYPE]) ? !e[HOME] : e[HOME]);
            let homeEventRender = renderEvents(homeEvents);
            let awayEvents = _.filter(sortedEvents, e => isOwnGoal(e[TYPE]) ? e[HOME] : !e[HOME]);
            let awayEventRender = renderEvents(awayEvents);
            let homeTeam = match[HOME];
            let awayTeam = match[AWAY];
            let matchUrl = matchReportUrl(match[ID]);
            return <ListGroup.Item key={index} style={style} className={"list-group-item-action cursor-pointer px-0"}
                                   onClick={() => navigate(matchUrl)}>
                <div className="d-flex flex-row px-1">
                    <div style={{flex: '1 0 0px'}}>
                        <div className="d-flex flex-row px-0">
                            <div>
                                <RatingBadge100 rt={_.get(homeTeam[STATS], RATING, 0)} title="Home Team Rating" dp={1}/>
                            </div>
                            <div className="mx-1" style={{marginTop: -3}}>
                                <TeamLogoLink {...homeTeam} size={32} placeholder={placeholder}/>
                            </div>
                            <TeamLinkShortener {...homeTeam}/>
                            <div className="d-none d-sm-block">
                                <Gender fixedWidth gender={homeTeam[GENDER]} hiddenValue={0}/>
                            </div>
                            <div className="flex-grow-1 d-none d-lg-block">
                                <div className="float-end">
                                    {homeEventRender}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: '0 0 60px'}} className="m-0 py-0 px-1 text-center">
                        <Link to={matchUrl}>{scoreElem}</Link>
                    </div>
                    <div style={{flex: '1 0 0px'}}>
                        <div className="d-flex flex-row-reverse px-0">
                            <div>
                                <RatingBadge100 rt={_.get(awayTeam[STATS], RATING, 0)} title="Away Team Rating" dp={1}/>
                            </div>
                            <div className="mx-1" style={{marginTop: -3}}>
                                <TeamLogoLink {...awayTeam} size={32} placeholder={placeholder}/>
                            </div>
                            <TeamLinkShortener {...awayTeam}/>
                            <div className="d-none d-sm-block">
                                <Gender fixedWidth gender={awayTeam[GENDER]} hiddenValue={0}/>
                            </div>
                            <div className="flex-grow-1 d-none d-lg-block">
                                <div className="float-start">
                                    {awayEventRender}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroup.Item>;
        }
    };

    let height = 41;
    if (scrollListHeight != null) {
        return <ListGroup>
            <List
                style={{overflowX: 'hidden'}}
                height={scrollListHeight}
                itemCount={rows.length}
                itemSize={height}>
                {RenderedItem}
            </List>
        </ListGroup>
    } else {
        return <ListGroup>
            {_.map(matches, (m, index) =>
                <LazyLoadComponent key={index} height={height}
                                   placeholder={<RenderedItem index={index} style={{height: height}} placeholder/>}>
                    <RenderedItem index={index} style={{height: height}}/>
                </LazyLoadComponent>)}
        </ListGroup>
    }
}

export default Matches;